import React, { Component, Fragment } from "react";
import { Howl } from "howler";

import { Stage, Layer, Group, Rect, Arc, Text } from "react-konva";
import betchipsnd from "././../../../../../assets/sounds/betoncard.mp3";
import careabeananebet from "../../../../../assets/games/CarebbeanPoker/ante&bet.svg";
import betrussiansnd from "././../../../../../assets/sounds/bet.mp3";
import pokerchipsnd from "././../../../../../assets/sounds/pokerchip_snd.mp3";
import plyerwin_snd from "././../../../../../assets/sounds/playerwin.mp3";
import cardsdropping_snd from "././../../../../../assets/sounds/carddroping.mp3";
import cardsshuffle from "././../../../../../assets/sounds/shuffle-cards.mp3";
import reaveldealer_cards from "././../../../../../assets/sounds/dealercards_reavel.mp3";
import clear_snd from "././../../../../../assets/sounds/clear.mp3";
import selectCard from "../../../../../assets/sounds/selectcard.mp3";
import unselectCard from "../../../../../assets/sounds/unselectcard.mp3";
import playerWin from "../../../../../assets/sounds/successNew.mp3"
import playerLose from "../../../../../assets/sounds/successNew.mp3"
import Cards from "../CaribbeanPoker/UI/Carebbeancards";
import chip_10 from "../../../../../assets/games/CarebbeanPoker/black.png";
import chip_25 from "../../../../../assets/games/CarebbeanPoker/Purple chip.svg";
import chip_50 from "../../../../../assets/games/CarebbeanPoker/blue.png";
import chip_100 from "../../../../../assets/games/russianPoker/red.png";
import chip_500 from "../../../../../assets/games/CarebbeanPoker/yellow.png";
import chip_1000 from "../../../../../assets/games/CarebbeanPoker/Whitechip.svg";
import mutesnds from "../../../../../assets/games/russianPoker/mute_snds.png"
import carebeantable from "../../../../../assets/games/CarebbeanPoker/Caribbeantable_windows.png";
import carebeantablebg from "../../../../../assets/games/CarebbeanPoker/caribbeantableportrite.png";
import carebeantableport from "../../../../../assets/games/CarebbeanPoker/caribbeanportatetable.png";
import pokertablelandsp from "../../../../../assets/games/CarebbeanPoker/lnd_table.png";
import landscapebg from "../../../../../assets/games/CarebbeanPoker/Caribbean-lndscpe.png";
import carebeanjson from "../CaribbeanPoker/Carebbeanpoker.json";
import balanceicon from "../../../../../assets/games/russianPoker/balancewindows.png";
import balanceiconwindows from "../../../../../assets/games/russianPoker/balancewindows.png";
import beticon from "../../../../../assets/games/russianPoker/Bet icon.png";
import beticonwindows from "../../../../../assets/games/russianPoker/betwindows.png";
import winnericn from "../../../../../assets/games/russianPoker/winnericon.png";
import winnericnwindows from "../../../../../assets/games/russianPoker/winnericonwindows.png";
import informicon from "../../../../../assets/games/russianPoker/i.png";
import optionbtn from "../../../../../assets/games/russianPoker/Option.png";
import optionbtnwindows from "../../../../../assets/games/russianPoker/optionbtnwindows.png";
import volumeimg from "../../../../../assets/games/russianPoker/Valume.png";
import betincerementdecrement from "../../../../../assets/games/russianPoker/betincreament&decreament.png";
import Betbtn from "../../../../../assets/games/russianPoker/betbtn.png";
import Foldbn from "../../../../../assets/games/russianPoker/foldbtn.png";
import Foldbnwindows from "../../../../../assets/games/russianPoker/foldbtnwindows.png";
import useridlmg from "../../../../../assets/games/russianPoker/userid.png";
import Buybtnwindows from "../../../../../assets/games/CarebbeanPoker/buybtnwindows.png";
import crbndeck from "../../../../../assets/games/CarebbeanPoker/DeckTP.a128753c.png";
import popupExit from "../../../../../assets/common_imgs/exit_btn.png"
import gsap from "gsap";
import resultCards_crbn from "../CaribbeanPoker/UI/resultCards";
import DealerCardsContainer from "./UI/DealerCardsContainerCarebbean";
import "./Carebianpoker.css";
import { connect } from "react-redux";


// npm i react-konva@17.0.1-3
class RussianPoker extends Component {
  constructor(props) {
    super(props);
    this.cardsRef = React.createRef();
    this.dealerCardsContainer = React.createRef();
    // antebetresult and shuffled result : ---------
    this.randomPlayerCards = [];
    this.shuffledPlayerCards = [];
    this.randomDealerCards = [];
    // replaceCards (userSelectedCards) : ---------
    this.replaceCards = [];
    // serverResponse (after draw cards) : ---------
    this.unsequenceDrawCards = [];
    this.sequenceDrawCards = [];
    // clickOnBuyCard : ---------
    this.buyCardUnsequenceArray = [];
    this.buyCardsequenceArray = [];
    // DEALER CARDS
    this.dealerCardsRandom = [];
    this.dealerShuffledCards = [];
    // CONFIRM_DEALER_CARD : -----------------
    this.dealerUnsequenceDrawCards = [];
    this.dealerSequenceDrawFinalCards = [];
    this.insuranceActive = false;
    this.balnce = this.props.balance;
    this.antebet = 0;
    this.betsamount = 0;
    this.roundNumber = 0;
    this.betAmount = 0;


    this.state = {
      myOrient: "land",
      showpayouts:false,
      anteglowstatus: 0,
      coverbetpannel: null,
      previousBets: [],
      lowBalaceErrorInfo: "",
      ShowGamerules: false,
      ShowHistory: false,
      totalAnteBetAmount: 0,
      betshigh_balnce: null,
      keyboardhandle: false,
      betbtncrbn: true,
      dealenable: false,
      disClearBtn: 1,
      disDrawbtn: 1,
      disBuybtn: 1,
      Foldbtn: 1,
      Yesbtn: 1,
      isclickamount: 0,
      Nobtn: 1,
      betBtnValue: 0,
      disBetBtn: 1,
      playerhand_status: "",
      isoptionmenu: false,
      buttontext: "",
      balance_rsp: 0,
      updateBet: 0,
      betdeductions: 0,
      antebetcircle: false,
      betchipincrementbtn: false,
      multibuttonsebledsble: false,
      mutesounds: false,
      totalbet: 0,
      bonusbetamount: 0,
      previousBonusbetamount: 0,
      count: 1,
      insurencetbet: 0,
      pokerranking: "",
      pokerrankingdeler: "",
      storebetchips: [],
      storeantebetchips: [],
      storebonuschips: [],
      storeinsurancechips: [],
      totalwinRussian: 0,
      betdecreamentbtn: false,
      carebbeanidincrement: 0,
      bsbtn: true,
      anteBetAmount: 0,
      previousAnteBetAmount: 0,
      disablemultibutton: false,
      insurencebtn: false,
      isMobilePortraitLndsp: null,
      betbutton: "DEAL",
      activeScreen: null,
      // //GroupProperties
      heightOfLayer: null,
      widthOfLayer: null,
      dealerDistanceFromX: null,
      playerDistanceFromX: null,
      dealerDistanceFromY: null,
      PlayerDistanceFromY: null,
      // SpriteSheetProps
      revealCard: false,
      infoText: this.props.infoText,
      buydrawCheck: false,
      insurencetbet: 0,


      infocrbn: null,
      mobileAntiMaskCovercss: null,
      Anteglow: null,
      betactvglwcss: null,
      betbuttoncss: null,
      betbtntextcss: null,
      foldbuttoncss: null,
      foldbtntextcss: null,
      antetext: null,
      undotextcss: null,
      undotexttxtcss: null,
      drawbtncss: null,
      drawbtntxtcss: null,
      carebbeanbetchipstoante: null,
      betchipstobonusbetcss: null,
      chipsstoredcontainercss: null,
      antebetpdvcss: null,
      antechpimgcss: null,
      antespntxtcss: null,
      bonusdivcss: null,
      bonusimgcss: null,
      bonuspntxtcss: null,
      betbtntxtcss: null,
      betchiptobetbtncss: null,
      betchiptobetbtncss: null,
      betbtntxtcss: null,
      buydrawcontainer: null,
      drawchipcss: null,
      drawchiptextcss: null,
      insurancedivcss: null,
      insurancechipcss: null,
      insurancetxtcss: null,
      dealerhandcss: null,
      playerhandcss: null,
    };

    this.chiprefcarebean = React.createRef();
    this.chiprefee = React.createRef();
    this.chipdrawref = React.createRef();
    this.chipbnsref = React.createRef();
    this.chipvalue = React.createRef();
    this.chipsarray = React.createRef();
    this.chipbetref = React.createRef();
    this.chipinsurenceref = React.createRef();
    this.no = React.createRef();
    this.cardsRef = React.createRef();
    // this.dealerCardsContainer = React.createRef();
    this.buyDealerCardDrawn = React.createRef();
    this.handleResize = this.handleResize.bind(this);
    this.isMobile = false;
    this.dealenable = false;
    this.resetAnimationChips = false;
    this.playerWinBonus = false;
    this.wanttobuydelercrd = null;




    this.clearTimingLeft = [
      this.clertime1, this.clertime2, this.clertime3, this.clertime4, this.clertime5, this.clertime6, this.clertime7, this.clertime8, this.clertime9,
      this.clertime11, this.clertime12, this.clertime13, this.clertime14, this.clertime15, this.clertime16, this.clertime17, this.clertime18, this.clertime19,
      this.clertime21, this.clertime22, this.clertime23, this.clertime24, this.clertime25, this.clertime26, this.clertime27, this.clertime28, this.clertime29,
      this.clertime31, this.clertime32, this.clertime33, this.clertime34, this.clertime35, this.clertime36, this.clertime37, this.clertime38, this.clertime39,
      this.clertime41, this.clertime42, this.clertime43, this.clertime44, this.clertime45, this.clertime46, this.clertime47, this.clertime48, this.clertime49,
      this.clertime50, this.clertime51, this.clertime52, this.clertime53, this.clertime54, this.clertime55, this.clertime56
    ]

    this.GameRules = [
      
        { "name": "Caribbean Poker is played with a deck of 52 cards. To participate, you must place an ante bet. You receive five cards, and the dealer receives five cards—four face down and one open card. No new cards are dealt. The player and dealer compare hands formed from their five cards." },
        { "name": "After viewing your cards and the dealer's open card, you must decide whether to Bet, Fold, or Undo." },
        { "name": "UNDO: If you have not yet confirmed your action, you can undo your last move to reconsider your decision." },
        { "name": "BET: If you wish to challenge the dealer, you must place a bet equal to twice the ante." },
        { "name": "FOLD: If you do not wish to challenge the dealer, you must fold and lose your ante." },
        { "name": "Once the bet is placed, the dealer reveals their remaining four cards, and hands are compared." },
        { "name": "DEALER MUST QUALIFY: The dealer must have at least one Ace and one King to qualify. All winning hands (One pair and up) automatically qualify." },
        { "name": "If the dealer does not qualify, you receive 1 to 1 on your ante, and your bet is returned." },
        { "name": "If the dealer qualifies, the best hand wins, and winnings are calculated according to the pay table." },
        { "name": "If neither the player nor dealer has a winning hand, the highest card determines the winner." },
        { "name": "Example: Dealer's Hand: A, K, J, 8, 3. Player's Hand: A, K, Q, 3, 2. Player wins since Queen is higher than Jack." },
        { "name": "PUSH: If the dealer and the player have equal poker hands, both the ante and bet are returned to the player." },
        { "name": `BETTING LIMITS: The minimum ante bet is ${this.props.minBetAmt}, and the maximum is ${this.props.maxBetAmt}.` }
      
    ];
  
    this.chipVal = [
      { src: chip_10, key: 1, label: "10", val: 10 },
      { src: chip_25, key: 1, label: "20", val: 20 },
      { src: chip_50, key: 1, label: "50", val: 50 },
      { src: chip_100, key: 1, label: "100", val: 100 },
      { src: chip_500, key: 1, label: "500", val: 500 },
      { src: chip_1000, key: 1, label: "1000", val: 1000 },
      // { src: chip_25, key: 1, val: 1000 },
      // { src: chip_25, key: 1, val: 5000 },
    ];
    this.updatePlayerInfo = this.updatePlayerInfo.bind(this);

  }
  clearallsettimeouts = () => {
    for (let i = 1; i <= this.clearTimingLeft.length; i++) {
      clearTimeout(this.clearTimingLeft[i]);
      console.log(this.clearTimingLeft[i]);
    }
  }
  componentDidMount() {
    document.addEventListener('keydown', this.handleKeyDown);
    // document.addEventListener("keydown", this.Russian_handleKeyboardEvent);
    this.setState({ buttontext: "clear", insurencebtn: true });

    console.log(this.props.cb_chipValues)
    this.setState({ buttontext: "clear", insurencebtn: true })
  
    this.betchipsnd = new Audio(betchipsnd);
    this.betrussiansnd = new Audio(betrussiansnd);
    // this.reaveldealer_cards = new Audio(reaveldealer_cards);
  this.reaveldealer_cards =new Howl({src: [reaveldealer_cards],html5: true, });

    this.selectCard = new Audio(selectCard);
    this.unselectCard = new Audio(unselectCard);
    this.playerWin = new Audio(playerWin);
    this.playerLose = new Audio(playerLose);
    this.betchipactivated = false;
    this.pokerchipsnd = this.initializeChipSound();
    this.betchipsnd = this.initializeBetSound();
    this.playerWin = this.initializePlayerWinSound();
    this.playerLose = this.initializePlayerLoseSound();
    const { childRef } = this.props;
    childRef(this);


    if (this.props.cb_chipValues) {
      let newChips = this.props.cb_chipValues.split(",");
      // console.log(newChips);
      // console.log(newChips.length)
      let lblTxt = "";
      // for (let i = 0; i < newChips.slice(0,6).length; i++) {
      for (let i = 0; i < newChips.length; i++) {
        if (Number(newChips[i]) >= 1000 && Number(newChips[i]) <= 999999) {
          lblTxt = "K"
        } else if (Number(newChips[i]) >= 1000000 && Number(newChips[i]) <= 999999999) {
          lblTxt = "M"
        } else if (Number(newChips[i]) > 999999999) {
          lblTxt = "B"
        }
        this.chipVal[i].val = newChips[i]
        // console.log(this.chipVal[i].val, lblTxt);
        this.checkChipValuerCaribbean(Number(this.chipVal[i].val), lblTxt, i);
      }

    }




    document.getElementById("infrmiconcrbn").classList.add("addpointercrbn")


    console.log(carebeanjson.pokertable.height);
    window.addEventListener("resize", this.handleResize);

    // window.addEventListener("resize", this.handleOrientationChange);

    this.handleOrientationChange();

    console.log("Width: " + window.screen.width);
    console.log("height: " + window.screen.height);
    const betchipsRussianpoker = document.getElementById("betchipscrbn");
    this.setState({ infoText: this.props.language.Place_antebet })
    if (
      navigator.userAgentData &&
      navigator.userAgentData.platform &&
      navigator.userAgentData.platform == "Windows"
    ) {
      document.getElementById("landscapeInfocrbn").style.display = "none";
      document.getElementById("infocontainercrbn").classList.add("infotext_windowscrbn")
      document.getElementById("mobileAntiMaskCover_crbn").style.display = "none";
      document.getElementById("mobileAntiMaskCover_crbn").classList.remove("mobileAntiMaskCover_crbn")
      document.getElementById("info_crbn").classList.add("windowsinfocrbn");
      document.getElementById("betbtn_carebean").classList.add("crbn_nodrop")
      document.getElementById("anteclickCaribbean").src = careabeananebet
      document.getElementById("bet_carebean").src = careabeananebet
      document.getElementById("betpannel").classList.add("betpannelcarebean");
      document.getElementById("anteclickCaribbean").classList.add("antebet_carebean");
      document.getElementById("bet_carebean").classList.add("bet_carebean");
      document.getElementById("anteglow").classList.add("anteglow");
      document.getElementById("carebean_ante").classList.add("carebean_ante");
      document.getElementById("carebean_bet").classList.add("carebean_bet");





      const buybutton = document.getElementById("undobutton");
      buybutton.classList.add("addpointercrbn")

      document.getElementById("minmaxbetscrbn").classList.add("minmaxbets_windowscrbn");
      this.setState({
        activeScreen: 0
      })

      document.getElementById("container_carebean").classList.add("container_carebean");
      document.getElementById("Dealercrbn").classList.add("Dealercrbn")
      document.getElementById("Playercrbn").classList.add("Playercrbn")
      // document.getElementById("optionrussian_crbn").style.display = "none"


      document.getElementById("betbtn").classList.add("addpointercrbn");
      document.getElementById("Foldbtncrbn").classList.add("addpointercrbn");

      switch (this.state.count) {
        case 1:
          betchipsRussianpoker.src = chip_10;
          document.getElementById("val0").classList.remove("incrementval_windowscrbn");
          document.getElementById("val0").textContent = this.chipVal[0].label;
          document.getElementById("val0").classList.add("valtext_windows_chip10crbn", "chip_10text");

          // alert("...."+ betchipsRussianpoker.src)
          break;
        case 2:
          betchipsRussianpoker.src = chip_25;
          break;
        case 3:
          betchipsRussianpoker.src = chip_50;
          break;
        case 4:
          betchipsRussianpoker.src = chip_100;
          break;
        case 5:
          betchipsRussianpoker.src = chip_500;
          break;
        default:
          break;
      }
      Object.assign(betchipsRussianpoker.style, carebeanjson.betchpwindows);


    } else {
      document.getElementById("cnfrmfoldpop").style.visibility = "hidden";

      this.setState({
        activeScreen: 1
      })
      // const confirmbuydealerElement = document.getElementById("cnfrmfoldpop");
      const popupbetGreaterthanBalnceElement = document.getElementById("popupbet_greaterthanbalncecrbn");
      popupbetGreaterthanBalnceElement.classList.add("popupbetshighMobilecrbn");
      const containerPlayerdealerElement = document.getElementById("container_carebean");
      const betbutton = document.getElementById("betbtn");
      const foldbutton = document.getElementById("Foldbtncrbn");


      const buybutton = document.getElementById("undobutton");
      // buybutton.classList.add("addpointercrbn")


      containerPlayerdealerElement.className = "";
      containerPlayerdealerElement.style.cssText = "";





      buybutton.src = "";
      buybutton.style.cssText = "";
      betbutton.src = "";
      betbutton.style.cssText = "";
      foldbutton.src = "";
      foldbutton.style.cssText = "";

      switch (this.state.count) {
        case 1:
          betchipsRussianpoker.src = chip_10;
          document.getElementById("val0").textContent = "10";
          // alert("...."+ betchipsRussianpoker.src)
          break;
        case 2:
          betchipsRussianpoker.src = chip_25;
          break;
        case 3:
          betchipsRussianpoker.src = chip_50;
          break;
        case 4:
          betchipsRussianpoker.src = chip_100;
          break;
        case 5:
          betchipsRussianpoker.src = chip_500;
          break;
        case 6:
          betchipsRussianpoker.src = chip_1000;
          break;

        default:
          break;

      }





      document.getElementById("val0").style.cssText = "";
      document.getElementById("info_crbn").className = "";
      if (window.innerWidth < window.innerHeight) {
        document.getElementById("infocontainercrbn").style.display = "block"

        document.getElementById("anteglow").classList.add("anteglowportraite");



        document.getElementById("val0").classList.add("valtextPortrait_crbn", "valtextPortrait1_crbn");


        document.getElementById("betpannelcarebbeancover").style.display = "none";
        document.getElementById("betpannelcarebbeancover").classList.add("PlayerCardpannelcovercrbn");
        document.getElementById("mobileAntiMaskCover_crbn").style.display = "none";
        document.getElementById("mobileAntiMaskCover_crbn").classList.remove("mobileAntiMaskCover_crbn");
        document.getElementById("val0").classList.remove('valtext0');
        // confirmbuydealerElement.classList.add('confirmbuy-dealerpopup');

        // document.getElementById("info_crbn").classList.add("portraitinfo_crbn");
        document.getElementById('volumecrbn').classList.add("addpointercrbn")
        // betChipText.style.cssText = "";

        containerPlayerdealerElement.classList.add("pyrdlr_crbnprt");
        document.getElementById("Playercrbn").style.fontSize = "80px";
        document.getElementById("infocontainercrbn").style.display = "block"


      } else {
        console.log(document.getElementById("anteglow"))
        document.getElementById("infocontainercrbn").style.display = "none"
        document.getElementById("anteglow").classList.add("anteglowlndspe");

        document.getElementById("mobileAntiMaskCover_crbn").style.display = "none";
        document.getElementById("mobileAntiMaskCover_crbn").classList.remove("maskcoverlndspe_crbn");
        // document.getElementById("undobutton").classList.add("undobetbutton");
        document.getElementById("val0").classList.add("valtextLandscapecrbn", "valtextPortrait1_crbn");
        containerPlayerdealerElement.classList.add("pyrdlr_crbnlnsp");
        document.getElementById("Playercrbn").style.fontSize = "80px";

        // document.getElementById("landscapeInfocrbn").style.display="block"
        document.getElementById("inforussianlandcrbn").classList.add("infotext_lndspecrbn")

        document.getElementById("landscapeInfocrbn").classList.add("lndspinfo_crbn");


      }
    }
    this.showPreviousBetsStatus();
  }
  handleKeyDown = (event) => {
    console.log(event.key)
    //   if (event.key === 'Tab' || "Enter") {

    event.preventDefault();  // Disable the Tab key globally
    console.log('Tab key is disabled globally!');
    // }

  };


  initializeChipSound = () => {
    let chipSound = new Howl({
      src: [pokerchipsnd],
      html5: true, // Enable HTML5 Audio for mobile compatibility
    });
    this.setState({
      chipSound,
      isPlayingChip: false
    })
    return chipSound
  }
  playPokerChipSound = (chipSound) => {
    const { isPlayingChip, mutesounds } = this.state;
    let currentSound = chipSound;
    if (!mutesounds) {

      if (isPlayingChip) {
        chipSound.stop();
      }
      if (!chipSound) {
        currentSound = this.initializeChipSound();
      }

      currentSound.play();
      this.setState({
        isPlayingChip: true
      })
    }

  };
  initializeBetSound = () => {
    try {
      const betSound = new Howl({
        src: [betchipsnd],
        html5: true, // Enable HTML5 Audio for mobile compatibility
      });
      this.setState({
        betSound,
        isPlayingBet: false,
      });
      return betSound;
    } catch (error) {
      console.error("Error initializing bet sound:", error);
      return null;
    }
  };


  initializePlayerWinSound = () => {

    let winSound = new Howl({
      src: [playerWin],
      html5: true,
    });

    return winSound


  }
  initializePlayerLoseSound = () => {
    let winSound = new Howl({
      src: [playerLose],
      html5: true,
    });

    return winSound

  }
 

  checkChipValuerCaribbean(chipValue, chpTxt, i) {

    if (chipValue >= 1000) {
      chipValue = chipValue / 1000;
      this.checkChipValuerCaribbean(chipValue, chpTxt, i)
    } else {
      console.log(chpTxt);
      this.showResultChipValueCaribbean(chipValue, chpTxt, i);
      return chipValue
    }
  }
  showResultChipValueCaribbean(chVal, chipTxt, index) {
    console.log(chVal);
    console.log(chVal + "" + chipTxt)
    this.chipVal[index].label = chVal + "" + chipTxt;
    console.log(this.chipVal)
  }
  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleKeyDown);
    // document.removeEventListener("keydown", this.Russian_handleKeyboardEvent);
    window.removeEventListener("resize", this.handleResize);
    this.betchipsnd.pause();
    this.betrussiansnd.pause();
    // this.reaveldealer_cards.pause();

    this.selectCard.pause();
    this.unselectCard.pause();
    this.playerWin.pause();
    this.playerLose.pause();
  }


  clearActivesWeDid = () => {

    // this.setState({
    //   bsbtn: true
    // });

    this.randomPlayerCards = [];
    this.shuffledPlayerCards = [];
    this.randomDealerCards = [];
    this.replaceCards = [];
    this.unsequenceDrawCards = [];
    this.sequenceDrawCards = [];
    this.buyCardUnsequenceArray = [];
    this.buyCardsequenceArray = [];
    this.dealerCardsRandom = [];
    this.dealerShuffledCards = [];
    this.dealerUnsequenceDrawCards = [];
    this.dealerSequenceDrawFinalCards = [];
    setTimeout(() => {
      document.getElementById('rankinginfodeler_crbn').className = "";
      document.getElementById('rankinginfo_crbn').className = "";
      document.getElementById('rankinginfodeler_crbn').textContent = "";
      document.getElementById('rankinginfo_crbn').textContent = "";


    }, 100)
    this.setState({ pokerrankingdeler: "", pokerranking: "" })
    this.cardsRef.current.ReBet();
    this.dealerCardsContainer.current.ReBet();

  }

  handleResize() {
   
    console.log("calling resize");
    // document.getElementById("crbnbgim_crbn");
    this.handleOrientationChange();
  }

  handleOrientationChange = () => {
    console.log(this.state.anteglowstatus)
    if (document.getElementById("optionsmenubutton")) {
      gsap.to('#optionsmenubutton', { autoAlpha: 0 })
    }

    if (document.getElementById("histry_russian_crbn")) {
      document.getElementById("histry_russian_crbn").className = "";

    }
    if (document.getElementById("exithistory")) {
      document.getElementById("exithistory").className = "";

    }
    if (document.getElementById("prevhistory_crbn")) {
      document.getElementById("prevhistory_crbn").className = "";

    }
    if (document.getElementById("histry_body_crbn")) {
      document.getElementById("histry_body_crbn").className = "";
    }
    if (document.getElementById("heading_hstiry_crbn")) {
      document.getElementById("heading_hstiry_crbn").className = "";

    }



    this.setState({ isoptionmenu: false, ShowLimits: false, ShowGamerules: false });

    console.log(window.innerHeight);
    console.log(window.innerWidth);
    const isPortrait = window.innerHeight > window.innerWidth;
    if (isPortrait) {
      console.log(" -----------  PORT     -------------------");
      this.setState({
        myOrient: "port",
      });
    } else {
      console.log(" -----------  LAND     -------------------");
      this.setState({
        myOrient: "land",
      });
    }
    const crbndeckcrds = document.getElementById("crbndeck");
  
    const bacaratbgim_crbn = document.getElementById("crbnbgim_crbn");
    const pokertable = document.getElementById("pokertableimcrbn");
    const pokertablelnspe = document.getElementById("pokertablelandscapecrbn");
    const useriddetl = document.getElementById("useridcrbn");
   
    const russianbncicon = document.getElementById("balnceiconcrbn");
    const exitbtn = document.getElementById("exitbtn");
    const beticonrussian = document.getElementById("beticoncrbn");
    const winnericonrussian = document.getElementById("winnericoncrbn");
    const informi = document.getElementById("infrmiconcrbn");
    const optionrussianbtn = document.getElementById("optionrussian_crbn");
    const volumeRussianimg = document.getElementById("volumecrbn");
 
    const betchipincrementbtn = document.getElementById("betincrenetcrbn");
    const betchipdecrementbtn = document.getElementById("betdecrementcrbn");
    const betchipsRussianpoker = document.getElementById("betchipscrbn");
    const Russianbgwindowsimg = document.getElementById("Russianbgwindows");
    const caribbeantbleport = document.getElementById("caribbeantbleport");
    const Russiantblewindows = document.getElementById("rsntblewindows");
    const betbutton = document.getElementById("betbtn");
    const foldbutton = document.getElementById("Foldbtncrbn");

    const buybutton = document.getElementById("undobutton");
    // const drwtext = document.getElementById("drawtext");
    const undotexttext = document.getElementById("undotext");
    const betbtntext = document.getElementById("betbtntext");
    const foldbtntext = document.getElementById("foldtextcrbn");
    const dealerBuyCardElement = document.getElementById("dealerBuyCard");
    const dealerChipsAddToBuyElement = document.getElementById("dealerChipsAddToBuyButton");

    const bonusInfoTheadElement = document.getElementById('bonusInfoThead');
    const bonusInfoTbodyElement = document.getElementById("bonusInfoTbody");

    const winpopuprspelement = document.getElementById("winpopup_crbn");
    const playerDetailsElement = document.getElementById("playerDetailscrbn");
    const containerPlayerdealerElement = document.getElementById("container_carebean");
    const chipsstoredcontainerElement = document.getElementById('chipsstoredcontainer');
    const minmaxbetsElement = document.getElementById("minmaxbetscrbn")
    const popupbetGreaterthanBalnceElement = document.getElementById("popupbet_greaterthanbalncecrbn");




    containerPlayerdealerElement.className = "";
    playerDetailsElement.className = "";
    minmaxbetsElement.className = "";



    bacaratbgim_crbn.style.cssText = "";

    optionrussianbtn.src = "";
    optionrussianbtn.style.cssText = "";





    russianbncicon.src = "";
    russianbncicon.style.cssText = "";

    beticonrussian.src = "";
    beticonrussian.style.cssText = "";

    exitbtn.src = "";
    exitbtn.style.cssText = "";


    winnericonrussian.src = "";
    winnericonrussian.style.cssText = "";

    informi.src = "";
    informi.style.cssText = "";


    volumeRussianimg.src = "";
    volumeRussianimg.style.cssText = "";

    betchipincrementbtn.src = "";
    betchipincrementbtn.style.cssText = "";

    // betchipsRussianpoker.src = "";
    // betchipsRussianpoker.style.cssText = "";

    betchipdecrementbtn.src = "";
    betchipdecrementbtn.style.cssText = "";



    dealerBuyCardElement.className = "";
    dealerChipsAddToBuyElement.className = "";
    winpopuprspelement.className = "";
    if (chipsstoredcontainerElement !== null) {
      chipsstoredcontainerElement.className = "";
    }


    if (bonusInfoTheadElement && bonusInfoTbodyElement) {
      bonusInfoTheadElement.className = "";
      bonusInfoTbodyElement.className = "";

    }





    if (
      navigator.userAgentData &&
      navigator.userAgentData.platform &&
      navigator.userAgentData.platform == "Windows"
    ) {
      crbndeckcrds.src = crbndeck
      crbndeckcrds.classList.add("cardDeckwindowscrbn")
      //  alert("windows");
      // console.log("not a mobile");
      this.setState({
        heightOfLayer: 948,
        widthOfLayer: 2350,
        // dealerDistanceFromX: 250,
        // playerDistanceFromX: 1020,    
        dealerDistanceFromX: 225,
        playerDistanceFromX: 1060,
        dealerDistanceFromY: 0,
        PlayerDistanceFromY: 128,
        isMobilePortraitLndsp: false,
        scaleX: 1.8,
        scaleY: 1.8,
        activeScreen: 0
      });

      this.isMobile = false;
      this.systemOS = "Windows";
     
      // document.getElementById("betcontainer").classList.remove("betpannelrussian");
      // document.getElementById("betcontainer").classList.add("betpannelrussianwindows");
      playerDetailsElement.classList.add("playerIdDetailswindowscrbn");

      containerPlayerdealerElement.classList.add("container_carebean");



   
      undotexttext.classList.remove("undotextportraite");
      betbtntext.classList.remove("betportraitcrbne");
      foldbtntext.classList.remove("foldtextportraitecrbn");

      // drwtext.classList.add("drawtextwindows");
      undotexttext.classList.add("undotextwindows");
      betbtntext.classList.add("betwindowscrbn");
      foldbtntext.classList.add("foldtextwindowscrbn");
   
      undotexttext.classList.remove("undotextlndspe");
     
     

      Russianbgwindowsimg.style.background = `url(${carebeantable})`;
      //  alert(".............."+ Russianbgwindowsimg.style.background)

      Object.assign(Russianbgwindowsimg.style, carebeanjson.russianbgwindows)



      optionrussianbtn.src = optionbtnwindows;
      Object.assign(optionrussianbtn.style, carebeanjson.optionbtnwindows)

      russianbncicon.src = balanceiconwindows;
      Object.assign(russianbncicon.style, carebeanjson.balanceiconwindows)

      document.getElementById("balancecrbn").classList.remove("balanceportraitecrbn,balancelndscapecrbn");
      document.getElementById("balancecrbn").classList.add("balancewindowscrbn");

      useriddetl.src = useridlmg;
      Object.assign(useriddetl.style, carebeanjson.useridwindows)



      document.getElementById("useridtextcrbn").classList.remove("useridportraitecrbn,useridlandscapecrbn");
      document.getElementById("useridtextcrbn").classList.add("useridwindowscrbn");

      beticonrussian.src = beticonwindows;

      Object.assign(beticonrussian.style, carebeanjson.beticonwindows);
      document.getElementById("betscrbn").classList.remove("betslandspecrbn,betsportraitecrbn");
      document.getElementById("betscrbn").classList.add("betswindowscrbn");



   
      winnericonrussian.src = winnericnwindows;
      Object.assign(winnericonrussian.style, carebeanjson.winnericonwindows);

      document.getElementById("winscrbn").classList.remove("winslandspecrbn,winsportraitecrbn");
      document.getElementById("winscrbn").classList.add("winswindowscrbn");

      informi.src = informicon;
      Object.assign(informi.style, carebeanjson.infrmicnlnspewindows);

      volumeRussianimg.src = volumeimg;
      Object.assign(volumeRussianimg.style, carebeanjson.volumewindows);


      betchipincrementbtn.src = betincerementdecrement;
      Object.assign(betchipincrementbtn.style, carebeanjson.beincrementwindows);


      betchipdecrementbtn.src = betincerementdecrement;
      Object.assign(betchipdecrementbtn.style, carebeanjson.bedecrementwindows);



    } else {


      console.log(isPortrait);
      document.getElementById("playerDealerCardContainercrbn").classList.remove("stage-top-mobile-portraitcrbn");
      // let betcontainerElement = document.getElementById("betcontainer");

      Russianbgwindowsimg.style.cssText = "";
      Russianbgwindowsimg.style.backgroundImage = 'none';


      useriddetl.src = "";
      useriddetl.style.cssText = "";
      bacaratbgim_crbn.style.cssText = "";


      optionrussianbtn.src = "";
      optionrussianbtn.style.cssText = "";


      russianbncicon.src = "";
      russianbncicon.style.cssText = "";

      beticonrussian.src = "";
      beticonrussian.style.cssText = "";


      exitbtn.src = "";
      exitbtn.style.cssText = "";




      winnericonrussian.src = "";
      winnericonrussian.style.cssText = "";


      informi.src = "";
      informi.style.cssText = "";




      volumeRussianimg.src = "";
      volumeRussianimg.style.cssText = "";




      betchipsRussianpoker.src = "";
      betchipsRussianpoker.style.cssText = "";




      betchipincrementbtn.src = "";
      betchipincrementbtn.style.cssText = "";



      betchipdecrementbtn.src = "";
      betchipdecrementbtn.style.cssText = "";

      crbndeckcrds.src = crbndeck
      if (chipsstoredcontainerElement !== null) {
        chipsstoredcontainerElement.className = "";
      }

      let chipsElementText = document.getElementById("val0");
      // betcontainerElement.style.cssText = "";
      chipsElementText.className = "";
      let chipsElmtextclass = "";

      const betTextaddtoBetElement = document.getElementById("betTextaddtoBetcrbn");
      if (this.betchipactivated === true) {
        if (isPortrait) {
          gsap.to(this.chipbetref.current, {
            x: 156,
            y: -582,
            // x: -27,
            // y: -925,
            duration: 0.000001
          });


          gsap.to(betTextaddtoBetElement, {
            x: -299,
            y: 617,
            duration: 0.000001
          });

        } else {


          gsap.to(this.chipbetref.current, {
            x: -533,
            y: -189,
            duration: 0.000001
          });


          gsap.to(betTextaddtoBetElement, {
            x: 656,
            y: -500,
            duration: 0.000001

          });


        }

      } else {
        this.betchipactivated = false;
      }




      switch (this.state.count) {
        case 1:
          betchipsRussianpoker.src = chip_10;
          chipsElementText.textContent = this.chipVal[0].label;
          chipsElmtextclass = "valtextPortrait1_crbn";

          break;
        case 2:
          betchipsRussianpoker.src = chip_25;
          chipsElementText.textContent = this.chipVal[1].label;
          chipsElmtextclass = "bet-text-addtoBet20crbn";
          break;
        case 3:
          betchipsRussianpoker.src = chip_50;
          chipsElementText.textContent = this.chipVal[2].label;
          chipsElmtextclass = "bet-text-addtoBet20crbn";
          break;
        case 4:
          betchipsRussianpoker.src = chip_100;
          chipsElementText.textContent = this.chipVal[3].label;
          chipsElmtextclass = "bet-text-addtoBet20crbn";
          break;
        case 5:
          betchipsRussianpoker.src = chip_500;
          chipsElementText.textContent = this.chipVal[4].label;
          chipsElmtextclass = "bet-text-addtoBet20crbn";
          break;
        case 6:
          betchipsRussianpoker.src = chip_1000;
          chipsElementText.textContent = this.chipVal[5].label;
          chipsElmtextclass = "bet-text-addtoBet20crbn";
          break;

        default:
          break;

      }


      const rankinginfodelerElement = document.getElementById("rankinginfodeler_crbn");
      const rankinginfoElement = document.getElementById("rankinginfo_crbn");
      rankinginfodelerElement.className = "";
      rankinginfoElement.className = "";
      rankinginfoElement.style.cssText = "";
      popupbetGreaterthanBalnceElement.className = "";


      betbtntext.className = "";
      foldbtntext.className = "";
      if (isPortrait) {



        if (document.getElementById("Payout_crbn")) {
          document.getElementById("Payout_crbn").classList.remove("payout_crbnLndspe")

        }
       
        if (document.getElementById("PAYOUTTABLE")) {
          document.getElementById("PAYOUTTABLE").classList.remove("shoWPAYOUTTABLElndspe")

        }
        if (document.getElementById("payout_body")) {
          document.getElementById("payout_body").classList.remove("payout_bodylndspe")

        }
        
        if (document.getElementById("heading_payout")) {
          document.getElementById("heading_payout").classList.remove("payoutheadinglndspe")

        }

        if (document.getElementById("Payout_crbn")) {
          document.getElementById("Payout_crbn").classList.add("payout_crbnprtrt")

        }
       
        if (document.getElementById("PAYOUTTABLE")) {
          document.getElementById("PAYOUTTABLE").classList.add("shoWPAYOUTTABLEprtrt")

        }
        if (document.getElementById("payout_body")) {
          document.getElementById("payout_body").classList.add("payout_bodyprtrt")

        }
        
        if (document.getElementById("heading_payout")) {
          document.getElementById("heading_payout").classList.add("payoutheadingprtrt")

        }
        if (this.state.betshigh_balnce !== null) {
          popupbetGreaterthanBalnceElement.classList.add("popupbetshighPortrait_crbn")
        }
        document.getElementById("infocontainercrbn").style.display = "block"
        const rankinginfodelerElement = document.getElementById("rankinginfodeler_crbn");
        const rankinginfoElement = document.getElementById("rankinginfo_crbn");
        if (this.state.pokerrankingdeler !== "") {

          document.getElementById('rankinginfodeler_crbn').className = "";
          rankinginfodelerElement.classList.add("pokerrankingdelerPortrait_crbn")
        } else {
          document.getElementById('rankinginfodeler_crbn').className = "";

        }

        if (this.state.anteglowstatus == 0) {
        
          document.getElementById("anteglow").classList.remove("anteglowlndspe");
          document.getElementById("anteglow").classList.add("anteglowportraite");
        }
        else {

          document.getElementById("anteglow").classList.remove("anteglowlndspe");
          document.getElementById("anteglow").classList.remove("anteglowportraite");

        }
        if (this.state.pokerranking !== "") {


          rankinginfoElement.classList.add("pokerrankingPortait_crbn")
        } else {


          document.getElementById('rankinginfo_crbn').className = "";
        }
        crbndeckcrds.classList.remove("cardDeckLandscapecrbn")
        crbndeckcrds.classList.add("cardDeckportraitecrbn")
        document.getElementById("anteclickCaribbean").src = ""
        document.getElementById("bet_carebean").src = ""
        document.getElementById("betpannel").classList.remove("betpannelcarebean_lndspe");
        document.getElementById("anteclickCaribbean").classList.remove("antebet_carebeanlndspe");
        document.getElementById("bet_carebean").classList.remove("bet_carebeanlndspe");

        // document.getElementById("carebean_ante").classList.add("carebean_lndspe");
        document.getElementById("carebean_bet").classList.remove("carebean_betlndspe");

        document.getElementById("anteclickCaribbean").src = careabeananebet
        document.getElementById("bet_carebean").src = careabeananebet
        document.getElementById("betpannel").classList.add("betpannelcarebean");
        document.getElementById("anteclickCaribbean").classList.add("antebet_carebeanportrite");
        document.getElementById("bet_carebean").classList.add("bet_carebeanportraite");
        // document.getElementById("anteglow").classList.add("anteglowportraite");

        document.getElementById("carebean_bet").classList.add("carebean_betportraite");


        minmaxbetsElement.classList.add("minmaxbets_portraitcrbn");

        // drwtext.classList.remove("drawtextlandspe");
        undotexttext.classList.remove("undotextlndspe");


        // drwtext.classList.remove("drawtextwindows");
        undotexttext.classList.remove("undotextwindows");






        containerPlayerdealerElement.classList.add("pyrdlr_crbnprt");
        document.getElementById("Playercrbn").style.fontSize = "80px"
        pokertablelnspe.src = "";
        pokertablelnspe.style.cssText = "";

        playerDetailsElement.classList.add("playerIdDetailsPortraitcrbn");
        winpopuprspelement.classList.add("winPopupForMptIntlcrbn");
        document.getElementById("playerDealerCardContainercrbn").classList.remove("stage-top-mobile-landscape");
        document.getElementById("playerDealerCardContainercrbn").classList.add("stage-top-mobile-portraitcrbn");
     


       
        undotexttext.classList.add("undotextportraite");
        betbtntext.classList.add("betportraitcrbne");
        foldbtntext.classList.add("foldtextportraitecrbn");

        if (document.getElementById("val0") && chipsElmtextclass !== "") {
          document.getElementById("val0").classList.add("valtextPortrait_crbn", chipsElmtextclass);
        } else {
          document.getElementById("val0").classList.add("valtextPortrait_crbn");
        }

        bacaratbgim_crbn.style.background = `url(${carebeantablebg})`;
        bacaratbgim_crbn.style.backgroundSize = "cover";
        bacaratbgim_crbn.style.width = carebeanjson.bacaratbg.width;
        bacaratbgim_crbn.style.height = carebeanjson.bacaratbg.height;
        bacaratbgim_crbn.style.backgroundSize = carebeanjson.bacaratbg.background;




        caribbeantbleport.src = carebeantableport;

        Object.assign(caribbeantbleport.style, carebeanjson.caribbeantbleport)
        // betchipsRussianpoker.src = betchipsRussian;
        Object.assign(betchipsRussianpoker.style, carebeanjson.betchp);


        betchipincrementbtn.src = betincerementdecrement;
        Object.assign(betchipincrementbtn.style, carebeanjson.beincrementportraite);



        betchipdecrementbtn.src = betincerementdecrement;
        Object.assign(betchipdecrementbtn.style, carebeanjson.bedecrement)


        volumeRussianimg.src = volumeimg;
        Object.assign(volumeRussianimg.style, carebeanjson.volume)




        // optionrussianbtn.style.display = "none";
        optionrussianbtn.src = optionbtn;
        Object.assign(optionrussianbtn.style, carebeanjson.optionbtn);


        dealerBuyCardElement.classList.add('dealerBuyCard');
        dealerChipsAddToBuyElement.classList.add('dealerBuyCardDrawButtonPortrait');



        russianbncicon.src = balanceicon;
        Object.assign(russianbncicon.style, carebeanjson.balanceiconportraite);


        document.getElementById("balancecrbn").className = "";
        document.getElementById("balancecrbn").classList.add("balanceportraitecrbn");
        // document.getElementById("balance").classList.remove("balancelndscapecrbn");

        beticonrussian.src = beticon;

        Object.assign(beticonrussian.style, carebeanjson.beticon);


        document.getElementById("betscrbn").className = "";
        // document.getElementById("bets").classList.remove("betslandspecrbn");
        document.getElementById("betscrbn").classList.add("betsportraitecrbn");

        // exitbtn.src = exitrussian;
        Object.assign(exitbtn.style, carebeanjson.exit);



        winnericonrussian.src = winnericn;
        Object.assign(winnericonrussian.style, carebeanjson.winnericon);



        document.getElementById("winscrbn").className = "";
        document.getElementById("winscrbn").classList.add("winsportraitecrbn");

        informi.src = informicon;
        Object.assign(informi.style, carebeanjson.infrmicn);


        useriddetl.src = useridlmg;
        Object.assign(useriddetl.style, carebeanjson.useridportraite);

        document.getElementById("useridtextcrbn").className = "";
        document.getElementById("useridtextcrbn").classList.add("useridportraitecrbn");










        if (this.state.pokerrankingdeler !== "") {
          rankinginfodelerElement.classList.add("pokerrankingdelerPortrait_crbn")
        }

        if (this.state.pokerranking !== "") {
          rankinginfoElement.classList.add("pokerrankingPortait_crbn")
        }




        this.setState({
          heightOfLayer: 1200,
          widthOfLayer: 550,
          // dealerDistanceFromX: 40,
          // playerDistanceFromX: 180,
          dealerDistanceFromX: -370,
          playerDistanceFromX: -200,
          dealerDistanceFromY: 315,
          PlayerDistanceFromY: 510,
          isMobilePortraitLndsp: true,
          scaleX: 2.8,
          scaleY: 2.8,
          activeScreen: 1,
          antetext: "carebean_anteportraite",
          coverbetpannel: "popup_Portraitcrbn",
          infocrbn: "portraitinfo_crbn",
          mobileAntiMaskCovercss: "mobileAntiMaskCover_crbn",
          Anteglow: "anteglowportraite",
          // betactvglwcss: "betactivePortrait_crbn",
          betbuttoncss: carebeanjson.Betbtnportraite,
          betbtntextcss: "betportraitcrbn",
          foldbuttoncss: carebeanjson.foldbtnportraite,
          foldbtntextcss: "rebettextportraitcrbn",
          undotextcss: carebeanjson.Buybtnportraite,
          undotexttxtcss: "undotextportraite",
          drawbtncss: carebeanjson.Drawbtnportraite,
        
          carebbeanbetchipstoante: carebeanjson.Betchiptoanteprotrait,
          betchipstobonusbetcss: carebeanjson.betchipstobnsPortrait,
          chipsstoredcontainercss: "",
          antebetpdvcss: "chip-center-position_crbn",
          antechpimgcss: "chip20centerpositionPortrait_crbn",
          antespntxtcss: "antebetChipPortrait20_crbn",
          
          bonusimgcss: "bonusChipsForPortrait",
          bonuspntxtcss: "bonusChipsTextForPortrait",
          betchiptobetbtncss: carebeanjson.BetchiptobetPortrait,
          betbtntxtcss: `bet-text-addtoBet_carr-portrait ${chipsElmtextclass}`,
         
        
        
          dealerhandcss: "pokerrankingdelerPortrait_crbn",
          playerhandcss: "pokerrankingPortait_crbn",

          rankinginfodelercss: "pokerrankingdelerPortrait_crbn",
          inforussiancss: "",




        })

      } else {
        if (this.state.betshigh_balnce !== null) {
          popupbetGreaterthanBalnceElement.classList.add("popupbetshighLndsp_crbn")
        }


        document.getElementById("infocontainercrbn").style.display = "none"
        const rankinginfoElement = document.getElementById("rankinginfo_crbn");
        if (this.state.pokerrankingdeler !== "") {
          document.getElementById('rankinginfodeler_crbn').className = "";
          rankinginfodelerElement.classList.add("crbndealer_rank")
        } else {
          document.getElementById('rankinginfodeler_crbn').className = "";

        }
        if (document.getElementById("Payout_crbn")) {
          document.getElementById("Payout_crbn").classList.remove("payout_crbnprtrt")

        }
       
        if (document.getElementById("PAYOUTTABLE")) {
          document.getElementById("PAYOUTTABLE").classList.remove("shoWPAYOUTTABLEprtrt")

        }
        if (document.getElementById("payout_body")) {
          document.getElementById("payout_body").classList.remove("payout_bodyprtrt")

        }
        
        if (document.getElementById("heading_payout")) {
          document.getElementById("heading_payout").classList.remove("payoutheadingprtrt")

        }
       


       
        if (document.getElementById("Payout_crbn")) {
          document.getElementById("Payout_crbn").classList.add("payout_crbnLndspe")

        }
       
        if (document.getElementById("PAYOUTTABLE")) {
          document.getElementById("PAYOUTTABLE").classList.add("shoWPAYOUTTABLElndspe")

        }
        if (document.getElementById("payout_body")) {
          document.getElementById("payout_body").classList.add("payout_bodylndspe")

        }
        
        if (document.getElementById("heading_payout")) {
          document.getElementById("heading_payout").classList.add("payoutheadinglndspe")

        }
       

        if (this.state.anteglowstatus == 0) {
       
          document.getElementById("anteglow").classList.remove("anteglowportraite");
          document.getElementById("anteglow").classList.add("anteglowlndspe");
        }
        else {

          document.getElementById("anteglow").classList.remove("anteglowportraite");

          document.getElementById("anteglow").classList.remove("anteglowlndspe");

        }

        if (this.state.pokerranking !== "") {


          rankinginfoElement.classList.add("pokerrankingPlayerLndsp_crbn")
        } else {
          document.getElementById('rankinginfodeler_crbn').className = "";

        }
        crbndeckcrds.classList.remove("cardDeckportraitecrbn")
        crbndeckcrds.classList.add("cardDeckLandscapecrbn")
        document.getElementById("anteclickCaribbean").src = ""
        document.getElementById("bet_carebean").src = ""
        document.getElementById("betpannel").classList.remove("betpannelcarebean");
        document.getElementById("anteclickCaribbean").classList.remove("antebet_carebeanportrite");
        document.getElementById("bet_carebean").classList.remove("bet_carebeanportraite");
        // document.getElementById("anteglow").classList.remove("anteglowportraite");

        document.getElementById("carebean_bet").classList.remove("carebean_betportraite");

        document.getElementById("anteclickCaribbean").src = careabeananebet
        document.getElementById("bet_carebean").src = careabeananebet
        document.getElementById("betpannel").classList.add("betpannelcarebean_lndspe");
        document.getElementById("anteclickCaribbean").classList.add("antebet_carebeanlndspe");
        document.getElementById("bet_carebean").classList.add("bet_carebeanlndspe");
        // document.getElementById("anteglow").classList.add("anteglowlndspe");
        // document.getElementById("carebean_ante").classList.add("carebean_lndspe");
        document.getElementById("carebean_bet").classList.add("carebean_betlndspe");
      
        document.getElementById("playerDealerCardContainercrbn").classList.remove("stage-top-mobile-portraitcrbn");
        document.getElementById("playerDealerCardContainercrbn").classList.add("stage-top-mobile-landscape");
        // alert(",...land")
        this.setState({
          // heightOfLayer: 900,
          // widthOfLayer: 1600,
          heightOfLayer: 698,
          widthOfLayer: 720,

          dealerDistanceFromX: -340,
          playerDistanceFromX: -170,
          dealerDistanceFromY: 20,
          PlayerDistanceFromY: 100,
          isMobilePortraitLndsp: true,
          scaleX: 2.8,
          scaleY: 2.8,
          activeScreen: 1,
          antetext: "carebean_antelndspe",
          // infocrbn: "lndspinfo_crbn",
          mobileAntiMaskCovercss: "maskcoverlndspe_crbn",
          Anteglow: "anteglowlndspe",
          coverbetpannel: "popup_foldLndspcrbn",
          betactvglwcss: "betactivelndspe_crbn",
          betbuttoncss: carebeanjson.Betbtnlndspe,
          betbtntextcss: "betLandscapecrbn",
          foldbuttoncss: carebeanjson.foldbtnlndspe,
          foldbtntextcss: "foldtextLandscapecrbn",
          undotextcss: carebeanjson.Buybtnlndspe,
          undotexttxtcss: "undotext_lndspe",
          drawbtncss: carebeanjson.Drawbtnlndspe,
          drawbtntxtcss: "drawtextLndsp",
          carebbeanbetchipstoante: carebeanjson.betchiplndspe,
          betchipstobonusbetcss: carebeanjson.betchipstobnsLndsp,
          chipsstoredcontainercss: "chipsstoredcontainerLndsp_crbn",
          antebetpdvcss: "chip-center-position_crbn-lndsp",
          antechpimgcss: "chip20centerpositionlndspe_crbn",
          antespntxtcss: "antebetChiplndspe20_crbn",
          bonusdivcss: "bonusChipsCntrFrLndsp",
          bonusimgcss: "bonusChipsForPortrait",
          bonuspntxtcss: "bonusChipsTextfrLndsp",
          betchiptobetbtncss: carebeanjson.BetchiptobetLndsp,
          betbtntxtcss: `bet-text-addtoBet_carr-lndsp ${chipsElmtextclass}`,
       
          
       
          insurancedivcss: "chip-to-insurence-lndsp",
          insurancechipcss: "chiptoinsurenceLndSpe",
          insurancetxtcss: "insuranceTextLndspe",
          dealerhandcss: "crbndealer_rank",
          playerhandcss: "pokerrankingPlayerLndsp_crbn",
          rankinginfodelercss: "crbndealer_rank",
          inforussiancss: "lndspinfo_crbn",



        });

        if (this.state.pokerrankingdeler !== "") {
          rankinginfodelerElement.classList.add("pokerrankingPortait_crbn")
        }


        if (this.state.pokerranking !== "") {
          rankinginfoElement.classList.add("pokerrankingPlayerLndsp_crbn")
        }



        document.getElementById("landscapeInfocrbn").classList.add("lndspinfo_crbn");
        document.getElementById("inforussianlandcrbn").classList.add("infotext_lndspecrbn")

        minmaxbetsElement.classList.add("minmaxbets_lndspcrbn");

        containerPlayerdealerElement.classList.add("pyrdlr_crbnlnsp");
        if (chipsstoredcontainerElement) {
          chipsstoredcontainerElement.classList.add("chipsstoredcontainerLndsp_crbn");
        }
        document.getElementById("Playercrbn").style.fontSize = "80px";

        document.getElementById("landscapeInfocrbn").classList.add("lndspinfo_crbn");
        document.getElementById("inforussianlandcrbn").classList.add("infotext_lndspecrbn")



       
        // document.getElementById("betcontainer").classList.remove("betpannelrussian");

        if (document.getElementById("val0") && chipsElmtextclass !== "") {
          document.getElementById("val0").classList.add("valtextLandscapecrbn", chipsElmtextclass);
        } else {
          document.getElementById("val0").classList.add("valtextLandscapecrbn");
        }
        // Object.assign(betcontainerElement.style, carebeanjson.betpannelrussianIandscape);
        // alert("...lanscape")
       
        undotexttext.classList.remove("undotextportraite");

        // drwtext.classList.remove("drawtextwindows");
        undotexttext.classList.remove("undotextwindows");
        // foldbtntext.className = "";
        // betbtntext.className = "";

        // drwtext.classList.add("drawtextlandspe");
        undotexttext.classList.add("undotextlndspe");
        // betbtntext.classList.add("betlndspe");
        // foldbtntext.classList.add("foldtextlndspe");


        caribbeantbleport.src = "";
        caribbeantbleport.style.cssText = "";





        playerDetailsElement.classList.add("playerIdDetailsLandscapecrbn");
        bacaratbgim_crbn.style.background = `url(${landscapebg})`;
        bacaratbgim_crbn.style.backgroundSize = "cover";
        bacaratbgim_crbn.style.width = carebeanjson.bacaratbg.width;
        bacaratbgim_crbn.style.height = carebeanjson.bacaratbg.height;

        pokertablelnspe.src = pokertablelandsp;
        Object.assign(pokertablelnspe.style, carebeanjson.pokertableland);



        Object.assign(betchipsRussianpoker.style, carebeanjson.betchiplndspe);
        // document.getElementById("carebean_bet").className = "";
        // document.getElementById("carebean_bet").classList.add("bettextlndspe");



        volumeRussianimg.src = volumeimg;
        Object.assign(volumeRussianimg.style, carebeanjson.volumelnspe);


        useriddetl.src = useridlmg;
        Object.assign(useriddetl.style, carebeanjson.useridlndspe);


        document.getElementById("useridtextcrbn").className = "";
        document.getElementById("useridtextcrbn").classList.add("useridlandscapecrbn");


        optionrussianbtn.src = optionbtn;
        Object.assign(optionrussianbtn.style, carebeanjson.optionbtnlandspe);



        beticonrussian.src = beticon;
        Object.assign(beticonrussian.style, carebeanjson.beticonlandspe);


        document.getElementById("betscrbn").className = "";
        document.getElementById("betscrbn").classList.add("betslandspecrbn");
        // beticonrussian.style.transform =carebeanjson.beticonlandspe.transform;

        russianbncicon.src = balanceicon;
        Object.assign(russianbncicon.style, carebeanjson.balanceiconlndspe);


        document.getElementById("balancecrbn").className = "";
        document.getElementById("balancecrbn").classList.add("balancelndscapecrbn");
        // russianbncicon.style.transform = carebeanjson.balanceicon.transform;

        // exitbtn.src = exitrussian;
        Object.assign(exitbtn.style, carebeanjson.exitlanspe);


        winnericonrussian.src = winnericn;
        Object.assign(winnericonrussian.style, carebeanjson.winnericonlndspe);


        document.getElementById("winscrbn").className = "";
        document.getElementById("winscrbn").classList.add("winslandspecrbn");

        informi.src = informicon;
        Object.assign(informi.style, carebeanjson.infrmicnlnspe);

        betchipincrementbtn.src = betincerementdecrement;
        Object.assign(betchipincrementbtn.style, carebeanjson.betincreamentlndspe);



        betchipdecrementbtn.src = betincerementdecrement;
        Object.assign(betchipdecrementbtn.style, carebeanjson.bedecrementlndspe);

      }
    }
  };


  playPokerChipSound = (chipSound) => {
    const { isPlayingChip, mutesounds } = this.state;
    let currentSound = chipSound;
    if (!mutesounds) {

      if (isPlayingChip) {
        chipSound.stop();
      }
      if (!chipSound) {
        currentSound = this.initializeChipSound();
      }

      currentSound.play();
      this.setState({
        isPlayingChip: true
      })
    }

  };

  Gamerules = () => {
    this.setState({ ShowGamerules: !this.state.ShowGamerules, ShowHistory: false, ShowLimits: false }, () => {
      if (
        navigator.userAgentData &&
        navigator.userAgentData.platform &&
        navigator.userAgentData.platform == "Windows"
      ) {

        // this.setState({ShowGamerules:true},()=>{


        document.getElementById("ShowGamerulescrbn")?.classList.remove("Gamerulescontainerportcrbn")
        document.getElementById("gameruleMainHeadingcrbn")?.classList.remove("gameruleMainHeadingcrbnport")
        document.getElementById("unorderedlistitemscrbn")?.classList.remove("unorderedlistitemscrbnport")
        document.getElementById("ShowGamerulescrbn")?.classList.remove("Gamerulescontainerlndcrbn")
        document.getElementById("gameruleMainHeadingcrbn")?.classList.remove("gameruleMainHeadingcrbnlnd")
        document.getElementById("unorderedlistitemscrbn")?.classList.remove("unorderedlistitemscrbnlnd")

        document.getElementById("ShowGamerulescrbn")?.classList.add("Gamerulescontainercrbn")
        document.getElementById("gameruleMainHeadingcrbn")?.classList.add("gameruleMainHeadingcrbn")
        document.getElementById("unorderedlistitemscrbn")?.classList.add("unorderedlistitemscrbn")

        // })

      }
      else {
        if (window.innerHeight > window.innerWidth) {


          document.getElementById("ShowGamerulescrbn")?.classList.remove("Gamerulescontainercrbn")
          document.getElementById("gameruleMainHeadingcrbn")?.classList.remove("gameruleMainHeadingcrbn")
          document.getElementById("unorderedlistitemscrbn")?.classList.remove("unorderedlistitemscrbn")
          document.getElementById("ShowGamerulescrbn")?.classList.remove("Gamerulescontainerlndcrbn")
          document.getElementById("gameruleMainHeadingcrbn")?.classList.remove("gameruleMainHeadingcrbnlnd")
          document.getElementById("unorderedlistitemscrbn")?.classList.remove("unorderedlistitemscrbnlnd")

          document.getElementById("ShowGamerulescrbn")?.classList.add("Gamerulescontainerportcrbn")
          document.getElementById("gameruleMainHeadingcrbn")?.classList.add("gameruleMainHeadingcrbnport")
          document.getElementById("unorderedlistitemscrbn")?.classList.add("unorderedlistitemscrbnport")



        }
        else {

          document.getElementById("ShowGamerulescrbn")?.classList.remove("Gamerulescontainercrbn")
          document.getElementById("gameruleMainHeadingcrbn")?.classList.remove("gameruleMainHeadingcrbn")
          document.getElementById("unorderedlistitemscrbn")?.classList.remove("unorderedlistitemscrbn")
          document.getElementById("ShowGamerulescrbn")?.classList.remove("Gamerulescontainerportcrbn")
          document.getElementById("gameruleMainHeadingcrbn")?.classList.remove("gameruleMainHeadingcrbnport")
          document.getElementById("unorderedlistitemscrbn")?.classList.remove("unorderedlistitemscrbnport")
          document.getElementById("ShowGamerulescrbn")?.classList.add("Gamerulescontainerlndcrbn")
          document.getElementById("gameruleMainHeadingcrbn")?.classList.add("gameruleMainHeadingcrbnlnd")
          document.getElementById("unorderedlistitemscrbn")?.classList.add("unorderedlistitemscrbnlnd")



        }
      }
    })
  }




  ShowHistory() {



    this.setState({ ShowHistory: !this.state.ShowHistory, ShowGamerules: false, ShowLimits: this.state.ShowLimits ? false : this.state.ShowLimits, ishistorytable: true }, () => {



      if (
        navigator.userAgentData &&
        navigator.userAgentData.platform &&
        navigator.userAgentData.platform == "Windows"
      ) {

        if (document.getElementById("histry_russian_crbn")) {
          document.getElementById("histry_russian_crbn")?.classList.remove("histry_portraitecrbn")

        }
        if (document.getElementById("exithistory")) {
          document.getElementById("exithistory")?.classList.remove("exihstrybuttonportraitecrbn")

        }
        if (document.getElementById("prevhistory_crbn")) {
          document.getElementById("prevhistory_crbn")?.classList.remove("showprevhsrytbleportraite_crbn")

        }
        if (document.getElementById("histry_body_crbn")) {
          document.getElementById("histry_body_crbn")?.classList.remove("histry_bodyportraite_crbn")
        }
        if (document.getElementById("heading_hstiry_crbn")) {
          document.getElementById("heading_hstiry_crbn")?.classList.remove("heading_hstiryportraite_crbn")

        }
        if (document.getElementById("histry_russian_crbn")) {
          document.getElementById("histry_russian_crbn")?.classList.remove("histry_russianlndspecrbn")

        }
        if (document.getElementById("exithistory")) {
          document.getElementById("exithistory")?.classList.remove("exihstrybuttonlndspecrbn")

        }
        if (document.getElementById("prevhistory_crbn")) {
          document.getElementById("prevhistory_crbn")?.classList.remove("showprevhsrytblelndspecrbn")

        }
        if (document.getElementById("histry_body_crbn")) {
          document.getElementById("histry_body_crbn")?.classList.remove("histry_bodylndspecrbn")

        }
        if (document.getElementById("heading_hstiry_crbn")) {
          document.getElementById("heading_hstiry_crbn")?.classList.remove("heading_hstirylndspecrbn")

        }


        if (document.getElementById("histry_russian_crbn")) {
          document.getElementById("histry_russian_crbn").classList.add("histry_russian_crbn")

        }
        if (document.getElementById("exithistory")) {
          document.getElementById("exithistory").classList.add("exihstrybuttoncrbn")

        }
        if (document.getElementById("prevhistory_crbn")) {
          document.getElementById("prevhistory_crbn").classList.add("showprevhsrytble")

        }
        if (document.getElementById("histry_body_crbn")) {
          document.getElementById("histry_body_crbn").classList.add("histry_bodycrbn")

        }
        if (document.getElementById("heading_hstiry_crbn")) {
          document.getElementById("heading_hstiry_crbn").classList.add("heading_hstiry_crbn")

        }

       
      }
      else {

        if (window.innerHeight > window.innerWidth) {

          if (document.getElementById("histry_russian_crbn")) {
            document.getElementById("histry_russian_crbn")?.classList.remove("histry_russianlndspecrbn")

          }
          if (document.getElementById("exithistory")) {
            document.getElementById("exithistory")?.classList.remove("exihstrybuttonlndspecrbn")

          }
          if (document.getElementById("prevhistory_crbn")) {
            document.getElementById("prevhistory_crbn")?.classList.remove("showprevhsrytblelndspecrbn")

          }
          if (document.getElementById("histry_body_crbn")) {
            document.getElementById("histry_body_crbn")?.classList.remove("histry_bodylndspecrbn")

          }
          if (document.getElementById("heading_hstiry_crbn")) {
            document.getElementById("heading_hstiry_crbn")?.classList.remove("heading_hstirylndspecrbn")

          }
          if (document.getElementById("histry_russian_crbn")) {
            document.getElementById("histry_russian_crbn")?.classList.remove("histry_russian_crbn")

          }
          if (document.getElementById("exithistory")) {
            document.getElementById("exithistory")?.classList.remove("exihstrybuttoncrbn")

          }
          if (document.getElementById("prevhistory_crbn")) {
            document.getElementById("prevhistory_crbn")?.classList.remove("showprevhsrytble")

          }
          if (document.getElementById("histry_body_crbn")) {
            document.getElementById("histry_body_crbn")?.classList.remove("histry_bodycrbn")

          }
          if (document.getElementById("heading_hstiry_crbn")) {
            document.getElementById("heading_hstiry_crbn").classList.add("heading_hstiry_crbn")

          }

          if (document.getElementById("histry_russian_crbn")) {
            document.getElementById("histry_russian_crbn").classList.add("histry_portraitecrbn")

          }
          if (document.getElementById("exithistory")) {
            document.getElementById("exithistory").classList.add("exihstrybuttonportraitecrbn")

          }
          if (document.getElementById("prevhistory_crbn")) {
            document.getElementById("prevhistory_crbn").classList.add("showprevhsrytbleportraite_crbn")

          }
          if (document.getElementById("histry_body_crbn")) {
            document.getElementById("histry_body_crbn").classList.add("histry_bodyportraite_crbn")

            if (document.getElementById("heading_hstiry_crbn")) {
              document.getElementById("heading_hstiry_crbn").classList.add("heading_hstiryportraite_crbn")

            }

          }


        }
        else {


          if (document.getElementById("histry_russian_crbn")) {
            document.getElementById("histry_russian_crbn")?.classList.remove("histry_russian_crbn")

          }
          if (document.getElementById("exithistory")) {
            document.getElementById("exithistory")?.classList.remove("exihstrybuttoncrbn")

          }
          if (document.getElementById("prevhistory_crbn")) {
            document.getElementById("prevhistory_crbn")?.classList.remove("showprevhsrytble")

          }
          if (document.getElementById("histry_body_crbn")) {
            document.getElementById("histry_body_crbn")?.classList.remove("histry_bodycrbn")

          }
          if (document.getElementById("heading_hstiry_crbn")) {
            document.getElementById("heading_hstiry_crbn")?.classList.remove("heading_hstiry_crbn")

          }

          if (document.getElementById("histry_russian_crbn")) {
            document.getElementById("histry_russian_crbn")?.classList.remove("histry_portraitecrbn")

          }
          if (document.getElementById("exithistory")) {
            document.getElementById("exithistory")?.classList.remove("exihstrybuttonportraitecrbn")

          }
          if (document.getElementById("prevhistory_crbn")) {
            document.getElementById("prevhistory_crbn")?.classList.remove("showprevhsrytbleportraite_crbn")

          }
          if (document.getElementById("histry_body_crbn")) {
            document.getElementById("histry_body_crbn")?.classList.remove("histry_bodyportraite_crbn")
          }
          if (document.getElementById("heading_hstiry_crbn")) {
            document.getElementById("heading_hstiry_crbn").classList.remove("heading_hstiryportraite_crbn")

          }


          if (document.getElementById("histry_russian_crbn")) {
            document.getElementById("histry_russian_crbn").classList.add("histry_russianlndspecrbn")

          }
          if (document.getElementById("exithistory")) {
            document.getElementById("exithistory").classList.add("exihstrybuttonlndspecrbn")

          }
          if (document.getElementById("prevhistory_crbn")) {
            document.getElementById("prevhistory_crbn").classList.add("showprevhsrytblelndspecrbn")

          }
          if (document.getElementById("histry_body_crbn")) {
            document.getElementById("histry_body_crbn").classList.add("histry_bodylndspecrbn")

          }
          if (document.getElementById("heading_hstiry_crbn")) {
            document.getElementById("heading_hstiry_crbn").classList.add("heading_hstirylndspecrbn")

          }




        }

      }

    })


  }

  showPreviousBetsStatus = () => {


    // console.log(this.props.gameState);
    const { response } = this.props.gameState;

    if (
      navigator.userAgentData &&
      navigator.userAgentData.platform &&
      navigator.userAgentData.platform == "Windows"
    ) {
      if (response) {
        document.getElementById("betpannelcarebbeancover").style.display = "block";
        document.getElementById("betpannelcarebbeancover").classList.add("bet_pannelcaribbean_CRBN");
      }
      else {
        document.getElementById("betpannelcarebbeancover").style.display = "none";
        document.getElementById("betpannelcarebbeancover").classList.remove("bet_pannelcaribbean_CRBN");

      }
    }

    if (response) {


      this.setState({
        anteBetAmount: response.anteBetAmount,

      });

      switch (response.action) {
        case "PARAM_BET":

          this.showcardsAdBetsParamState(response, response.playerCardsSorted, response.dealerCards);
          this.showParamBetStatus(false);

          this.setState({
            infoText: response.message,
            pokerranking: response.playerHand,
            totalbet: response.anteBetAmount,
            buttontext: "fold",
            betbutton: "Bet",
            anteglowstatus: 1

          })


          break;
        case "BET":
          this.showCarebbeanBetreapnce(response);
          break;
        case "FOLD":
          this.setState({ anteglowstatus: 0 })
          // this.Show_FoldHandrespCaribbean(response);
          if (
            navigator.userAgentData &&
            navigator.userAgentData.platform &&
            navigator.userAgentData.platform == "Windows"
          ) {

            this.Show_FoldHandrespCaribbean(response);

            const buybutton = document.getElementById("undobutton");
            const betbutton = document.getElementById("betbtn");
            const foldbutton = document.getElementById("Foldbtncrbn");

            const buytextElement = document.getElementById("undotext");
            const betbtntextElement = document.getElementById("betbtntext");
            const foldtextElement = document.getElementById("foldtextcrbn");

            betbtntextElement.textContent = "";
            foldtextElement.textContent = "";

            buytextElement.textContent = "";




            buybutton.src = "";
            buybutton.style.cssText = "";

            betbutton.src = "";
            betbutton.style.cssText = "";


            foldbutton.src = "";
            foldbutton.style.cssText = "";

          } else {
            this.showPlayerorDealerorFold(response);
          }

          break;
        case "CONFIRM_FOLD":
          // this.Show_FoldHandrespCaribbean(response);
          if (
            navigator.userAgentData &&
            navigator.userAgentData.platform &&
            navigator.userAgentData.platform == "Windows"
          ) {
            this.Show_FoldHandrespCaribbean(response);

          } else {
            this.showPlayerorDealerorFold(response);
          }


          break;


        default:
          return []
      }

    }




  }



  showcardsAdBetsParamState = (response, playerFinalCards, dealerCards) => {

    // this.onDrawingCards(response.playerFinalCards, false);
    console.log(dealerCards)
    console.log(playerFinalCards)




    let convrAntebt = this.topLable_rsp(response.anteBetAmount);

    if (
      navigator.userAgentData &&
      navigator.userAgentData.platform &&
      navigator.userAgentData.platform == "Windows"
    ) {

      if (Number(response.anteBetAmount) > 0) {
        this.setState((prevState) => ({
          storebetchips: [
            ...prevState.storebetchips,
            <div id="chipcentercarebean">
              <img id="centerchips" className="carebeanantechip_10"
                src={chip_10}
                alt="Chip 10"
              />
              <span id="antebetval_10" className="antebet_caribean10">{response.anteBetAmount}</span>
            </div>

          ],
        }));
      }



    }
    else {

      if (Number(response.anteBetAmount) > 0) {
        this.setState((prevState) => ({
          storeantebetchips: [
            ...prevState.storeantebetchips,
            { chip: chip_10, chipvalue: convrAntebt, chipcolor1: "valtextPortrait1_crbn" },

          ],
        }));

      }



    }



    this.shuffledPlayerCards = [...playerFinalCards]
    for (let m = 0; m < dealerCards.length; m++) {
      if (dealerCards[m] === '-1') {
        this.randomDealerCards.push('xx')

      } else {
        this.randomDealerCards.push(dealerCards[m]);


      }
    }

    console.log("this.randomDealerCards", this.randomDealerCards);
    console.log("this.randomPlayerCards", this.shuffledPlayerCards);

    let cnt = this.shuffledPlayerCards.length;
    let cnt2 = this.randomDealerCards.length;
    for (let i = 0; i < cnt2; i++) {
      let b1 = this.randomDealerCards[i];
      switch (i) {
        case 0:
          this.dealerCardsContainer.current.showPreviousDealercards("cardZero", b1, cnt);
          break;
        case 1:
          this.dealerCardsContainer.current.showPreviousDealercards("cardOne", b1, cnt);
          break;
        case 2:
          this.dealerCardsContainer.current.showPreviousDealercards("cardTwo", b1, cnt);
          break;
        case 3:
          this.dealerCardsContainer.current.showPreviousDealercards("cardThree", b1, cnt);
          break;
        case 4:
          this.dealerCardsContainer.current.showPreviousDealercards("cardFour", b1, cnt);
          break;
        case 5:
          this.dealerCardsContainer.current.showPreviousDealercards("cardFive", b1, cnt);
          break;
        default:
          break;
      }
    }

    for (let i = 0; i < cnt; i++) {
      let b2 = this.shuffledPlayerCards[i];
      switch (i) {
        case 0:
          this.cardsRef.current.showPreviousPlayercards("cardZero", b2, cnt);
          break;
        case 1:
          this.cardsRef.current.showPreviousPlayercards("cardOne", b2, cnt);
          break;
        case 2:
          this.cardsRef.current.showPreviousPlayercards("cardTwo", b2, cnt);
          break;
        case 3:
          this.cardsRef.current.showPreviousPlayercards("cardThree", b2, cnt);
          break;
        case 4:
          this.cardsRef.current.showPreviousPlayercards("cardFour", b2, cnt);
          break;
        case 5:
          this.cardsRef.current.showPreviousPlayercards("cardFive", b2, cnt);
          // this.cardsRef.current.addCards("cardFive", b2, cnt);
          break;
        default:
          break;
      }

    }



  }





  showPlayerorDealerorFold = (res) => {


    // document.getElementById("betglow").classList.remove("anteglowportraite");
    // document.getElementById("betglow").classList.remove("anteglowlndspe");
    if (res.message === `Attention! You have a ${res.playerHand}. Are you sure you want to fold?`) {
      document.getElementById("cnfrmfoldpop").style.visibility = "visible";
      this.showcardsAdBetsParamState(res, res.playerCardsSorted, res.dealerCards);

      // this.showParamBetStatus(false); //  disble the all button chips,  if we have are you sure you want to fold cards?
      setTimeout(() => {
        this.setState({
          infoText: res.message,
          pokerranking: res.playerHand,
          totalbet: res.anteBetAmount,
          buttontext: "fold",
          betbutton: "Bet",
          anteglowstatus: 1
        })

      }, 100)

      const rankinginfodelerElement = document.getElementById("rankinginfodeler_crbn");
      const rankinginfoElement = document.getElementById("rankinginfo_crbn");
      const confirmbuydealerPopupElement = document.getElementById("cnfrmfoldpop");
      if (window.innerHeight > window.innerWidth) {

        rankinginfodelerElement.classList.add("pokerrankingPortait_crbn")

        document.getElementById("mobileAntiMaskCover_crbn").style.display = "block";
        document.getElementById("mobileAntiMaskCover_crbn").classList.add("mobileAntiMaskCover_crbn")



        confirmbuydealerPopupElement.className = "";
        confirmbuydealerPopupElement.style.display = "block";
        confirmbuydealerPopupElement.classList.add("popup_Portraitcrbn");
        document.getElementById("alertTxtcrbn").innerHTML = res.message;
        document.getElementById("alertTxtcrbn").classList.add('alertTxtPortraitcrbn');
        document.getElementById("yesbtn").classList.add("yesbutton_popup_portraitcrbn")
        document.getElementById("nobtn").classList.add("nobutton_popup_portraitcrbn")

      }
      else {


        rankinginfoElement.classList.add("pokerrankingPlayerLndsp_crbn")
        document.getElementById("mobileAntiMaskCover_crbn").style.display = "block";
        document.getElementById("mobileAntiMaskCover_crbn").classList.add("maskcoverlndspe_crbn")



        confirmbuydealerPopupElement.classList.add("popup_foldLndspcrbn");
        document.getElementById("alertTxtcrbn").innerHTML = res.message;
        document.getElementById("alertTxtcrbn").classList.add('alertTxtPortraitcrbn');
        document.getElementById("yesbtn").classList.add("yesbutton_popup_portraitcrbn")
        document.getElementById("nobtn").classList.add("nobutton_popup_portraitcrbn")

      }



    } else {


      document.getElementById("cnfrmfoldpop").style.visibility = "hidden";
      document.getElementById("rankinginfodeler_crbn")
      const betbutton = document.getElementById("betbtn");
      const foldbutton = document.getElementById("Foldbtncrbn");
      const betbtntextElement = document.getElementById("betbtntext");
      const foldtextElement = document.getElementById("foldtextcrbn");
      const rankinginfodelerElement = document.getElementById("rankinginfodeler_crbn");
      const rankinginfoElement = document.getElementById("rankinginfo_crbn");


      this.setState({
        previousAnteBetAmount: res.anteBetAmount,

        infoText: this.props.language.Place_antebet,
        totalbet: 0,
        anteBetAmount: 0,
        anteglowstatus: 0,
        pokerranking: "",
        pokerrankingdeler: "",
        //  totalbet:res.totalBetAmount,,
        betbutton: "REBET",
        buttontext: "REBETX2",
      });


      // document.getElementById("betpannelrussiancover").style.display = "none";
      betbutton.src = Betbtn;
      foldbutton.src = Foldbnwindows;

      betbtntextElement.className = "";
      foldtextElement.className = "";
      rankinginfodelerElement.className = "";
      rankinginfoElement.className = "";


      betbtntextElement.textContent = this.props.language.Rebet;
      foldtextElement.textContent = this.props.language.RebetX2;

      if (Number(res.anteBetAmount) > 0) {
        let convrAntebt = this.topLable_rsp(res.anteBetAmount);
        this.setState((prevState) => ({
          // storeantebetchips: [
          prevStatestoreantebetchips: [
            ...prevState.storeantebetchips,
            { chip: this.ReturnChipBasedAmount(res.anteBetAmount), chipvalue: convrAntebt, chipcolor1: "valtextPortrait1" },

          ],
        }));

      }



      if (window.innerHeight > window.innerWidth) {
        // portrait;


        // document.getElementById("betglow").src = "";
        // document.getElementById("betglow").classList.remove("betactivePortrait_crbn")
        // document.getElementById("betglow").classList.remove("betactivelndspe_crbn")

        setTimeout(() => {
          if (document.getElementById("chipcentercarebean")) {
            gsap.to("#chipcentercarebean", { x: 13, y: 1200, duration: 0.000001, zIndex: -2, opacity: 0 });
          }

          if (document.getElementById("betchipsaddtobetcaribn")) {
            gsap.to("#betchipsaddtobetcaribn", { x: 1, y: 76, duration: 0.000001, zIndex: -2, opacity: 0 });
            gsap.to("#betTextaddtoBetcrbn", { x: 153, y: 185, duration: 0.000001, zIndex: -2, opacity: 0 });
          }




        }, 100);



        betbtntextElement.classList.add("betportraitcrbn");
        Object.assign(betbutton.style, carebeanjson.Betbtnportraite);
        // Object.assign(betbtntextElement.style, carebeanjson.betportraite);
        Object.assign(foldbutton.style, carebeanjson.foldbtnportraite);
        foldtextElement.classList.add("rebettextportraitcrbn");

      } else {

        // setTimeout(() => {
        //   document.getElementById("betglow").classList.remove("betactivePortrait_crbn")
        //   document.getElementById("betglow").classList.remove("betactivelndspe_crbn")

        // }, 200)

        // landscape
        document.getElementById("cnfrmfoldpop").style.visibility = "hidden"

        // betbtntextElement.classList.add("betportrait");
        Object.assign(betbutton.style, carebeanjson.Betbtnlndspe);
        betbtntextElement.classList.add('betLandscapecrbn');
        Object.assign(foldbutton.style, carebeanjson.foldbtnlndspe);
        foldtextElement.classList.add("foldtextLandscapecrbn");


      }
      this.clearActivesWeDid();
    }













  }



  betChipsIncrementDecremenAction = () => {
    const betchipsRussianpoker = document.getElementById("betchipscrbn");

    if (
      navigator.userAgentData &&
      navigator.userAgentData.platform &&
      navigator.userAgentData.platform == "Windows"
    ) {


      document.getElementById("val0").classList.remove("chip_10text");
      switch (this.state.count) {
        case 1:
          console.log(this.storebetchips);
          // this.setState({ anteBetAmount: Number(this.chipVal[0].val) })
          betchipsRussianpoker.src = chip_10;
          document.getElementById("val0").classList.remove("incrementval_windowscrbn");
          document.getElementById("val0").textContent = this.chipVal[0].label;
          document.getElementById("val0").classList.add("valtext_windows_chip10crbn", "chip_10text");

          break;
        case 2:
          // this.setState({ anteBetAmount: Number(this.chipVal[1].val) })
          // this.setState({ totalbet: 20 })
          document.getElementById("val0").textContent = this.chipVal[1].label;
          document.getElementById("val0").classList.add("incrementval_windowscrbn");
          betchipsRussianpoker.src = chip_25;
          break;
        case 3:
          // this.setState({ anteBetAmount: Number(this.chipVal[2].val) })
          // this.setState({ totalbet: 50 })
          document.getElementById("val0").textContent = this.chipVal[2].label;
          document.getElementById("val0").classList.add("incrementval_windowscrbn");
          betchipsRussianpoker.src = chip_50;

          break;
        case 4:
          // this.setState({ anteBetAmount: Number(this.chipVal[3].val) })
          // this.setState({ totalbet: 100 })
          document.getElementById("val0").textContent = this.chipVal[3].label;
          document.getElementById("val0").classList.add("incrementval_windowscrbn");
          betchipsRussianpoker.src = chip_100;

          break;
        case 5:
          // this.setState({ anteBetAmount: Number(this.chipVal[4].val) })
          // this.setState({ totalbet: 500 })
          document.getElementById("val0").textContent = this.chipVal[4].label;
          document.getElementById("val0").classList.add("incrementval_windowscrbn");
          betchipsRussianpoker.src = chip_500;
          break;
        case 6:
          // this.setState({ anteBetAmount: Number(this.chipVal[4].val) })
          // this.setState({ totalbet: 500 })
          document.getElementById("val0").textContent = this.chipVal[5].label;
          document.getElementById("val0").classList.add("incrementval_windowscrbn");
          betchipsRussianpoker.src = chip_1000;
          break;

        default:
          break;
      }

      betchipsRussianpoker.style.position = carebeanjson.betchpwindows.position;
      betchipsRussianpoker.style.width = carebeanjson.betchpwindows.width;
      betchipsRussianpoker.style.top = carebeanjson.betchpwindows.top;
      betchipsRussianpoker.style.left = carebeanjson.betchpwindows.left;
      betchipsRussianpoker.style.zIndex = carebeanjson.betchpwindows.zIndex;


    } else {
      const isPortrait = window.innerHeight > window.innerWidth;

      document.getElementById("val0").textContent = "";
      document.getElementById("val1").textContent = "";
      document.getElementById("val2").textContent = "";
      document.getElementById("val3").textContent = "";

      console.log(this.state.count)
      document.getElementById("val0").classList.remove("valtextPortrait1_crbn");

      switch (this.state.count) {
        case 1:
          console.log(this.storebetchips);
          betchipsRussianpoker.src = chip_10;
          document.getElementById("val0").textContent = this.chipVal[0].label;
          // this.setState({ anteBetAmount: Number(this.chipVal[0].val) })
          document.getElementById("val0").classList.add("valtextPortrait_crbn", "valtextPortrait1_crbn");

          break;
        case 2:
          // this.setState({ anteBetAmount: Number(this.chipVal[1].val) })
          document.getElementById("val0").textContent = this.chipVal[1].label;
          document.getElementById("val0").classList.add("valtextPortrait_crbn");

          betchipsRussianpoker.src = chip_25;
          break;
        case 3:
          // this.setState({ anteBetAmount: Number(this.chipVal[2].val) })
          document.getElementById("val0").textContent = this.chipVal[2].label;
          document.getElementById("val0").classList.add("valtextPortrait_crbn");
          betchipsRussianpoker.src = chip_50;

          break;
        case 4:
          // this.setState({ anteBetAmount: Number(this.chipVal[3].val) })
          document.getElementById("val0").textContent = this.chipVal[3].label;
          document.getElementById("val0").classList.add("valtextPortrait_crbn");
          betchipsRussianpoker.src = chip_100;

          break;
        case 5:
          // this.setState({ anteBetAmount: Number(this.chipVal[4].val) })
          document.getElementById("val0").textContent = this.chipVal[4].label;
          document.getElementById("val0").classList.add("valtextPortrait_crbn");
          betchipsRussianpoker.src = chip_500;
          break;
        case 6:
          // this.setState({ anteBetAmount: Number(this.chipVal[4].val) })
          // this.setState({ totalbet: 500 })
          document.getElementById("val0").textContent = this.chipVal[5].label;
          document.getElementById("val0").classList.add("incrementval_windowscrbn");
          betchipsRussianpoker.src = chip_1000;
          break;

        default:
          break;
      }

      if (isPortrait) {

        Object.assign(betchipsRussianpoker.style, carebeanjson.betchp);
      } else {
        Object.assign(betchipsRussianpoker.style, carebeanjson.betchiplndspe);
      }



    }

  }

  playPokerBetSound = (chipSound) => {

    const { isPlayingBet, mutesounds } = this.state;
    let currentSound = chipSound;

    if (!mutesounds) {

      if (isPlayingBet && chipSound && typeof chipSound.stop === "function") {
        chipSound.stop();
      }


      if (!chipSound || typeof chipSound.play !== "function") {
        currentSound = this.initializeBetSound();
      }


      if (currentSound && typeof currentSound.play === "function") {

        currentSound.play();
        this.setState({
          isPlayingBet: true,
        });
      } else {
        console.error("Invalid sound object. Unable to play sound.");
      }
    }
  };


  IncreamentBetchips = () => {

    if (this.state.count < 6) {
      this.playPokerBetSound(betchipsnd)
      this.setState(
        (prevState) => ({
          count: prevState.count + 1,
        }),

        () => {
          console.log(this.state.count)

          if (this.state.count > 6) {
            this.setState({ betchipincrementbtn: true, betdecreamentbtn: false });
          }
          else {
            this.playPokerBetSound(betchipsnd)
            this.setState({ betchipincrementbtn: false });

          }
          // let balance=100
          // // if(this.balnce<=this.props.minBetAmt){
          //   if(balance<=this.props.minBetAmt){
          //     // if(balance>this.props.maxBetAmt){
          //       alert("..............")

          // document.getElementById("betincrenetcrbn").classList.add("drwnodrop")
          // document.getElementById("betdecrement").classList.add("drwnodrop")

          //   this.setState({infoText: this.props.language.MinBetAmt+" "+this.props.minBetLbl+" "+this.props.language.MaxBetAmt+" "+this.props.maxBetLbl,
          //     betchipincrementbtn:true

          //   })
          //     }
          // // }
          // else{
          //   this.setState({betchipincrementbtn:false})
          //   document.getElementById("betincrenetcrbn").classList.remove("drwnodrop")
          //   document.getElementById("betdecrement").classList.remove("drwnodrop")

          // }
          this.betChipsIncrementDecremenAction()
        })


    }

    console.log("this.state.count", this.state.count, "betdecreamentbtn", this.state.betdecreamentbtn, 'betchipincrementbtn', this.state.betchipincrementbtn)
  };



  DecreamentBetchips = () => {
    console.log("this.state.count", this.state.count, "betdecreamentbtn", this.state.betdecreamentbtn, 'betchipincrementbtn', this.state.betchipincrementbtn)
    // if (this.state.count == 1) {
    //   document.getElementById("betdecrement").disabled = true;
    // }

    const betchipsRussianpoker = document.getElementById("betchipscrbn");



    if (this.state.count > 1) {
      this.betchipsnd = new Audio(betchipsnd);
      this.setState(
        (prevState) => ({
          count: prevState.count - 1,
        }),
        () => {

          console.log(this.state.count)
          if (this.state.count < 1) {
            this.setState({ betdecreamentbtn: true, betchipincrementbtn: false });
          } else {
            this.playPokerBetSound(betchipsnd)
            this.setState({ betdecreamentbtn: false });
          }

          this.betChipsIncrementDecremenAction();

          // alert(".........."+this.state.count)
          // switch (this.state.count) {
          //   case 1:
          //     betchipsRussianpoker.src = chip_10;
          //     break;
          //   case 2:
          //     betchipsRussianpoker.src = chip_25;
          //     break;
          //   case 3:
          //     betchipsRussianpoker.src = chip_50;
          //     break;
          //   case 4:
          //     betchipsRussianpoker.src = chip_100;
          //     break;
          //   case 5:
          //     betchipsRussianpoker.src = chip_500;
          //     break;
          //   default:
          //     break;
          // }
        }
      );

    }


  };





  anteBetCaribbean = () => {
this.setState({showpayouts:false,anteglowstatus:1})
    document.getElementById("anteglow").classList.remove("anteglow");
    document.getElementById("anteglow").classList.remove("anteglowportraite");
    if (this.state.isoptionmenu === true) {
      this.Showoptionsmenu();
    }

    const betbutton = document.getElementById("betbtn");
    const foldbutton = document.getElementById("Foldbtncrbn");
    const undobutton = document.getElementById("undobutton");
    const undotexttext = document.getElementById("undotext");

    if (this.state.mutesounds) {
      this.betrussiansnd.pause();
    }
    else {
      this.betrussiansnd.play();

    }

    document.getElementById("betincrenetcrbn").classList.add("drwnodrop")
    document.getElementById("betdecrementcrbn").classList.add("drwnodrop")


    // console.log("this.props.minBetAmt", this.props.minBetAmt, "this.props.maxBetAmt", this.props.maxBetAmt)

    const popupbetGreaterthanBalnceElement = document.getElementById("popupbet_greaterthanbalncecrbn");
    if (popupbetGreaterthanBalnceElement) {
      popupbetGreaterthanBalnceElement.className = "";
      popupbetGreaterthanBalnceElement.classList.add("popupbetshighMobilecrbn");
    }
  
    if (
      navigator.userAgentData &&
      navigator.userAgentData.platform &&
      navigator.userAgentData.platform == "Windows"
    ) {
      document.getElementById("mobileAntiMaskCover_crbn").style.display = "none";
      document.getElementById("mobileAntiMaskCover_crbn").classList.remove("mobileAntiMaskCover_crbn")
      const betbutton = document.getElementById("betbtn");
      const foldbutton = document.getElementById("Foldbtncrbn");

      betbutton.style.display = "none"
      foldbutton.style.display = "none"
      undobutton.style.display = "none"

      let betbtntextElement = document.getElementById("betbtntext")
      let foldtextElement = document.getElementById("foldtextcrbn")
      const undotexttext = document.getElementById("undotext");
      betbtntextElement.textContent = ""
      foldtextElement.textContent = ""
      undotexttext.textContent = ""

      // if (this.state.anteBetAmount <= this.props.maxBetAmt) {

      //   document.getElementById("buttons_cover").classList.remove("buttons_cover")
      // } else {
      //   document.getElementById("buttons_cover").classList.add("buttons_cover")

      // }

      document.getElementById("foldtextcrbn").innerHTML = "";




      document.getElementById("betpannelcarebbeancover").style.display = "block";
      document.getElementById("betpannelcarebbeancover").classList.add("bet_pannelcaribbean_CRBN");
      // console.log(this.state.anteBetAmount)
      if (this.state.anteBetAmount >= this.props.minBetAmt) {
        this.setState({
          infoText: "", betchipincrementbtn: true, betdecreamentbtn: true,
          disBetBtn: 1, keyboardhandle: false, multibuttonsebledsble: true, disablemultibutton: true

        })


        // console.log(this.state.anteBetAmount)
        if (this.state.anteBetAmount <= this.props.maxBetAmt) {
          document.getElementById("betpannelcarebbeancover").style.display = "block";
          document.getElementById("betpannelcarebbeancover").classList.add("bet_pannelcaribbean_CRBN");

          const betbutton = document.getElementById("betbtn");
          const foldbutton = document.getElementById("Foldbtncrbn");
          betbutton.src = "";
          betbutton.style.display = "none"
          betbutton.style.cssText = ""

          foldbutton.src = "";
          foldbutton.style.display = "none"
          foldbutton.style.cssText = ""
          let betbtntextElement = document.getElementById("betbtntext")
          let foldtextElement = document.getElementById("foldtextcrbn")
          betbtntextElement.textContent = ""
          foldtextElement.textContent = ""
          const body = {
            sessionId: sessionStorage.getItem("sessionId"),
            CN: "CARIBBEAN_POKER_BET",
            object: {
              gameId: this.props.gameId,
              action: "PARAM_BET",
              playerId: Number(this.props.playerId),
              anteBet: Number(this.state.anteBetAmount.toFixed(2)),
              handId: Number(this.props.gameState.handId)
            },
          };
          // console.log(body);
          this.props.network.sendof(body);
          this.setState({ multibuttonsebledsble: false })

        }
        else {

          this.setState({ multibuttonsebledsble: true, disablemultibutton: false })
          let betbtntextElement = document.getElementById("betbtntext")
          let foldtextElement = document.getElementById("foldtextcrbn")
          betbutton.src = Betbtn;
          Object.assign(betbutton.style, carebeanjson.Betbtnwindows);
          betbtntextElement.classList.add("betbtntext");
          betbtntextElement.textContent = this.props.language.Deal;

          foldbutton.src = Foldbn;
          Object.assign(foldbutton.style, carebeanjson.foldbtnwindows);
          foldtextElement.classList.add("foldtextcrbn");
          foldtextElement.textContent = this.props.language.RP_Clear;
          this.setState({
            infoText: this.props.language.MinBetAmt + " " + this.props.minBetLbl + " " + this.props.language.MaxBetAmt + " " + this.props.maxBetLbl,
            multibuttonsebledsble: true
          })
        }
      }
      else {
        this.setState({ betdecreamentbtn: false, betchipincrementbtn: false });
        document.getElementById("foldtextcrbn").innerHTML = this.props.language.RP_Clear
        document.getElementById("betpannelcarebbeancover").style.display = "none";
        document.getElementById("betpannelcarebbeancover").classList.remove("bet_pannelcaribbean_CRBN");
        this.setState({
          infoText: this.props.language.MinBetAmt + " " + this.props.minBetLbl + " " + this.props.language.MaxBetAmt + " " + this.props.maxBetLbl,
          multibuttonsebledsble: true


        })
      }

    } else {
     
      document.getElementById("anteglow").classList.remove("anteglowportraite");
      document.getElementById("anteglow").classList.remove("anteglowlndspe");

      let betbtntextElement = document.getElementById("betbtntext")
      let foldtextElement = document.getElementById("foldtextcrbn")


      if (this.state.anteBetAmount >= this.props.minBetAmt) {
        if (this.state.anteBetAmount <= this.props.maxBetAmt) {
          this.setState({
            infoText: "", betchipincrementbtn: true, betdecreamentbtn: true,
            disBetBtn: 1, keyboardhandle: false, multibuttonsebledsble: true, disablemultibutton: true

          })



          document.getElementById("betpannelcarebbeancover").style.display = "block";
          document.getElementById("playerDealerCardContainercrbn").style.zIndex = 20;

          betbtntextElement.textContent = "";
          foldtextElement.textContent = "";

          betbutton.src = "";
          foldbutton.src = "";
          betbutton.style.cssText = "";
          foldbutton.style.cssText = "";
          undobutton.src = "";
          undotexttext.textContent = ""

          const body = {
            sessionId: sessionStorage.getItem("sessionId"),
            CN: "CARIBBEAN_POKER_BET",
            object: {
              gameId: this.props.gameId,
              action: "PARAM_BET",
              playerId: Number(this.props.playerId),
              anteBet: Number(this.state.anteBetAmount.toFixed(2)),

              handId: Number(this.props.gameState.handId)
            },
          };



          // console.log(body);
          this.props.network.sendof(body);
          this.setState({ multibuttonsebledsble: false })
          if (window.innerHeight > window.innerWidth) {
            document.getElementById("mobileAntiMaskCover_crbn").classList.remove("maskcoverlndspe_crbn")
            document.getElementById("mobileAntiMaskCover_crbn").style.display = "block";
            
            document.getElementById("mobileAntiMaskCover_crbn").classList.add("mobileAntiMaskCover_crbn")
     
          }
          else{
            document.getElementById("mobileAntiMaskCover_crbn").classList.remove("mobileAntiMaskCover_crbn")
            document.getElementById("mobileAntiMaskCover_crbn").classList.add("maskcoverlndspe_crbn")
            document.getElementById("mobileAntiMaskCover_crbn").style.display = "block";
            
        
     

          }

 
        
        }

        else {
          betbutton.src = Betbtn;
          foldbutton.src = Foldbn;
          betbutton.style.cssText = Betbtn;
          foldbutton.style.cssText = Foldbn;

          if (window.innerHeight > window.innerWidth) {
            document.getElementById("mobileAntiMaskCover_crbn").classList.remove("maskcoverlndspe_crbn")
            document.getElementById("mobileAntiMaskCover_crbn").style.display = "block";
            
            document.getElementById("mobileAntiMaskCover_crbn").classList.add("mobileAntiMaskCover_crbn")
     
            Object.assign(betbutton.style, carebeanjson.Betbtnportraite);
            betbtntextElement.classList.add("betportraitcrbn");
            betbtntextElement.textContent = this.props.language.Deal;


            Object.assign(foldbutton.style, carebeanjson.foldbtnportraite);
            foldtextElement.classList.add("foldtextportraitcaribben");
            foldtextElement.textContent = this.props.language.RP_Clear;

          } else {
          alert(">.........1")
            document.getElementById("mobileAntiMaskCover_crbn").classList.remove("mobileAntiMaskCover_crbn")
            document.getElementById("mobileAntiMaskCover_crbn").style.display = "block";
            document.getElementById("mobileAntiMaskCover_crbn").classList.add("maskcoverlndspe_crbn")
            Object.assign(betbutton.style, carebeanjson.Betbtnlndspe);
            betbtntextElement.classList.add("betLandscapecrbn");
            betbtntextElement.textContent = this.props.language.Deal;

            Object.assign(foldbutton.style, carebeanjson.foldbtnlndspe);
            foldtextElement.classList.add("foldtextLandscapecrbn");
            foldtextElement.textContent = this.props.language.RP_Clear;

          }


          this.setState({
            infoText: this.props.language.MinBetAmt + " " + this.props.minBetLbl + " " + this.props.language.MaxBetAmt + " " + this.props.maxBetLbl,
            // multibuttonsebledsble: true
          })
        }
      }
      else {
      
        betbutton.src = Betbtn;
        foldbutton.src = Foldbn;
        if (window.innerHeight > window.innerWidth) {
          document.getElementById("mobileAntiMaskCover_crbn").classList.remove("maskcoverlndspe_crbn")
          document.getElementById("mobileAntiMaskCover_crbn").style.display = "block";
          document.getElementById("mobileAntiMaskCover_crbn").classList.add("mobileAntiMaskCover_crbn")


          Object.assign(betbutton.style, carebeanjson.Betbtnportraite);
          betbtntextElement.classList.add("betportraitcrbn");
          betbtntextElement.textContent = this.props.language.Deal;


          Object.assign(foldbutton.style, carebeanjson.foldbtnportraite);
          foldtextElement.classList.add("foldtextportraitcaribben");
          foldtextElement.textContent = this.props.language.RP_Clear;

        } else {
          alert(">...........2")
          document.getElementById("mobileAntiMaskCover_crbn").classList.remove("mobileAntiMaskCover_crbn")
          document.getElementById("mobileAntiMaskCover_crbn").style.display = "block";
          document.getElementById("mobileAntiMaskCover_crbn").classList.add("maskcoverlndspe_crbn")

          Object.assign(betbutton.style, carebeanjson.Betbtnlndspe);
          betbtntextElement.classList.add("betLandscapecrbn");
          betbtntextElement.textContent = this.props.language.Deal;

          Object.assign(foldbutton.style, carebeanjson.foldbtnlndspe);
          foldtextElement.classList.add("foldtextLandscapecrbn");
          foldtextElement.textContent = this.props.language.RP_Clear;

        }

        // document.getElementById("betpannelcarebbeancover").style.display = "none";
        // document.getElementById("betpannelcarebbeancover").classList.add("bet_pannelcaribbean_CRBN");
        this.setState({
          infoText: this.props.language.MinBetAmt + " " + this.props.minBetLbl + " " + this.props.language.MaxBetAmt + " " + this.props.maxBetLbl,
          // multibuttonsebledsble: true


        })
      }
    }
  };

  playRemainingSounds = (chipSound) => {
    const { isPlayingBet, mutesounds } = this.state;
    let currentSound = chipSound;
    if (!mutesounds) {

      // if (currentSound) {
      //   chipSound.stop();
      // }
      // if (!chipSound) {
      //   currentSound = this.initializeBetSound();
      // }

      currentSound.play();
      // this.setState({
      //   isPlayingBet: true
      // })
    }

  };

  clearbets() {

    if (this.state.isoptionmenu === true) {
      this.Showoptionsmenu();
    }
    this.setState({showpayouts:false})

    this.clearallsettimeouts()
    this.updateplayerbalnace();
    document.getElementById("mobileAntiMaskCover_crbn").style.display = "none";
    document.getElementById("mobileAntiMaskCover_crbn").classList.remove("mobileAntiMaskCover_crbn");

    if (this.state.mutesounds) {
      this.clear_snd = new Audio(clear_snd)
      this.clear_snd.pause()

    }
    else {
      this.clear_snd = new Audio(clear_snd)
      this.clear_snd.play()

    }

    // document.getElementById("bonusRussian").classList.add("addpointercrbn");



    this.setState({
      totalbet: 0, disClearBtn: 1,
      isbonuspattable: false, anteBetAmount: 0,
      bsbtn: true,
      infoText: this.props.language.Place_antebet
    })
    // document.getElementById("betcircle").classList.add("addpointercrbn")
    // document.getElementById("betcircle").classList.remove("insurencenodrop")
    // document.getElementById("bonusRussian").classList.remove("addpointercrbn")
    // document.getElementById("bonusRussian").classList.add("drwnodrop")
    // document.getElementById("bonustext").style.color = ""
    // document.getElementById("bnsactive").src = "";
    // document.getElementById("bnsactive").classList.remove("betglow");
    this.setState({ multibuttonsebledsble: false })
    // document.getElementById("betbtn").classList.add("addpointercrbn")
    // document.getElementById("betbtn").classList.remove("drwnodrop")
    this.clertime1 = setTimeout(() => {
      gsap.to(`#careabeanantebets${this.state.carebbeanidincrement}`, { zIndex: -2 });
      gsap.to(this.chipbnsref.current, { zIndex: -2 });
      document.getElementById("betpannelcarebbeancover").style.display = "none";
      document.getElementById("betpannelcarebbeancover").classList.add("bet_pannelcaribbean_CRBN");
    }, 1000);



    const betbutton = document.getElementById("betbtn");
    const foldbutton = document.getElementById("Foldbtncrbn");
    const buybutton = document.getElementById("undobutton");


    betbutton.src = "";
    document.getElementById("betbtntext").classList.remove("betwindowscrbn");
    document.getElementById("betbtntext").textContent = "";
    betbutton.style.cssText = ""

    foldbutton.src = "";
    document.getElementById("foldtextcrbn").classList.remove("foldtextwindowscrbn");
    document.getElementById("foldtextcrbn").textContent = "";
    foldbutton.style.cssText = ""


    document.getElementById("undotext").textContent = "";
    buybutton.src = "";
    buybutton.style.position = "";
    buybutton.style.width = "";
    buybutton.style.transform = "";
    // const hands = [
    //   "onepair", "Threekind", "aceking", "fullhse",
    //   "Straightflush", "frofkind", "twopair",
    //   "flush", "stright", "royalflush"
    // ];
    // hands.forEach(hand => {
    //   document.getElementById(hand).style.color = "";
    // });

    //  document.getElementById("onepair").style.color = "";
    //   document.getElementById("Threekind").style.color = "";
    //   document.getElementById("aceking").style.color = "";
    //   document.getElementById("fullhse").style.color = "";
    //   document.getElementById("Straightflush").style.color = "";
    //   document.getElementById("frofkind").style.color = "";
    //   document.getElementById("twopair").style.color = "";
    //   document.getElementById("flush").style.color = "";
    //   document.getElementById("stright").style.color = "";
    //   document.getElementById("royalflush").style.color = "";




    if (
      navigator.userAgentData &&
      navigator.userAgentData.platform &&
      navigator.userAgentData.platform == "Windows"
    ) {
      if (document.getElementById("popupbet_greaterthanbalncecrbn")) {
        this.hidepopup_betshigh();
      }


      this.hidepopup_betshigh();

      document.getElementById("betpannelcarebbeancover").style.display = "block";
      document.getElementById("betpannelcarebbeancover").classList.add("pannelcoverrussian");

      if (document.getElementById("chipcentercarebean")) {
        gsap.to("#chipcentercarebean", {
          x: 741,
          y: 91,
          duration: .1,
          ease: "power2.inOut",
          zIndex: -2,
          opacity: 0
        });

      }



    } else {


      gsap.to(`#careabeanantebets${this.state.carebbeanidincrement}`, {
        x: 1,
        y: 0,
        duration: 0.3,
        ease: "power2.inOut",
        zIndex: 2,
      });

      setTimeout(() => {
        this.setState({
          // storebetchips:[]
        });
      }, 1000);


      const isPortrait = window.innerHeight > window.innerWidth;
      // if(this.state.betshigh_balnce){
      if (document.getElementById("popupbet_greaterthanbalncecrbn")) {
        this.hidepopup_betshigh();
      }
      // }

      if (isPortrait) {
        document.getElementById("betpannelcarebbeancover").style.display = "block";
        document.getElementById("mobileAntiMaskCover_crbn").style.display = "none";
        if (document.getElementById("chipcentercarebean")) {
          gsap.to("#chipcentercarebean", {
            x: 153,
            y: 666,
            duration: 0.5,
            ease: "power2.inOut",
            zIndex: 2,
            opacity: 0
          });
        }

        // let t1 = gsap.timeline();
        // t1.to("#chipcentercarebean", {
        //   y: -966, duration: 0.2, opacity: 1,
        //   onComplete: function () {
        //     gsap.set("#chipcentercarebean", { opacity: 0 });
        //   }
        // });
        // t1.to("#chipcentercarebean", {
        //   x: 13, y:-2170, duration: 0.0001, zIndex: -12, opacity: 0,
        //   onComplete: function () {
        //     gsap.set("#chipcentercarebean", { opacity: 0, });
        //   }

        // });
        // t1.play();


        if (document.getElementById('chip10centerbns')) {
          gsap.to(`#chip10centerbns`, {
            x: -160,
            y: 1200,
            duration: 0.3,
            ease: "power2.inOut",
            zIndex: -2,
            opacity: 0
          });

        }




      } else {

        if (document.getElementById("chipcentercarebean")) {
          gsap.to("#chipcentercarebean", {
            x: 571,
            y: -106,
            duration: 0.5,
            ease: "power2.inOut",
            zIndex: 2,
            opacity: 0
          });
        }




      }
    }
  }







  Carebbeananterespnce = (res) => {
    // alert("..123")
    // this.setState({betdeductions:0,noupdateblnce:false})
    // this.state.betdeductions=0;
    // this.updateplayerbalnace();
    // alert(".....resultante")

    //  this.Showbonustouser(res);

    console.log("RP Ante Result: ");
    console.log(res);
    console.log(res.dealerCards);
    console.log(res.playerCards);
    console.log(res.playerFinalCards);

    this.randomPlayerCards = [...res.playerCards];
    this.shuffledPlayerCards = [...res.playerCardsSorted];
    console.log(this.randomPlayerCards)
    console.log(this.shuffledPlayerCards)

    // this.randomDealerCards = [...res.dealerCards];


    for (let m = 0; m < res.dealerCards.length; m++) {
      if (res.dealerCards[m] === '-1') {
        this.randomDealerCards.push('xx')

      } else {
        this.randomDealerCards.push(res.dealerCards[m]);
      }
    }

    console.log("this.randomDealerCards", this.randomDealerCards);
    console.log("this.randomPlayerCards", this.randomPlayerCards);

    let cnt = this.randomPlayerCards.length;

 
    

    for (let i = 0; i < cnt; i++) {
      var b1 = this.randomPlayerCards[i];
    

      switch (i) {
        case 0:
          this.cardsRef.current.addCards("cardZero", b1, cnt);
          break;
        case 1:
          this.cardsRef.current.addCards("cardOne", b1, cnt);
          break;
        case 2:
          this.cardsRef.current.addCards("cardTwo", b1, cnt);
          break;
        case 3:
          this.cardsRef.current.addCards("cardThree", b1, cnt);
          break;
        case 4:
          this.cardsRef.current.addCards("cardFour", b1, cnt);
          break;
        case 5:
          this.cardsRef.current.addCards("cardFive", b1, cnt);
          break;
        default:
          break;
      }
    }


    this.clertime4 = setTimeout(() => {
      this.onDrawingCards(this.shuffledPlayerCards, false);
      if (this.state.isMobilePortraitLndsp === true) {
        document.getElementById("playerDealerCardContainercrbn").style.zIndex = 1;
      }
      // }, 1300)
      this.setState({
        pokerranking: res.playerHand,
        infoText: res.message,
        multibuttonsebledsble: false,
        disablemultibutton: false,

        // betBtnValue:1,

      });
      document.getElementById("playerDealerCardContainercrbn").classList.add("addpointercrbn")
      document.getElementById("rankinginfo_crbn").classList = "";
      document.getElementById("rankinginfo_crbn").classList.add(spokerranking);



    }, cnt * 2 * 500);


    let dealerCardsLength = this.randomDealerCards.length;

    this.clertime5 = setTimeout(() => {
      for (let i = 0; i < dealerCardsLength; i++) {
        var a1 = this.randomDealerCards[i];
        // playSound(i);
        switch (i) {
          case 0:
            this.dealerCardsContainer.current.addCards(
              "cardZero",
              a1,
              dealerCardsLength
            );
            break;
          case 1:
            this.dealerCardsContainer.current.addCards(
              "cardOne",
              a1,
              dealerCardsLength
            );
            break;
          case 2:
            this.dealerCardsContainer.current.addCards(
              "cardTwo",
              a1,
              dealerCardsLength
            );
            break;
          case 3:
            this.dealerCardsContainer.current.addCards(
              "cardThree",
              a1,
              dealerCardsLength
            );
            break;
          case 4:
            this.dealerCardsContainer.current.addCards(
              "cardFour",
              a1,
              dealerCardsLength
            );
            break;
          default:
            break;
        }
      }

    }, dealerCardsLength * 400);

    this.randomPlayerCards = [];
    // this.shuffledPlayerCards = [];

    this.setState({
      revealCard: true,
      buttontext: "fold",
      betbutton: "Bet",
    });





    let info = "";
    let spokerranking = "";
    let betglow = ""

    let mode = ""
    if (
      navigator.userAgentData &&
      navigator.userAgentData.platform &&
      navigator.userAgentData.platform == "Windows"

    ) {

      // alert("...."+res.message)

      info = "windowsinfocrbn";
      spokerranking = "crbn_playerrank"
      mode = "Windows"
      // betglow = "betglow"
      const betbutton = document.getElementById("betbtn");
      const foldbutton = document.getElementById("Foldbtncrbn");
      const undobutton = document.getElementById("undobutton");

      betbutton.style.display = "none"
      foldbutton.style.display = "none"
      undobutton.style.display = "none"

      let betbtntextElement = document.getElementById("betbtntext")
      let foldtextElement = document.getElementById("foldtextcrbn")
      const undotexttext = document.getElementById("undotext");
      betbtntextElement.textContent = ""
      foldtextElement.textContent = ""
      undotexttext.textContent = ""


      setTimeout(() => {

        // document.getElementById("betglow").classList.add(betglow)
        // document.getElementById("betbtncrbn").style.display = "block"
      }, 7500)


    } else {
      document.getElementById("betpannelcarebbeancover").style.display = "none";
      if (window.innerWidth > window.innerHeight) {
        document.getElementById('rankinginfo_crbn').removeAttribute("style");

        spokerranking = "pokerrankingPlayerLndsp_crbn";
        info = "lndspinfo_crbn";
        // betglow = "betactivelndspe_crbn";
        // betglow = this.state.betactvglwcss;


        mode = "landscape";
      } else {
        info = "portraitinfo_crbn";
        // betglow = "betactivePortrait_crbn";
        // betglow = this.state.betactvglwcss;
        spokerranking = "pokerrankingPortait_crbn";
        mode = "portrait";
        // document.getElementById("rankinginfodeler_crbn").classList.add(betglow)
        document.getElementById('rankinginfo_crbn').removeAttribute("style");







      }
    }









  };



  onDrawingCards(data, drawBuyHideAndShow) {

    console.log(data);
    let cnt = data.length;
    for (let i = 0; i < cnt; i++) {
      var b1 = data[i];
      // if (b1) {
        this.cardsshuffle = new Audio(cardsshuffle);
         this.cardsshuffle.currentTime = 0;

        // if (this.cardsshuffle) {
        //   this.cardsshuffle.currentTime = 0;
        //   if (this.state.mutesounds) {
        //     this.cardsshuffle.pause();
        //   } else {
        //     this.cardsshuffle.play();
        //   }
        // }

// 
      // }
      switch (i) {
        case 0:

          this.cardsRef.current.SuffleCard("cardZero", b1, cnt);
          break;
        case 1:
          this.cardsRef.current.SuffleCard("cardOne", b1, cnt);
          break;
        case 2:
          this.cardsRef.current.SuffleCard("cardTwo", b1, cnt);
          break;
        case 3:
          this.cardsRef.current.SuffleCard("cardThree", b1, cnt);
          break;
        case 4:
          this.cardsRef.current.SuffleCard("cardFour", b1, cnt);
          break;
        case 5:
          this.cardsRef.current.SuffleCard("cardFive", b1, cnt);
          break;
        default:
          break;
      }
    }

    this.clertime6 = setTimeout(() => {

      if (
        navigator.userAgentData &&
        navigator.userAgentData.platform &&
        navigator.userAgentData.platform == "Windows"
      ) {

        this.showParamBetStatus()

      }

      else {
        this.showParamBetStatus()

      }
    }, 2000)
  }


  showParamBetStatus = () => {

    const buybutton = document.getElementById("undobutton");
    const betbutton = document.getElementById("betbtn");
    const foldbutton = document.getElementById("Foldbtncrbn");


    const undotextElement = document.getElementById("undotext");
    const betbtntextElement = document.getElementById("betbtntext");
    const foldtextElement = document.getElementById("foldtextcrbn");
   

    betbtntextElement.textContent = "";
    foldtextElement.textContent = "";
    undotextElement.textContent = "";

    buybutton.src = "";
    buybutton.style.cssText = "";

    betbutton.src = "";
    betbutton.style.cssText = "";


    foldbutton.src = "";
    foldbutton.style.cssText = "";


    if (
      navigator.userAgentData &&
      navigator.userAgentData.platform &&
      navigator.userAgentData.platform == "Windows"
    ) {
      document.getElementById("mobileAntiMaskCover_crbn").style.display = "none";
      document.getElementById("mobileAntiMaskCover_crbn").classList.remove("maskcoverlndspe_crbn")
      document.getElementById('rankinginfo_crbn').classList.add("crbn_playerrank");
      document.getElementById("betpannelcarebbeancover").style.display = "block";
      document.getElementById("betpannelcarebbeancover").classList.add("bet_pannelcaribbean_CRBN");
      document.getElementById("undotext").textContent = ""

      betbutton.src = Betbtn;
      Object.assign(betbutton.style, carebeanjson.Betbtnwindows);
      document.getElementById("betbtntext").textContent = this.props.language.Bet;
      document.getElementById("betbtntext").classList.add("betwindowscrbn");


      foldbutton.src = Foldbnwindows;
      Object.assign(foldbutton.style, carebeanjson.foldbtnwindows);
      document.getElementById("foldtextcrbn").textContent = this.props.language.Fold;
      document.getElementById("foldtextcrbn").classList.add("foldtextwindowscrbn");


      document.getElementById("betbtn_carebean").classList.remove("crbn_nodrop")




    }

    else {
      const isPortrait = window.innerHeight > window.innerWidth;
      document.getElementById("mobileAntiMaskCover_crbn").style.display = "block";
      if (isPortrait) {
        document.getElementById("anteglow").classList.remove("anteglowportraite");
        // document.getElementById("betglow").classList.add("betactivePortrait_crbn");
        document.getElementById("mobileAntiMaskCover_crbn").style.display = "block";
        document.getElementById("mobileAntiMaskCover_crbn").classList.add("mobileAntiMaskCover_crbn")
        document.getElementById("rankinginfo_crbn").classList.add("pokerrankingPortait_crbn");

        // document.getElementById("betglow").classList.add("betactivePortrait_crbn")
        betbtntextElement.textContent = this.props.language.Bet;
        betbtntextElement.classList.add("betportraitcrbn");
        foldtextElement.textContent = this.props.language.Fold;
        foldtextElement.classList.add("foldtextportraitcaribben");
        betbutton.src = Betbtn;
        Object.assign(betbutton.style, carebeanjson.Betbtnportraite);


        foldbutton.src = Foldbn;
        Object.assign(foldbutton.style, carebeanjson.foldbtnportraite);


      } else {
        document.getElementById("rankinginfo_crbn").classList.add("pokerrankingPlayerLndsp_crbn");

        document.getElementById("anteglow").classList.remove("anteglowlndspe");
        document.getElementById("mobileAntiMaskCover_crbn").style.display = "block";
        document.getElementById("mobileAntiMaskCover_crbn").classList.add("maskcoverlndspe_crbn")
        // document.getElementById("betglow").classList.add("betactivelndspe_crbn");

        betbtntextElement.textContent = this.props.language.Bet;
        betbtntextElement.classList.add("betLandscapecrbn");
        foldtextElement.textContent = this.props.language.Fold;
        foldtextElement.classList.add("foldtextLandscapecrbn");
        betbutton.src = Betbtn;
        Object.assign(betbutton.style, carebeanjson.Betbtnlndspe);

        foldbutton.src = Foldbn;
        Object.assign(foldbutton.style, carebeanjson.foldbtnlndspe);


      }
    }



  }



  revealDealercards(res) {


    this.dealerCardsRandom = [...res.dealerCards];
    this.dealerShuffledCards = [...res.dealerCardsSorted];
    // this.setState({ totalwinRussian: res.totalWinAmount });
    console.log(this.state.totalwinRussian);
    console.log(this.dealerShuffledCards)
    let cnt = this.dealerCardsRandom.length;


    for (let i = 0; i < cnt; i++) {
      let b1 = this.dealerCardsRandom[i];
      // if (b1) {
      //   this.reaveldealer_cards = new Audio(reaveldealer_cards);
      //   if (this.state.mutesounds) {

      //     this.reaveldealer_cards.pause();
      //   } else {
      //     this.reaveldealer_cards.play();
      //   }
      // }
      switch (i) {
        case 0:
          if (this.randomDealerCards[0] != b1) {
            this.dealerCardsContainer.current.revealDealerCards("cardZero", b1, cnt);
          }
          break;
        case 1:
          if (this.randomDealerCards[1] != b1) {
            this.dealerCardsContainer.current.revealDealerCards("cardOne", b1, cnt);
          }
          break;
        case 2:
          if (this.randomDealerCards[2] != b1) {
            this.dealerCardsContainer.current.revealDealerCards("cardTwo", b1, cnt);
          }
          break;
        case 3:
          if (this.randomDealerCards[3] != b1) {
            this.dealerCardsContainer.current.revealDealerCards("cardThree", b1, cnt);
          }
          break;
        case 4:
          if (this.randomDealerCards[4] != b1) {
            this.dealerCardsContainer.current.revealDealerCards("cardFour", b1, cnt);
          }
          break;
        case 5:
          if (this.randomDealerCards[5] != b1) {
            this.dealerCardsContainer.current.revealDealerCards("cardFive", b1, cnt);
          }
          break;
        default:
          break;
      }

    }
    this.playRemainingSounds(this.reaveldealer_cards)
    this.clertime6 = setTimeout(() => {
      console.log("before", this.randomDealerCards);
      // this.suffleDealerCards(this.randomDealerCards.sort(() => Math.random()-0.5));
      this.suffleDealerCards(this.dealerShuffledCards);
      console.log("after", this.randomDealerCards);
    }, this.dealerShuffledCards.length * 500);

  }
  updateplayerbalnace = () => {
    this.props.gamstateHandle();
    // this.setState({betdeductions:0,noupdateblnce:true})
    const body2 = {
      CN: "GET_PLAYER_INFO",
      object: {
        gameId: Number(this.props.gameId,),
        playerId: Number(this.props.playerId),

      },
      sessionId: sessionStorage.getItem('sessionId'),
    }
    this.props.network.sendof(body2);
    this.body2 = {};

  }




  showCarebbeanBetreapnce = (res) => {
    console.log(res)
    //// }, dealerCardsLength*300+3000)
    // this.setState({betdeductions:0,noupdateblnce:false})
    // this.state.betdeductions=0;
    //  this.updateplayerbalnace();

    // document.getElementById("rankinginfodeler_crbn").style.textContent= res.message
    const betbutton = document.getElementById("betbtn");
    const foldbutton = document.getElementById("Foldbtncrbn");
    const betTextaddtoBetElement = document.getElementById("betTextaddtoBetcrbn");
    this.setState({ playerhand_status: res.playerHand })


    const betbtntextElement = document.getElementById("betbtntext");
    const foldtextElement = document.getElementById("foldtextcrbn");
    document.getElementById("cnfrmfoldpop").style.visibility = "hidden"

    if (
      navigator.userAgentData &&
      navigator.userAgentData.platform &&
      navigator.userAgentData.platform == "Windows"
    ) {
      // document.getElementById("rankinginfodeler_crbn").classList.add("windowscareabean_dealer");
      // document.getElementById("rankinginfo_crbn").classList.add("crbn_playerrank");
      // let t1 = gsap.timeline();
      //     t1.to("#chip10centerbns", {
      //       x:813, y:553, duration: 0.5, opacity: 0,
      //       onComplete: () => {
      //         gsap.set("#chip10centerbns", { opacity:0});
      //       }
      //     });
      //  this.Showinsurenceactiveornot(res)
      // this.revealDealercards(res);

      document.getElementById("betbtntext").textContent = "";
      document.getElementById("foldtextcrbn").textContent = "";

      this.showcheckpayerordealerwins(res);


    }

    else {


      if (window.innerHeight > window.innerWidth) {



        this.showcardsAdBetsParamState(res, res.playerCardsSorted, res.dealerCards);

        betbutton.style.cssText = "";
        foldbutton.style.cssText = "";
        this.setState({

          // disablemultibutton: true,
          betdecreamentbtn: false,
          betchipincrementbtn: false,
          pokerranking: res.playerHand,
          // pokerrankingdeler:res.dealerHand,
          infoText: res.message,
          totalbet: res.totalBetAmount,
        });

        setTimeout(() => { this.setState({ infoText: "" }) }, 2000);

        // document.getElementById("foldtext").style.opacity = "0.5";
        // foldbutton.style.opacity = "0.5"




        // setTimeout(() => {
        //   this.setState({ infoText: res.message, pokerrankingdeler: res.dealerHand, })
        //   document.getElementById("rankinginfodeler").classList.add("pokerrankingdelerPortrait");
        // }, 3000);



        switch (res.winner) {
          case "Dealer":

            this.showPlayerorDealerorFold(res)

            break;
          case "Player":
            this.showPlayerorDealerorFold(res)


            // this.ifDealerWinsMobilePortraitAction();
            // break;
            // this.ifPlayerWinsMobilePortraitAction();

            // if(Number(res.anteBetAmount) >0){
            //   let convrAntebt = this.topLable_rsp(res.anteBetAmount);
            //   this.setState((prevState) => ({
            //     storeantebetchips: [
            //       ...prevState.storeantebetchips,
            //       { chip: chip_10, chipvalue: convrAntebt, chipcolor1: "valtextPortrait1" },

            //     ],
            //   }));

            // }


            break;


          default:
            break;
        }




      } else {

        this.prevStatecheckPlayerOrDealerWinMblLndspe(res);



      }

    }
  }

  prevStatecheckPlayerOrDealerWinMblLndspe = (res) => {

    const betbutton = document.getElementById("betbtn");
    const foldbutton = document.getElementById("Foldbtncrbn");


    const buytextElement = document.getElementById("undotext");
    const betbtntextElement = document.getElementById("betbtntext");
    const foldtextElement = document.getElementById("foldtextcrbn");

    // const betTextaddtoBetElement = document.getElementById("betTextaddtoBet");
    // this.setState({ infoText: res.message, pokerrankingdeler: res.dealerHand, })
    // document.getElementById("rankinginfodeler").classList.add("pokerrankingdelerLndSpe");
    // const confirmbuydealerPopupElement = document.getElementById("cnfrmfoldpop");

    betbutton.className = "";
    betbutton.style.cssText = "";

    foldbutton.className = "";
    foldbutton.style.cssText = "";


    buytextElement.className = "";
    buytextElement.style.cssText = "";
    buytextElement.textContent = "";

    betbtntextElement.className = "";
    betbtntextElement.style.cssText = "";
    betbtntextElement.textContent = "";

    foldtextElement.className = "";
    foldtextElement.style.cssText = "";
    foldtextElement.textContent = "";


    switch (res.winner) {
      case "Dealer":
      case "Player":
        this.showPlayerorDealerorFold(res)

        break;

      default:
        break;
    }





  }





  CarebbeanBetreapnce = (res) => {

    //// }, dealerCardsLength*300+3000)
    // this.setState({betdeductions:0,noupdateblnce:false})
    // this.state.betdeductions=0;
    //  this.updateplayerbalnace();

    // document.getElementById("rankinginfodeler_crbn").style.textContent= res.message
    const betbutton = document.getElementById("betbtn");
    const foldbutton = document.getElementById("Foldbtncrbn");
    const betTextaddtoBetElement = document.getElementById("betTextaddtoBetcrbn");
    this.setState({ playerhand_status: res.playerHand })
    if (
      navigator.userAgentData &&
      navigator.userAgentData.platform &&
      navigator.userAgentData.platform == "Windows"
    ) {
      document.getElementById("mobileAntiMaskCover_crbn").style.display = "none";
      document.getElementById("mobileAntiMaskCover_crbn").classList.remove("maskcoverlndspe_crbn")
      this.clertime7 = setTimeout(() => {
        document.getElementById("rankinginfodeler_crbn").classList.add("windowscareabean_dealer");
        this.setState({ infoText: res.message, pokerrankingdeler: res.dealerHand })
      }, 1000);

      document.getElementById("betbtntext").textContent = "";
      document.getElementById("foldtextcrbn").textContent = "";
      this.revealDealercards(res)
      this.checkpayerordealerwins(res);
      // this.revealDealercards(res);

    }

    else {
      // document.getElementById("mobileAntiMaskCover_crbn").style.display = "none";

      // document.getElementById("insurenceactive").className = "";
      //  const poker_Rankingsinsurence = [
      //     "Royal Flush","Straight","Straight Flush", "Four of a Kind", 
      //     "Full House", "Flush", "Three of a Kind"
      //   ];
      this.setState({ anteglowstatus: 0 })
      if (window.innerHeight > window.innerWidth) {



        this.revealDealercards(res)
        this.clertime7 = setTimeout(() => {


          this.setState({ pokerrankingdeler: res.dealerHand, })
          document.getElementById("rankinginfodeler_crbn").classList.remove("betactivePortrait_crbn");
          document.getElementById("rankinginfodeler_crbn").classList.add(`${this.state.rankinginfodelercss}`);

          switch (res.winner) {
            case "Dealer":
              setTimeout(() => {
                this.ifDealerWinsMobilePortraitAction(res);
              }, 1000)
              break;

            // case `CONGRATULATIONS! You won FUN ${res.totalWinAmount}`:
            case "Player":
              this.ifPlayerWinsMobilePortraitAction(res);
              break;

            default:
              break;
          }

        }, 3500)



      } else {
        this.setState({ pokerrankingdeler: res.dealerHand, })
        document.getElementById("rankinginfodeler_crbn").classList.add("crbndealer_rank");

        this.revealDealercards(res);
        this.clertime7 = setTimeout(() => {
          this.checkPlayerOrDealerWinMblLndspe(res);
        }, 5000)




      }

    }

  }


  checkPlayerOrDealerWinMblLndspe = (res) => {

    const betbutton = document.getElementById("betbtn");
    const foldbutton = document.getElementById("Foldbtncrbn");
    // const drawtextElement = document.getElementById("drawtext");
    const undotextElement = document.getElementById("undotext");
    const betbtntextElement = document.getElementById("betbtntext");
    const foldtextElement = document.getElementById("foldtextcrbn");
    const betText = document.querySelector("#betTextaddtoBetcrbn");

    const betTextaddtoBetElement = document.getElementById("betTextaddtoBetcrbn");
    this.setState({ infoText: res.message })

    // const confirmbuydealerPopupElement = document.getElementById("cnfrmfoldpop");

    betbutton.className = "";
    betbutton.style.cssText = "";

    foldbutton.className = "";
    foldbutton.style.cssText = "";

    // drawtextElement.className = "";
    // drawtextElement.style.cssText = "";

    undotextElement.className = "";
    undotextElement.style.cssText = "";

    betbtntextElement.className = "";
    betbtntextElement.style.cssText = "";

    foldtextElement.className = "";
    foldtextElement.style.cssText = "";


    switch (res.winner) {
      case "Dealer":
        this.updateplayerbalnace();

        this.setState((prevState) => ({
          previousAnteBetAmount: prevState.anteBetAmount,

          totalbet: 0,
          anteBetAmount: 0,
          infoText:res.message


        }));

        this.clertime48 = setTimeout(() => {
          this.clearActivesWeDid();
        }, 1000)

        document.getElementById("mobileAntiMaskCover_crbn").style.display = "none";



        // document.getElementById("betglow").src = "";
        // document.getElementById("carebean_bet").style.color = "";
        // document.getElementById("betglow").classList.remove("betglow");
        document.getElementById("anteglow").classList.add("anteglowlndspe");
        this.setState({ betbutton: "REBET", bsbtn: true, buttontext: "REBETX2", betchipincrementbtn: false, betdecreamentbtn: false });
        let t1 = gsap.timeline();

        t1.to("#chipcentercarebean", {
          x: -390,
          y: -799, duration: 0.2, opacity: 1,
          onComplete: () => {
            gsap.set("#chipcentercarebean", { opacity: 0 });
          }
        });
        t1.to("#chipcentercarebean", {
          x: 13, y: 1200, duration: 0.0001, zIndex: -2, opacity: 0,
          onComplete: () => {
            gsap.set("#chipcentercarebean", { opacity: 0, });
          }
        });
        t1.play();


        let t2 = gsap.timeline();
        t2.to("#betchipsaddtobetcaribn", {
          x: -300, y: -2060, duration: 0.5, zIndex: 12, ease: "power2.inOut", opacity: 1,
          onComplete: () => {
            gsap.set("#betchipsaddtobetcaribn", { opacity: 0 });
          }
        });
        t2.to("#betchipsaddtobetcaribn", {
          x: -2, y: 72, duration: 0.5, zIndex: -2, opacity: 0,

        });
        t2.play();

        let t3 = gsap.timeline();
        t3.to("#betTextaddtoBetcrbn", {
          x: 110, y: -2000, duration: 0.5, ease: "power2.inOut", opacity: 0,
          onComplete: () => {
            gsap.set("#betTextaddtoBetcrbn", { opacity: 0 });
          }
        });
        t3.to("#betTextaddtoBetcrbn", {
          x: 153, y: 185, duration: 0.5, zIndex: -2, opacity: 0,

        });

        t3.play();
        this.playRemainingSounds(this.playerLose)


        document.getElementById("betbtncrbn").style.display = "block"
        betbtntextElement.className = "";
        foldtextElement.className = "";


        betbutton.src = Betbtn;
        Object.assign(betbutton.style, carebeanjson.Betbtnlndspe);
        betbtntextElement.textContent = this.props.language.Rebet;
        betbtntextElement.classList.add('betLandscapecrbn');

        foldbutton.src = Foldbn;
        Object.assign(foldbutton.style, carebeanjson.foldbtnlndspe);
        foldtextElement.textContent = this.props.language.RebetX2;
        foldtextElement.classList.add("foldtextLandscapecrbn");

        // document.getElementById("betglow").classList.remove("betactivelndspe_crbn");
        this.clertime49 = setTimeout(() => {
          this.setState({ infoText: this.props.language.Place_antebet });

        }, 1000);

        // delaerChipAnimDiv.className ="";
        // dealerchipElement.src = "";
        if (this.state.mutesounds) {
          this.playerLose.pause();
        } else {
          this.playerLose.play();
        }

        // document.getElementById("onepair").style.color = "";
        // document.getElementById("Threekind").style.color = "";
        // document.getElementById("aceking").style.color = "";
        // document.getElementById("fullhse").style.color = "";
        // document.getElementById("Straightflush").style.color = "";
        // document.getElementById("frofkind").style.color = "";
        // document.getElementById("twopair").style.color = "";
        // document.getElementById("flush").style.color = "";
        // document.getElementById("stright").style.color = "";
        // document.getElementById("royalflush").style.color = "";
        break;

      // case `CONGRATULATIONS! You won FUN ${res.totalWinAmount}`:
      case "Player":
        const winpopuprspelement = document.getElementById("winpopup_crbn");
        const winamtElement = document.getElementById("winamt_crbn");
        const delaerChipAnimDiv = document.getElementById('delaerchipanim_crbn')
        const dealerchipElement = document.getElementById('dealerchip_crbn');
        setTimeout(() => {
          this.updateplayerbalnaceIfplayerWins()
          this.updateplayerbalnace();
        }, 5000);

        this.clertime53 = setTimeout(() => {
          this.clearActivesWeDid();
        }, 1000);

        console.log(this.state.anteBetAmount, this.state.bonusbetamount);

       


        delaerChipAnimDiv.className = "";
        dealerchipElement.src = "";
        dealerchipElement.className = "";

        this.resetAnimationChips = true;








        // document.getElementById("betglow").src = "";
        document.getElementById("carebean_bet").style.color = "";
        // document.getElementById("betglow").classList.remove("betglow");

        // let t1 = gsap.timeline()
        gsap.to("#chipcentercarebean", { x: -1537, y: 128, duration: 0.5 });
        gsap.to("#betchipsaddtobetcaribn", { x: -2061, y: 221, duration: 0.5, delay: 1 });

        if (document.getElementById("betTextaddtoBetcrbn")) {
          gsap.to("#betTextaddtoBetcrbn", {
            x: -2117, y: 244, duration: 0.5, delay: 1, onComplete: () => {
              betText.innerHTML = `<span>${Number((res.anteWinAmount + res.winAmount).toFixed(2))}</span>`
            }
          });
        }

        setTimeout(() => {

          winpopuprspelement.classList.add("winPopupLand_crbn");
          winamtElement.classList.add("winamtlnd_crbn");
          this.setState({ totalwinRussian:res.totalWinAmount})
          this.playRemainingSounds(this.playerWin)
        }, 1500)

        // gsap.to(this.chipdrawref.current, { x: 102, y: -130, duration: 0.5, delay: 1 });

        this.setState({ betbutton: "REBET", buttontext: "REBETX2", disablemultibutton: false, bsbtn: true, betchipincrementbtn: false, betdecreamentbtn: false })


        // betbtntextElement.className ="";
        // foldtextElement.className = "";

        // betbutton.src = Betbtn;
        // Object.assign(betbutton.style, carebeanjson.Betbtnlndspe);
        // betbtntextElement.textContent = this.props.language.Rebet;
        // betbtntextElement.classList.add('betLandscapecrbn');

        // foldbutton.src = Foldbnwindows;
        // Object.assign(foldbutton.style, carebeanjson.foldbtnlndspe);
        // foldtextElement.textContent = this.props.language.RebetX2;
        // foldtextElement.classList.add("foldtextLandscapecrbn");

        switch (this.state.count) {
          case 1:
            dealerchipElement.src = chip_10;
            break;
          case 2:
            dealerchipElement.src = chip_25;
            break;
          case 3:
            dealerchipElement.src = chip_50;
            break;
          case 4:
            dealerchipElement.src = chip_100;
            break;
          case 5:
            dealerchipElement.src = chip_500;
            break;
          default:
            break;
        }

        Object.assign(dealerchipElement.style, carebeanjson.dealerchipstoplayerPortrait)

        let t6 = gsap.timeline()
        t6.to("#dealerchip_crbn", {
          x: -866,
          y: 954,
          duration: 0.5,
          ease: "power2.inOut",
          delay: 1,
          onStart: () => {
            gsap.set('#dealerchip_crbn', { opacity: 1, zIndex: 2 })
          },
          onComplete: () => {
            gsap.set('#dealerchip_crbn', { opacity: 0, zIndex: -10 })
          },


        });


        t6.to("#dealerchip_crbn", {
          x: -45,
          y: 20,
          duration: 0.002,
          ease: "power2.inOut",
          onStart: () => {
            gsap.set('#dealerchip_crbn', { opacity: 0, zIndex: -10 })
          },
        })

      




        break;

      default:
        break;
    }




  }

















  ifDealerWinsMobilePortraitAction = (res) => {
    // this.playerLose = new Audio(playerLose);
    // this.playerLose.currentTime = 0;
    // console.log(this.state.storebonuschips)
    const insuranceText = document.querySelectorAll("#chiptoinsurence span");
    const winpopuprspelement = document.getElementById("winpopup_crbn");
    const winamtElement = document.getElementById("winamt_crbn");
    this.clertime47 = setTimeout(() => {
      document.getElementById("betbtncrbn").style.display = "block"
      this.updateplayerbalnace();
      // console.log(this.state.anteBetAmount, this.state.bonusbetamount);
      this.setState((prevState) => ({
        infoText: res.message,
        totalwinRussian: res.totalWinAmount,
        previousAnteBetAmount: res.anteBetAmount,
        totalbet: 0,
        anteBetAmount: 0,

        prevStatestoreantebetchips: [...prevState.storeantebetchips],

      }))

      this.clertime48 = setTimeout(() => {
        this.clearActivesWeDid();
      }, 1000);





      const betbutton = document.getElementById("betbtn");
      const foldbutton = document.getElementById("Foldbtncrbn");
      const betTextaddtoBetElement = document.getElementById("betTextaddtoBetcrbn");


      // document.getElementById("betglow").src = "";
      document.getElementById("carebean_bet").style.color = "";

      this.setState({ betbutton: "REBET", bsbtn: true, buttontext: "REBETX2", betchipincrementbtn: false, betdecreamentbtn: false });
      let t1 = gsap.timeline();

      t1.to("#chipcentercarebean", {
        y: -2060, duration: 0.2, opacity: 1,
        onComplete: () => {
          gsap.set("#chipcentercarebean", { opacity: 0 });
        }
      });
      t1.to("#chipcentercarebean", {
        x: 13, y: 1200, duration: 0.0001, zIndex: -2, opacity: 0,
        onComplete: () => {
          gsap.set("#chipcentercarebean", { opacity: 0, });
        }
      });
      t1.play();


      let t2 = gsap.timeline();
      t2.to("#betchipsaddtobetcaribn", {
        x: -20, y: -2060, duration: 0.5, zIndex: 12, ease: "power2.inOut", opacity: 1,
        onComplete: () => {
          gsap.set("#betchipsaddtobetcaribn", { opacity: 0 });
        }
      });
      t2.to("#betchipsaddtobetcaribn", {
        x: -2, y: 72, duration: 0.5, zIndex: -2, opacity: 0,

      });
      t2.play();

      let t3 = gsap.timeline();
      t3.to("#betTextaddtoBetcrbn", {
        x: -20, y: -2060, duration: 0.5, ease: "power2.inOut", opacity: 0,
        onComplete: () => {
          gsap.set("#betTextaddtoBetcrbn", { opacity: 0 });
        }
      });
      t3.to("#betTextaddtoBetcrbn", {
        x: -2, y: 72, duration: 0.5, zIndex: -2, opacity: 0,

      });

      t3.play();

      this.playRemainingSounds(this.playerLose)


      this.clertime49 = setTimeout(() => {
        // document.getElementById("betglow").classList.remove("betactivePortrait_crbn")
        document.getElementById("mobileAntiMaskCover_crbn").style.display = "none";
        gsap.fromTo(winpopuprspelement, { scale: 1, duration: 0.5, rotate: 360 }, {
          autoAlpha: 0, scale: 0, duration: 0.5, onComplete: () => {
            winpopuprspelement.classList.remove("winPopupForMptSw_crbn");
            winamtElement.classList.remove("winamtMpSw_crbn");
          }
        })
        this.setState({ infoText: this.props.language.Place_antebet, totalwinRussian: 0, storeantebetchips: [], storeinsurancechips: [], });
        betbutton.src = Betbtn;
        // Object.assign(betbutton.style, carebeanjson.Betbtnportraite);
        Object.assign(betbutton.style, this.state.betbuttoncss);

        foldbutton.src = Foldbn;
        // Object.assign(foldbutton.style, carebeanjson.foldbtnportraite);
        Object.assign(foldbutton.style, this.state.foldbuttoncss);
        document.getElementById("betbtntext").textContent = this.props.language.Rebet;

        document.getElementById("betbtntext").classList.add(`${this.state.betbtntextcss}`);
        document.getElementById("foldtextcrbn").classList.add(`${this.state.foldbtntextcss}`);
        // document.getElementById("betbtntext").classList.add("betportraitcrbn");
        document.getElementById("anteglow").classList.add("anteglowportraite");
        // document.getElementById("betglow").classList.remove("betactivePortrait_crbn");

        document.getElementById("foldtextcrbn").textContent = this.props.language.RebetX2;

      }, 3000);

      // delaerChipAnimDiv.className ="";
      // dealerchipElement.src = "";

      // if (this.state.mutesounds) {
      //   this.playerLose.pause();
      // } else {
      //   this.playerLose.play();
      // }
      // this.playRemainingSounds(this.playerLose)




    }, 2000);


  }



  suffleDealerCards = (data) => {
    let cnt = data.length;
    for (let i = 0; i < cnt; i++) {
      let b1 = data[i];
      this.cardsshuffle = new Audio(cardsshuffle);
      // if (this.cardsshuffle) {
      //   this.cardsshuffle.currentTime = 0;
      //   if (this.state.mutesounds) {
      //     this.cardsshuffle.pause()
      //   } else {
      //     this.cardsshuffle.play()
      //   }



      // }
      switch (i) {
        case 0:

          this.dealerCardsContainer.current.SuffleCard("cardZero", b1, cnt);
          break;
        case 1:
          this.dealerCardsContainer.current.SuffleCard("cardOne", b1, cnt);
          break;
        case 2:
          this.dealerCardsContainer.current.SuffleCard("cardTwo", b1, cnt);
          break;
        case 3:
          this.dealerCardsContainer.current.SuffleCard("cardThree", b1, cnt);
          break;
        case 4:
          this.dealerCardsContainer.current.SuffleCard("cardFour", b1, cnt);
          break;
        case 5:
          this.dealerCardsContainer.current.SuffleCard("cardFive", b1, cnt);
          break;
        default:
          break;
      }
    }
  };



  Undobetscaribbean() {
    if (this.state.mutesounds) {
      this.clear_snd = new Audio(clear_snd)
      this.clear_snd.pause()

    }
    else {
      this.clear_snd = new Audio(clear_snd)
      this.clear_snd.play()

    }
    this.setState({showpayouts:false})

    if (this.state.totalbet > 0) {


      let deductprevbets = this.state.previousBets.pop();

      console.log(deductprevbets)
      let totalAnteBetAmount = this.state.anteBetAmount - deductprevbets;
      console.log(totalAnteBetAmount)
      //  this.setState({totalbet:totalAnteBetAmount})



      // this.setState((prevState) => ({
      //   totalbet: this.state.anteBetAmount -Number(deductprevbets),

      // }))
      console.log(totalAnteBetAmount)

      // for (let k = 1; k <= this.chipVal.length; k++) {
      //   if (this.state.count === k) {

      //     totalAnteBetAmount = this.state.anteBetAmount - 10;


      //   }
      // }
      document.getElementById("betpannelcarebbeancover").style.display = "none";
      this.setState({ anteBetAmount: totalAnteBetAmount })
      this.setState({ totalbet: totalAnteBetAmount })

      let convrAntebt = this.topLable_rsp(totalAnteBetAmount);
      this.setState((prevState) => ({
        // totalbet: prevState.totalbet + Number(chipData.val),
        betdeductions: prevState.totalbet,
        // anteBetAmount: prevState.anteBetAmount + Number(chipData.val),
      }));

      if (
        navigator.userAgentData &&
        navigator.userAgentData.platform &&
        navigator.userAgentData.platform == "Windows"
      ) {


        
        const chipDetails = [
          { val: this.chipVal[0].val, src: chip_10, className: "carebeanantechip_10" ,textclass:"antebet_caribean10"},
          { val: this.chipVal[1].val, src: chip_25, className: "carebeanantechip_20",textclass:"antebet_caribean20" },
          { val: this.chipVal[2].val, src: chip_50, className: "carebeanantechip_50" ,textclass:"antebet_caribean50"},
          { val: this.chipVal[3].val, src: chip_100, className: "carebeanantechip_100",textclass:"antebet_caribean100" },
          { val: this.chipVal[4].val, src: chip_500, className: "carebeanantechip_500", textclass:"antebet_caribean500"},
          { val: this.chipVal[5].val, src: chip_1000, className: "carebeanantechip_500",textclass:"antebet_caribean500" },
        ];
        const chipData = chipDetails[this.state.count - 1];

        if (chipData) {
          // betchipstoantebet.src = chipData.src;

        

          this.setState((prevState) => ({
            storebetchips: [
              ...prevState.storebetchips,
              <div id="chipcentercarebean">
                <img
                  id="centerchips"
                  className={chipData.className}
                  src={this.ReturnChipBasedAmountUndo(Number(convrAntebt.toFixed(2)))}
                  alt={`Chip ${chipData.val}`}
                />
                <span id={`antebetval_${chipData.val}`} className={chipData.textclass}>
                  {convrAntebt}
                </span>
              </div>,
            ],
          }));

        }

        // Object.assign(betchipstoantebet.style, carebeanjson.Betchiptoantewindows)



      }
      else {

        if (window.innerHeight > window.innerWidth) {


          switch (this.state.count) {
            case 1:


              this.clertime13 = setTimeout(() => {
                this.setState((prevState) => ({
                  storeantebetchips: [
                    ...prevState.storeantebetchips,
                    { chip: this.ReturnChipBasedAmountUndo(Number(convrAntebt.toFixed(2))), chipvalue: convrAntebt, chipcolor1: "valtextPortrait1" },

                  ],
                }));
              }, 150);
              break;
            case 2:
              

              this.clertime14 = setTimeout(() => {
                this.setState((prevState) => ({
                  storeantebetchips: [
                    ...prevState.storeantebetchips,
                    { chip: this.ReturnChipBasedAmountUndo(Number(convrAntebt.toFixed(2))), chipvalue: convrAntebt, chipcolor1: null },

                  ],
                }));
              }, 150);
              break;
            case 3:
             

              this.clertime15 = setTimeout(() => {
                this.setState((prevState) => ({
                  storeantebetchips: [
                    ...prevState.storeantebetchips,
                    { chip: this.ReturnChipBasedAmountUndo(Number(convrAntebt.toFixed(2))), chipvalue: convrAntebt, chipcolor1: null },

                  ],
                }));
              }, 150);
              break;
            case 4:
              

              this.clertime16 = setTimeout(() => {
                this.setState((prevState) => ({
                  storeantebetchips: [
                    ...prevState.storeantebetchips,
                    { chip: this.ReturnChipBasedAmountUndo(Number(convrAntebt.toFixed(2))), chipvalue: convrAntebt, chipcolor1: null },

                  ],
                }));
              }, 150);
              break;
            case 5:
            

              this.clertime17 = setTimeout(() => {
                this.setState((prevState) => ({
                  storeantebetchips: [
                    ...prevState.storeantebetchips,
                    { chip: this.ReturnChipBasedAmountUndo(Number(convrAntebt.toFixed(2))), chipvalue: convrAntebt, chipcolor1: null },

                  ],
                }));
              }, 150);
              break;
            case 6:
             

              this.clertime17 = setTimeout(() => {
                this.setState((prevState) => ({
                  storeantebetchips: [
                    ...prevState.storeantebetchips,
                    { chip: this.ReturnChipBasedAmountUndo(Number(convrAntebt.toFixed(2))), chipvalue: convrAntebt, chipcolor1: null },

                  ],
                }));
              }, 150);
              break;
            default:
              break;
          }

        }

        else {
          switch (this.state.count) {
            case 1:

              this.setState((prevState) => ({
                // totalbet: prevState.totalbet + Number(this.chipVal[1].val),
                // anteBetAmount: prevState.anteBetAmount + Number(this.chipVal[1].val),
                betdeductions: prevState.totalbet,
              }, () => {
                console.log(this.state.betdeductions)
              }))

              this.clertime13 = setTimeout(() => {
                this.setState((prevState) => ({
                  storeantebetchips: [
                    ...prevState.storeantebetchips,
                    { chip: this.ReturnChipBasedAmountUndo(Number(convrAntebt.toFixed(2))), chipvalue: convrAntebt, chipcolor1: "valtextPortrait1" },

                  ],
                }));
              }, 150);
              break;
            case 2:
             

              this.clertime14 = setTimeout(() => {
                this.setState((prevState) => ({
                  storeantebetchips: [
                    ...prevState.storeantebetchips,
                    { chip: this.ReturnChipBasedAmountUndo(Number(convrAntebt.toFixed(2))), chipvalue: convrAntebt, chipcolor1: null },

                  ],
                }));
              }, 150);
              break;
            case 3:
             
              this.clertime15 = setTimeout(() => {
                this.setState((prevState) => ({
                  storeantebetchips: [
                    ...prevState.storeantebetchips,
                    { chip: this.ReturnChipBasedAmountUndo(Number(convrAntebt.toFixed(2))), chipvalue: convrAntebt, chipcolor1: null },

                  ],
                }));
              }, 150);
              break;
            case 4:
            
              this.clertime16 = setTimeout(() => {
                this.setState((prevState) => ({
                  storeantebetchips: [
                    ...prevState.storeantebetchips,
                    { chip: this.ReturnChipBasedAmountUndo(Number(convrAntebt.toFixed(2))), chipvalue: convrAntebt, chipcolor1: null },

                  ],
                }));
              }, 150);
              break;
            case 5:
              
              this.clertime17 = setTimeout(() => {
                this.setState((prevState) => ({
                  storeantebetchips: [
                    ...prevState.storeantebetchips,
                    { chip: this.ReturnChipBasedAmountUndo(Number(convrAntebt.toFixed(2))), chipvalue: convrAntebt, chipcolor1: null },

                  ],
                }));
              }, 150);
              break;
            case 6:
             

              this.clertime17 = setTimeout(() => {
                this.setState((prevState) => ({
                  storeantebetchips: [
                    ...prevState.storeantebetchips,
                    { chip: this.ReturnChipBasedAmountUndo(Number(convrAntebt.toFixed(2))), chipvalue: convrAntebt, chipcolor1: null },

                  ],
                }));
              }, 150);
              break;
            default:
              break;
          }


        }

      }


    }


  }






  FoldCards() {
    this.setState({showpayouts:false})
    if (this.state.isoptionmenu === true) {
      this.Showoptionsmenu();
    }
    this.clearallsettimeouts()
    if (this.state.mutesounds) {
         this.betrussiansnd.pause();
       }
       else {
         this.betrussiansnd.play();
   
       }

    // this.setState({bsbtn:true,insurencebtn:true,antebetcircle:true})
    // alert("foldcard");

    const buybutton = document.getElementById("undobutton");
    const betbutton = document.getElementById("betbtn");
    const foldbutton = document.getElementById("Foldbtncrbn");


    // document.getElementById("drawtext").textContent = "";
    document.getElementById("undotext").textContent = "";

    betbutton.src = "";
    betbutton.style.cssText = ""

    foldbutton.src = "";
    foldbutton.style.cssText = ""


    buybutton.src = "";
    buybutton.style.cssText = ""
    document.getElementById("betbtntext").innerHTML = "";

    document.getElementById("foldtextcrbn").textContent = "";

    if (
      navigator.userAgentData &&
      navigator.userAgentData.platform &&
      navigator.userAgentData.platform == "Windows"
    ) {
      this.setState({ Foldbtn: 1 })
      document.getElementById("mobileAntiMaskCover_crbn").style.display = "none";
      document.getElementById("mobileAntiMaskCover_crbn").classList.remove("maskcoverlndspe_crbn")
      // document.getElementById("betTextaddtodraw").style.zIndex=-1
      document.getElementById("betbtntext").classList.remove("betwindowscrbn");
      document.getElementById("foldtextcrbn").classList.remove("foldtextwindowscrbn");
      // document.getElementById("drawtext").textContent = "";

      buybutton.src = "";
      buybutton.style.cssText = ""
      document.getElementById("undotext").textContent = "";
    } else {



      this.setState({ betchipincrementbtn: false, betdecreamentbtn: false });

      document.getElementById("betbtntext").classList.remove("betportraitcrbn");
      document.getElementById("foldtextcrbn").classList.remove("foldtextportraitcaribben");
      const isPortrait = window.innerHeight > window.innerWidth;
      if (isPortrait) {
        document.getElementById("mobileAntiMaskCover_crbn").style.display = "none";
        document.getElementById("betpannelcarebbeancover").style.display = "none";
        // alert(".......123portraiteclear");
      } else {
        // alert(".......landscape");
      }
    }

    const body = {
      sessionId: sessionStorage.getItem("sessionId"),

      CN: "CARIBBEAN_POKER_BET",
      object: {
        action: "FOLD",
        gameId: this.props.gameId,
        playerId: Number(this.props.playerId),
        handId: Number(this.props.gameState.handId)
      },
    };
    console.log(body);
    this.props.network.sendof(body);
    // const body2 = {
    //   CN: "GET_PLAYER_INFO",
    //   object: {
    //     gameId: Number(this.props.gameId,),
    //     playerId: Number(this.props.playerId),

    //   },
    //   sessionId: sessionStorage.getItem('sessionId'),
    // }
    // this.props.network.sendof(body2);
    // this.body2 = {};



  }





  FoldHandrespCaribbean(res) {


    this.updateplayerbalnace();
    const betbutton = document.getElementById("betbtn");
    const foldbutton = document.getElementById("Foldbtncrbn");
  

    document.getElementById("mobileAntiMaskCover_crbn").style.display = "none";
    document.getElementById("mobileAntiMaskCover_crbn").classList.remove("maskcoverlndspe_crbn")
    if (res.message === `Attention! You have a ${res.playerHand}. Are you sure you want to fold?`) {

      document.getElementById("cnfrmfoldpop").style.visibility = "visible"
      this.setState({ cmfrmdealeraction: 2, cmfrmdealeractionNO: 2 })
      if (
        navigator.userAgentData &&
        navigator.userAgentData.platform &&
        navigator.userAgentData.platform == "Windows"
      ) {


        document.getElementById("betpannelcarebbeancover").style.display = "block";
        const drawbutton = document.getElementById("drawbtn");
        const buybutton = document.getElementById("undobutton");
        const betbutton = document.getElementById("betbtn");
        const foldbutton = document.getElementById("Foldbtncrbn");

        // const drawtextElement = document.getElementById("drawtext");
        // const buytextElement = document.getElementById("buytext");
        const betbtntextElement = document.getElementById("betbtntext");
        const foldtextElement = document.getElementById("foldtextcrbn");

        betbtntextElement.textContent = "";
        foldtextElement.textContent = "";



        buybutton.src = "";
        buybutton.style.cssText = "";

        betbutton.src = "";
        betbutton.style.cssText = "";


        foldbutton.src = "";
        foldbutton.style.cssText = "";


        document.getElementById("cnfrmfoldpop").style.display = "block";
        document.getElementById("alertTxtcrbn").classList.add("alertTxtcrbn");

        document.getElementById("alertTxtcrbn").innerHTML = res.message;
        document.getElementById("cnfrmfoldpop").classList.add("popup_cnfrmbuydealrcardcrbn");
        document.getElementById("yesbtn").classList.add("yesbutton_popupcrbn")
        document.getElementById("nobtn").classList.add("nobutton_popupcrbn")

      }
      else {

        document.getElementById("mobileAntiMaskCover_crbn").style.display = "none";
        this.setState({ bsbtn: true, mobileAntiMaskCovercss: "", betactvglwcss: "", anteglowstatus: 1 })
        const confirmbuydealerPopupElement = document.getElementById("cnfrmfoldpop");


        setTimeout(() => {
          document.getElementById("mobileAntiMaskCover_crbn").style.display = "none";
          document.getElementById("betpannelcarebbeancover").style.display = "none";
        }, 500)
        // const isPortrait = window.innerHeight > window.innerWidth;
        // if (isPortrait) {
        //   document.getElementById("mobileAntiMaskCover_crbn").style.display = "none";
        //   document.getElementById("betpannelcarebbeancover").style.display = "none";

        // } else {

        // }
        if (window.innerHeight > window.innerWidth) {

          confirmbuydealerPopupElement.className = "";
          confirmbuydealerPopupElement.style.display = "block";
          confirmbuydealerPopupElement.classList.add("popup_Portraitcrbn");
          document.getElementById("alertTxtcrbn").innerHTML = res.message;
          document.getElementById("alertTxtcrbn").classList.add('alertTxtPortraitcrbn');
          document.getElementById("yesbtn").classList.add("yesbutton_popup_portraitcrbn")
          document.getElementById("nobtn").classList.add("nobutton_popup_portraitcrbn")

        }
        else {
          confirmbuydealerPopupElement.style.display = "block";
          confirmbuydealerPopupElement.classList.add("popup_cnfrmfoldcrbn");
          document.getElementById("alertTxtcrbn").innerHTML = res.message;
          document.getElementById("alertTxtcrbn").classList.add('alertTxtPortraitcrbn');
          document.getElementById("yesbtn").classList.add("yesbutton_popup_portraitcrbn")
          document.getElementById("nobtn").classList.add("nobutton_popup_portraitcrbn")

        }
      }


    }
    else {

      this.clertime10 = setTimeout(() => {
        this.setState({ infoText: this.props.language.Place_antebet });
      }, 2000);
      this.playRemainingSounds(this.playerLose)
      betbutton.src = Betbtn;
      //document.getElementById("betbtntext").textContent = "";
      document.getElementById("betbtntext").innerHTML = this.props.language.Rebet;

      document.getElementById("foldtextcrbn").textContent = this.props.language.RebetX2;
      this.updateplayerbalnace();

      if (
        navigator.userAgentData &&
        navigator.userAgentData.platform &&
        navigator.userAgentData.platform == "Windows"
      ) {
        document.getElementById("betpannelcarebbeancover").style.display = "none";
        // document.getElementById("betglow").classList.remove("betglow")
        document.getElementById("anteglow").classList.add("anteglow")

        this.setState((prevState) => ({
          previousAnteBetAmount: res.anteBetAmount,
          previousBonusbetamount: res.bonusBetAmount,
          totalbet: 0,
          totalwinRussian: 0,
          anteBetAmount: 0,
          betbutton: "REBET",
          buttontext: "REBETX2",
          infoText: res.message,
          betchipincrementbtn: false, betdecreamentbtn: false
        }));



        this.cardsRef.current.ReBet();
        this.dealerCardsContainer.current.ReBet();
        document.getElementById("rankinginfodeler_crbn").classList.remove("windowscareabean_dealer");
        document.getElementById("rankinginfo_crbn").classList.remove("crbn_playerrank");




        document.getElementById("betbtntext").textContent = "";
        document.getElementById("foldtextcrbn").textContent = "";
        // this.clertime11=setTimeout(() => {

        betbutton.src = Betbtn;
        Object.assign(betbutton.style, carebeanjson.Betbtnwindows)

        document.getElementById("betbtntext").innerHTML = this.props.language.Rebet;
        document.getElementById("betbtntext").classList.add("betwindowscrbn");


        foldbutton.src = Foldbnwindows;
        Object.assign(foldbutton.style, carebeanjson.foldbtnwindows)
        document.getElementById("foldtextcrbn").textContent = this.props.language.RebetX2;
        document.getElementById("foldtextcrbn").classList.add("rebettextwindows_crbn");
        this.setState({ infoText: this.props.language.Place_antebet, bsbtn: true });

        let t1 = gsap.timeline();
        t1.to("#chipcentercarebean", {
          // y: -2060, duration: 20.5, opacity: 1,
          x: 0, y: -1061, duration: 0.2, opacity: 1,
          onComplete: () => {
            gsap.set("#chipcentercarebean", { opacity: 0 });
          }
        });
        t1.to("#chipcentercarebean", {
          x: 955, y: 554, duration: 0.1, zIndex: -12, opacity: 0,
          onComplete: () => {
            gsap.set("#chipcentercarebean", { opacity: 0 });
          }

        });

        t1.play();


      }
      else {

        // document.getElementById("betglow").classList.remove("betactivePortrait_crbn")

        this.playRemainingSounds(this.playerLose)

        this.setState((prevState) => ({
          previousAnteBetAmount: prevState.anteBetAmount,

          totalbet: 0,
          anteBetAmount: 0,

          anteglowstatus: 0,
          // betdeductions: 0,
          betbutton: "REBET",
          buttontext: "REBETX2",
          infoText: res.message
        }));



        const betbtntextElement = document.getElementById("betbtntext");
        const foldtextElement = document.getElementById("foldtextcrbn");
        // const betactiveBlinkElement = document.getElementById("betglow")
        // betactiveBlinkElement.src = "";
        // betactiveBlinkElement.classList.remove("betactivePortrait_crbn");
        let rankinginfoElement = document.getElementById("rankinginfo_crbn");
        rankinginfoElement.className = "";
        rankinginfoElement.textContent = "";

        this.clearActivesWeDid();

        betbutton.src = Betbtn;
        foldbutton.src = Foldbn;

        if (window.innerHeight > window.innerWidth) {
          document.getElementById("anteglow").classList.add("anteglowportraite")

          Object.assign(betbutton.style, carebeanjson.Betbtnportraite)
          Object.assign(foldbutton.style, carebeanjson.foldbtnportraite);

          betbtntextElement.textContent = this.props.language.Rebet;
          foldtextElement.textContent = this.props.language.RebetX2;
          // carebeanjson.betportraitcrbne
          betbtntextElement.classList.add("betportraitcrbn");
          foldtextElement.classList.add("rebettextportraitcrbn");


          // x: 25,
          // y: 1200,
          let t1 = gsap.timeline();
          t1.to("#chipcentercarebean", {
            // x:25, y: 1200, duration: 0.2, opacity: 1,
            x: 25, y: -2060, duration: 0.2, opacity: 1,
            onComplete: function () {
              gsap.set("#chipcentercarebean", { opacity: 0 });
            }
          });
          t1.to("#chipcentercarebean", {
            // x: 13,
            y: -1130, duration: 0.0001, zIndex: -12, opacity: 0,
            onComplete: function () {
              gsap.set("#chipcentercarebean", { opacity: 0, });
            }

          });
          t1.play();

          if (this.state.buydrawCheck) {

            let t4 = gsap.timeline();
            t4.to(this.chipdrawref.current, {
              x: 340, y: -2060, duration: 0.5, zIndex: 12, ease: "power2.inOut", opacity: 1,
              // x: 360, y: -2060, duration: 0.5, zIndex: 12, ease: "power2.inOut", opacity: 1,
              onComplete: () => {
                gsap.set(this.chipdrawref.current, { opacity: 0 });
              }
            });
            t4.to(this.chipdrawref.current, {
              x: 548, y: -36, duration: 0.5, zIndex: -2, opacity: 0,
              onComplete: () => {
                gsap.set(this.chipdrawref.current, { opacity: 1, delay: 2 });
              }
            });
            t4.play();
          }


          if (this.playerWinBonus === true) {
            if (document.getElementById("chip10centerbns")) {
              gsap.to("#chip10centerbns", { x: -170, y: 1200, duration: 0.5, zIndex: -2, opacity: 1 });
              this.playerWinBonus = false;

            }

          }



        } else {
          // document.getElementById("betglow").classList.remove("betactivelndspe_crbn")

          document.getElementById("anteglow").classList.add("anteglowlndspe")
          // document.getElementById("anteglow").classList.remove("anteglowlndspe")
          // IF MOBILE LANDSCAPE ;

          Object.assign(betbutton.style, carebeanjson.Betbtnlndspe)
          Object.assign(foldbutton.style, carebeanjson.foldbtnlndspe);

          betbtntextElement.textContent = this.props.language.Rebet;
          foldtextElement.textContent = this.props.language.RebetX2;
          // carebeanjson.betportraitcrbne
          betbtntextElement.classList.add("betLandscapecrbn");
          foldtextElement.classList.add("foldtextLandscapecrbn");




          let t1 = gsap.timeline();
          t1.to("#chipcentercarebean",
            { x: 0, y: -600, duration: 0.3, autoAlpha: 1, ease: "power2.inOut", });
          t1.to("#chipcentercarebean",
            { x: 0, y: 0, duration: 0.0001, zIndex: -12, autoAlpha: 0 });
          t1.play();




        }
      }
    }
  }


  Show_FoldHandrespCaribbean(res) {

    // if (res.message === `Attention! You have a ${res.playerHand}. Are you sure you want to fold?`) {
    document.getElementById("mobileAntiMaskCover_crbn").style.display = "none";
    document.getElementById("mobileAntiMaskCover_crbn").classList.remove("maskcoverlndspe_crbn")
    if (res.message === `Attention! You have a ${res.playerHand}. Are you sure you want to fold?`) {
    
      this.setState({
        cmfrmdealeraction: 2, cmfrmdealeractionNO: 2, infoText: res.message, buttontext: "fold",
        betbutton: "Bet", totalbet: res.totalBetAmount, pokerranking: res.playerHand,
      })
      if (
        navigator.userAgentData &&
        navigator.userAgentData.platform &&
        navigator.userAgentData.platform == "Windows"
      ) {
         
        document.getElementById("betpannelcarebbeancover").style.display = "block";
        document.getElementById("betpannelcarebbeancover").classList.add("bet_pannelcaribbean_CRBN");

        this.showcardsAdBetsParamState(res, res.playerCardsSorted, res.dealerCards);
        this.showParamBetStatus(false);


         document.getElementById("cnfrmfoldpop").style.visibility = "visible"
        document.getElementById("cnfrmfoldpop").style.display = "block";
        document.getElementById("alertTxtcrbn").classList.add("alertTxtcrbn");

        document.getElementById("alertTxtcrbn").innerHTML = res.message;
        document.getElementById("cnfrmfoldpop").classList.add("popup_cnfrmbuydealrcardcrbn");
        document.getElementById("yesbtn").classList.add("yesbutton_popupcrbn")
        document.getElementById("nobtn").classList.add("nobutton_popupcrbn")

      }

    }
    else {
      document.getElementById("betpannelcarebbeancover").style.display = "none";
      document.getElementById("betpannelcarebbeancover").classList.remove("bet_pannelcaribbean_CRBN");
      this.updateplayerbalnace();
      const betbutton = document.getElementById("betbtn");
      const foldbutton = document.getElementById("Foldbtncrbn");
      this.clertime10 = setTimeout(() => {
        this.setState({ infoText: this.props.language.Place_antebet });
      }, 700);


      // document.getElementById("betpannelrussiancover").style.display = "block";
      // document.getElementById("betpannelrussiancover").classList.remove("bet_pannelcaribbean_CRBN");

      // this.setState({ betbutton: "REBET", buttontext: "REBETX2" })
      // const betbutton = document.getElementById("betbtn");
      // const foldbutton = document.getElementById("Foldbtn");
      betbutton.src = Betbtn;
      //document.getElementById("betbtntext").textContent = "";
      document.getElementById("betbtntext").innerHTML = this.props.language.Rebet;

      // document.getElementById("betbtntext").classList.add("rebet");







      document.getElementById("foldtextcrbn").textContent = this.props.language.RebetX2;

      // if (document.getElementById("dealbtntext")) {
      //   document.getElementById("dealbtntext").textContent = "REBET";
      // }
      // if (document.getElementById("betbtntextrebet")) {
      //   document.getElementById("betbtntextrebet").textContent = "REBET";
      // }


      // this.setState({ infoText: res.message });



      if (
        navigator.userAgentData &&
        navigator.userAgentData.platform &&
        navigator.userAgentData.platform == "Windows"
      ) {

        this.setState((prevState) => ({
          previousAnteBetAmount: res.anteBetAmount,

          totalbet: 0,
          anteBetAmount: 0,

          betdeductions: 0,
          betbutton: "REBET",
          buttontext: "REBETX2",

          infoText: res.message
        }));

        this.cardsRef.current.ReBet();
        this.dealerCardsContainer.current.ReBet();
        document.getElementById("rankinginfo_crbn").classList.remove("windowscareabean_dealer");
        document.getElementById("rankinginfo_crbn").classList.remove("crbn_playerrank");



        document.getElementById("betbtntext").textContent = "";
        document.getElementById("foldtextcrbn").textContent = "";
        // this.clertime11=setTimeout(() => {

        betbutton.src = Betbtn;
        Object.assign(betbutton.style, carebeanjson.Betbtnwindows)

        document.getElementById("betbtntext").innerHTML = this.props.language.Rebet;
        document.getElementById("betbtntext").classList.add("betwindowscrbn");


        foldbutton.src = Foldbnwindows;
        Object.assign(foldbutton.style, carebeanjson.foldbtnwindows)
        document.getElementById("foldtextcrbn").textContent = this.props.language.RebetX2;
        document.getElementById("foldtextcrbn").classList.add("rebettextwindows_crbn");
        this.setState({ infoText: this.props.language.Place_antebet, bsbtn: true });



        let t1 = gsap.timeline();
        t1.to("#chipcentercarebean", {
          // y: -2060, duration: 20.5, opacity: 1,
          x: 0, y: -662, duration: 0.2, opacity: 1,
          onComplete: () => {
            gsap.set("#chipcentercarebean", { opacity: 0 });
          }
        });
        t1.to("#chipcentercarebean", {
          x: 955, y: 554, duration: 0.1, zIndex: -12, opacity: 0,
          onComplete: () => {
            gsap.set("#chipcentercarebean", { opacity: 0 });
          }

        });

        t1.play();
        // document.getElementById("centerchips").classList.remove("chip10centerposition")


      }



    }

  }





  TotalbetamountGreaterthanbalance(betamnt) {
    let balance = this.balnce;

    if (navigator.userAgentData &&
      navigator.userAgentData.platform &&
      navigator.userAgentData.platform === "Windows") {
      // alert("......."+this.state.totalbet)
      if (this.state.totalbet > this.props.balance) {
        // alert("......."+betamnt)
        // document.getElementById("info_crbn").classList.add("windowsinfo");
        document.getElementById("betbtn").classList.remove("addpointercrbn")
        document.getElementById("Foldbtncrbn").classList.remove("addpointercrbn")
        document.getElementById("betbtn").classList.add("drwnodrop")
        document.getElementById("Foldbtncrbn").classList.add("drwnodrop")
        // alert(".........................amountreduces")
        this.setState({
          infoText: this.props.language.Low_Balance,
          disablemultibutton: false,
          multibuttonsebledsble: false


        });
        document.getElementById("betpannelcarebbeancover").style.display = "block";
        document.getElementById("betpannelcarebbeancover").classList.add("bet_pannelcaribbean_CRBN");
        document.getElementById("betbtn").classList.add("addpointercrbn")
        document.getElementById("Foldbtncrbn").classList.add("addpointercrbn")

      }
      else {
        // document.getElementById("betpannelcarebbeancover").style.display = "none";
        // document.getElementById("betpannelcarebbeancover").classList.remove("bet_pannelcaribbean_CRBN");
        // this.setState({ infoText: this.props.language.deal_Game })
        document.getElementById("betbtn").classList.remove("drwnodrop")
        document.getElementById("Foldbtncrbn").classList.remove("drwnodrop")



      }
    } else {
      const inforussianElement = document.getElementById("info_crbn");
      const betbtnElement = document.getElementById("betbtn");
      const FoldbtnElement = document.getElementById("Foldbtncrbn");
      const mobileAntiMaskCoverElement = document.getElementById("mobileAntiMaskCover_crbn")
      inforussianElement.className = "";
      betbtnElement.className = "";
      FoldbtnElement.className = "";

      if (window.innerHeight > window.innerWidth) {
        if (this.state.totalbet > this.props.balance) {
          inforussianElement.classList.add('portraitinfo_crbn');
          betbtnElement.classList.add('drwnodrop');
          FoldbtnElement.classList.add('drwnodrop');
          mobileAntiMaskCoverElement.style.display = 'block';
          mobileAntiMaskCoverElement.classList.add('mobileAntiMaskCover_crbn');
          this.setState({
            infoText: this.props.language.Low_Balance,
            disablemultibutton: false,
            multibuttonsebledsble: false
          });

        } else {
          mobileAntiMaskCoverElement.style.display = 'none';
          mobileAntiMaskCoverElement.classList.remove('mobileAntiMaskCover_crbn');
          this.setState({ infoText: this.props.language.deal_Game });
          betbtnElement.classList.remove("drwnodrop");
          FoldbtnElement.classList.remove("drwnodrop");
        }

      } else {

      }
    }

  }


  anteclickCaribbean(carebean_bet) {
    

    
    
    console.log(carebean_bet)
    this.playPokerChipSound(this.pokerchipsnd);
this.setState({showpayouts:false,anteglowstatus:0})
    if (this.state.isoptionmenu === true) {
      this.Showoptionsmenu();
    }

    // const betbutton = document.getElementById("betbtn");
    // // const foldbutton = document.getElementById("Foldbtn");
    // this.pokerchipsnd = new Audio(pokerchipsnd);
    // if (this.state.mutesounds) {
    //   this.pokerchipsnd = new Audio(pokerchipsnd);
    //   this.pokerchipsnd.pause();

    // }
    // else {
    //   this.pokerchipsnd = new Audio(pokerchipsnd);
    //   this.pokerchipsnd.play();

    // }
    const chipsstoredcontainerElement = document.getElementById('chipsstoredcontainer');
    // if(chipsstoredcontainerElement !== null){
    //   chipsstoredcontainerElement.className = "";
    //   }

    //  alert("................."+this.state.totalbet+this.props.balance)

    // this.TotalbetamountGreaterthanbalance();


    const betchipstoantebet = document.getElementById(`careabeanantebets${this.state.carebbeanidincrement}`);
    const betbutton = document.getElementById("betbtn");
    const foldbutton = document.getElementById("Foldbtncrbn");
    // let bnsactived = document.getElementById("bnsactive");
    // bnsactived.src = bnsactive;
    const winpopuprspelement = document.getElementById("winpopup_crbn");
    const winamtElement = document.getElementById("winamt_crbn");
    // document.getElementById("bonusRussian").classList.remove("drwnodrop");
    // document.getElementById("bonusRussian").classList.add("addpointercrbn");




    // pokerranking

    this.setState((prevState) => ({
      carebbeanidincrement: prevState.carebbeanidincrement + 1,
      // betbutton: "DEAL",
      buttontext: "clear",
      bsbtn: false,
      pokerranking: "",
      pokerrankingdeler: "",
      // totalwinRussian: 0,
      disClearBtn: 0,
      betBtnValue: 0,
      disBetBtn: 0,
      keyboardhandle: true,
      infoText: "",
      //  totalbet:prevState.totalbet + 1,
    }));

    // this.cardsRef.current.ReBet();
    // this.dealerCardsContainer.current.ReBet();
    this.randomPlayerCards = [];
    this.shuffledPlayerCards = [];
    this.randomDealerCards = [];
    this.replaceCards = [];
    this.unsequenceDrawCards = [];
    this.sequenceDrawCards = [];
    this.buyCardUnsequenceArray = [];
    this.buyCardsequenceArray = [];
    this.dealerCardsRandom = [];
    this.dealerShuffledCards = [];
    this.dealerUnsequenceDrawCards = [];
    this.dealerSequenceDrawFinalCards = [];

    document.getElementById('rankinginfodeler_crbn').className = "";
    document.getElementById('rankinginfo_crbn').className = "";
   
    // document.getElementById("bonusRussian").classList.remove("bnspoiner");
    let rebetamount = 0;
    let rebetorrebetX2 = 1;
    if (carebean_bet == "REBET") {
      rebetorrebetX2 = 1;
      console.log(carebean_bet)
      rebetamount = this.state.previousAnteBetAmount
      // this.setState({ totalbet: rebetamount }, () => {
      //   this.balnce = this.topLable_rsp(Math.floor(this.props.balance - rebetamount));
      // })
      this.setState({
      
        anteBetAmount: rebetamount,


        betBtnValue: 0, disBetBtn: 0

      })
      console.log("rebet    : ", this.state.anteBetAmount);

    }
    // console.log("this.state.previousAnteBetAmount", this.state.previousAnteBetAmount,
    //   "this.state.previousBonusbetamount", this.state.previousBonusbetamount
    // );
    else if (carebean_bet == "REBETX2") {
      rebetorrebetX2 = 2;
      let rebetdouble = (this.state.previousAnteBetAmount) * 2;
      rebetamount = rebetdouble;
      this.setState((prevState) => ({
       
        anteBetAmount: rebetdouble,


        betBtnValue: 0, disBetBtn: 0

      }));
      // this.balnce = this.topLable_rsp(Math.floor(this.props.balance - rebetdouble));
      console.log("rebetX2    : ", this.state.previousAnteBetAmount)
    }
    gsap.to(`#careabeanantebets${this.state.carebbeanidincrement}`, {

      opacity: 1
    });

    this.setState({ infoText: this.props.language.deal_Game, betchipincrementbtn: false, betdecreamentbtn: false })


    if (this.state.previousAnteBetAmount * rebetorrebetX2 <= this.props.maxBetAmt) {
     
      // document.getElementById("betbtncrbn").style.display="none"
      // document.getElementById("undobtn").style.display = "block";
      setTimeout(()=>{
        document.getElementById("betbtncrbn").style.display="block"
        document.getElementById("undobtn").style.display="block"
  
      },200)
      if (
        navigator.userAgentData &&
        navigator.userAgentData.platform &&
        navigator.userAgentData.platform == "Windows"
      ) {

        // alert(">.....rebetstutus"+)

        this.setState({ betbutton: "DEAL", })
        const popupbetGreaterthanBalnceElement = document.getElementById("popupbet_greaterthanbalncecrbn");
        const textbetsHighElement = document.getElementById("textbets_highcrbn");
        const attentionElement = document.getElementById("attentioncrbn");
        const betbtnElement = document.getElementById("betbtn");

        let rebetstatus = 1;
        if (carebean_bet == "REBETX2" || carebean_bet == "REBET") {


          document.getElementById("undobtn").style.display = "block";




          document.getElementById("playerDealerCardContainercrbn").classList.add("addpointercrbn")

          let totalBetAmountForLimitsCheck;
          let totalAnteBetAmount

          for (let k = 1; k <= this.chipVal.length; k++) {
            if (this.state.count === k) {
              totalBetAmountForLimitsCheck = this.state.totalbet + Number(this.chipVal[k - 1].val);
              if (carebean_bet == "REBETX2") {
                let chip10centerbnsElement = document.querySelectorAll("#chip10centerbns span");
                console.log(this.state.anteBetAmount)
                // alert("...................p1")
                // let chipcenterpositionElement = document.querySelectorAll("#chipcentercarebean span");
                // let chip10centerbnsElement = document.querySelectorAll("#chip10centerbns span");
                // console.log(chipcenterpositionElement)
                // for (let a = 0; a < chipcenterpositionElement.length; a++) {
                //   chipcenterpositionElement[a].innerHTML = this.state.previousAnteBetAmount * 2;
                // }
                for (let b = 0; b < chip10centerbnsElement.length; b++) {
                  chip10centerbnsElement[b].innerHTML = this.state.previousBonusbetamount * 2;
                }


                totalAnteBetAmount = (this.state.previousAnteBetAmount) * 2;
                chip10centerbnsElement = (this.state.bonusbetamount) * 2;

                rebetstatus = 2;
              
 
                // document.querySelector('antebetChipPortrait10').innerHTML = totalAnteBetAmount
              } else {
                this.setState({ betbutton: "DEAL"})
                //  alert(".........."+ this.state.previousAnteBetAmount)

                console.log(this.state.previousAnteBetAmount)
                totalAnteBetAmount = this.state.previousAnteBetAmount;
                console.log(totalAnteBetAmount)

                rebetstatus = 1;


                // alert("..............."+this.state.bonusbetamount)

                //totalAnteBetAmount = this.state.anteBetAmount + Number(this.chipVal[k - 1].val);
              }
            }
          }


          if (Number((Number(this.state.previousAnteBetAmount * rebetstatus)).toFixed(2)) > Number(this.props.balance) ||
          Number((Number(this.state.previousAnteBetAmount * 3 * rebetstatus)).toFixed(2)) > Number(this.props.balance)) {
          // console.log("this.state.anteBetAmount :------", this.state.previousAnteBetAmount, this.state.previousBonusbetamount);
          this.setState({
            anteBetAmount: 0,
            bonusbetamount: 0,
            totalbet: 0,
          })
          console.log(Number((Number(this.state.previousAnteBetAmount * rebetstatus)).toFixed(2)))
          console.log(this.props.balance)
          if (Number((Number(this.state.previousAnteBetAmount * rebetstatus)).toFixed(2)) > Number(this.props.balance)) {

            this.setState({
              betshigh_balnce: true,
              lowBalaceErrorInfo: `Insufficient Funds to place the ANTE of ${(this.state.previousAnteBetAmount * rebetstatus).toFixed(1)},Please DEPOSIT to continue playing`
            })




            // this.setState({ betshigh_balnce: true, multibuttonsebledsble: true, disablemultibutton: true })
          } else if (Number((Number(this.state.previousAnteBetAmount * 3 * rebetstatus)).toFixed(2)) > Number(this.props.balance)) {


            this.setState({
              betshigh_balnce: false,
              lowBalaceErrorInfo: `Insufficient Funds to follow up your ANTE of ${(this.state.previousAnteBetAmount * rebetstatus).toFixed(1)},with BET ${(this.state.previousAnteBetAmount * rebetstatus * 2).toFixed(1)} Please DEPOSIT to continue playing.`
            })




            // this.setState({
            //   anteBetAmount: 0,
            //   totalbet: 0,
            // })
            this.setState({ betshigh_balnce: true, multibuttonsebledsble: true })
            document.getElementById("betbtn").classList.remove("addpointercrbn")
            document.getElementById("betbtn").classList.add("drwnodrop")
            popupbetGreaterthanBalnceElement.className = "";
            gsap.fromTo(popupbetGreaterthanBalnceElement, { scale: 0, rotate: 0, opacity: 0 },
              { scale: 1, duration: 1, rotate: 720, opacity: 1, ease: "power2.out" });

            // if (popupbetGreaterthanBalnceElement && textbetsHighElement && attentionElement) {

            //   popupbetGreaterthanBalnceElement.classList.add("popupbetshighcrbn");
            //   textbetsHighElement.classList.add("poptext_betshighcrbn");
            //   attentionElement.classList.add("attention_textcrbn");


              if (popupbetGreaterthanBalnceElement && textbetsHighElement && attentionElement) {
                popupbetGreaterthanBalnceElement.classList.add("popupbetshighwindows");
                textbetsHighElement.classList.add("poptextBetshighwindowscaribbean");
                attentionElement.classList.add("attention_textcaribbean");

              }



            }


          }
          
          else if (this.state.previousAnteBetAmount * rebetstatus > this.props.maxBetAmt) {
          
            this.setState({
              infoText: this.props.language.MinBetAmt + " " + this.props.minBetLbl + " " + this.props.language.MaxBetAmt + " " + this.props.maxBetLbl,
              multibuttonsebledsble: true,
              anteBetAmount: 0,
              totalbet: 0,
            })
            document.getElementById("betpannelcarebbeancover").style.display = "none";

          } else {

            this.previousantebonousamount(carebean_bet);

            let totalBetAmountForLimitsCheck = 0;


            for (let k = 1; k <= this.chipVal.length; k++) {
              if (this.state.count === k) {
                if (this.state.anteBetAmount + Number(this.chipVal[k - 1].val) < Number(this.props.balance)) {

                  totalBetAmountForLimitsCheck = this.state.anteBetAmount + Number(this.chipVal[k - 1].val);


                  this.setState({ multibuttonsebledsble: false });

                  // gsap.to("#chipcentercarebean", { opacity: 0 });
                  const betbutton = document.getElementById("betbtn");
                  const foldbutton = document.getElementById("Foldbtncrbn");


                  document.getElementById("betbtntext").textContent = "";
                  document.getElementById("foldtextcrbn").textContent = "";

                  betbutton.src = "";
                  betbutton.style.position = "";
                  betbutton.style.width = "";
                  betbutton.style.transform = "";

                  foldbutton.src = "";
                  foldbutton.style.position = "";
                  foldbutton.style.width = "";
                  foldbutton.style.transform = "";



                  // var tl = gsap.timeline();
                  // tl.to(this.chiprefcarebean.current, {
                  //   x: -300,
                  //   y: -106,
                  //   duration: 0.5,
                  //   ease: "power2.inOut",
                  //   zIndex: 12,
                  //   autoAlpha:1,
                  //   onComplete: () => {
                  //     gsap.set(this.chiprefcarebean.current, { autoAlpha: 0 })
                  //   }
                  // });
                  // tl.to(this.chiprefcarebean.current, {
                  //   x: 1090,
                  //   y: 351,
                  //   autoAlpha:0,
                  //   duration: 0.00001,

                  // });
                  // // tl.reverse();
                  // // tl.to(this.chiprefcarebean.current, { opacity: 1, zIndex: -2 });
                  // tl.play();
                  // // const betchipstoantebet = document.getElementById("carebeanbetsanteclick");


                  var tl = gsap.timeline();
                  tl.to(this.chiprefcarebean.current, {
                    x: -300,
                    y: -106,
                    duration: 0.2,
                    ease: "power2.inOut",
                    autoAlpha:1,
                    zIndex: 12,
                    onComplete: () => {
                      gsap.set(this.chiprefcarebean.current, { autoAlpha: 0 });
                    }
                  });
                  tl.to(this.chiprefcarebean.current, {
                    autoAlpha: 0,
    
                    x: 790,
                    duration: 0.1,
                    y: 45,
                    zIndex: -2,
                  
                  });



                  const chipDetails = [
                    { val: this.chipVal[0].val, src: chip_10, className: "carebeanantechip_10" ,textclass:"antebet_caribean10"},
                    { val: this.chipVal[1].val, src: chip_25, className: "carebeanantechip_20",textclass:"antebet_caribean10" },
                    { val: this.chipVal[2].val, src: chip_50, className: "carebeanantechip_50" ,textclass:"antebet_caribean10"},
                    { val: this.chipVal[3].val, src: chip_100, className: "carebeanantechip_100",textclass:"antebet_caribean100" },
                    { val: this.chipVal[4].val, src: chip_500, className: "carebeanantechip_500", textclass:"antebet_caribean100"},
                    { val: this.chipVal[5].val, src: chip_1000, className: "carebeanantechip_500",textclass:"antebet_caribean100" },
                  ];

                  const chipData = chipDetails[this.state.count - 1];

                  if (chipData) {
                    betchipstoantebet.src = chipData.src;

                  
                    this.state.previousBets = [];
                    this.state.previousBets.push(Number(totalAnteBetAmount))
                    setTimeout(() => {
                      this.setState((prevState) => ({
                        storebetchips: [
                          ...prevState.storebetchips,
                          <div id="chipcentercarebean">
                            <img
                              id="centerchips"
                              className={chipData.className}
                              src={this.ReturnChipBasedAmount(Number(totalAnteBetAmount.toFixed(2)))}
                              alt={`Chip ${chipData.val}`}
                            />
                            <span id={`antebetval_${chipData.val}`} className={chipData.textclass}>
                              {totalAnteBetAmount}
                            </span>
                          </div>,
                        ],
                      }));
                    }, 150);
                  }

                  Object.assign(betchipstoantebet.style, carebeanjson.Betchiptoantewindows)


                  console.log(this.state.previousBets)

                }




                document.getElementById("rankinginfodeler_crbn").classList.remove("windowscareabean_dealer");
                document.getElementById("rankinginfo_crbn").classList.remove("crbn_playerrank");
                // alert(".........anteclickCaribbean")

                // alert("................."+this.state.count)
                this.cardsRef.current.ReBet();
                this.dealerCardsContainer.current.ReBet();
                this.randomPlayerCards = [];
                this.shuffledPlayerCards = [];

                // gsap.to("#carebeanbetsanteclick", { duration: 0.1, opacity: 0, zIndex: -2 });

                // gsap.to("#betchipsaddtobetcaribn", { x: 0, y: 0, duration: 0.5 });


                // if (this.state.mutesounds) {
                //   this.pokerchipsnd = new Audio(pokerchipsnd);
                //   this.pokerchipsnd.pause();

                // }
                // else {
                //   this.pokerchipsnd = new Audio(pokerchipsnd);
                //   this.pokerchipsnd.play();

                // }

                //  alert("................."+this.state.totalbet+this.props.balance)


                // alert("......."+this.props.language.Deal)

                // console.log(this.state.totalbet)
                // gsap.to("#chip10centerbns",{zIndex:-2})



                // document.getElementById("betpannelcarebbeancover").style.display = "none";
                // document.getElementById("betpannelcarebbeancover").classList.add("bet_pannelcaribbean_CRBN");
                this.setState({ betchipincrementbtn: false, betdecreamentbtn: false })
                document.getElementById("betincrenetcrbn").classList.remove("drwnodrop")
                document.getElementById("betdecrementcrbn").classList.remove("drwnodrop")

                console.log(this.state.totalbet)

                console.log(this.balnce)
                // this.setState({infoText:"Press Deal to start the Game!"})
                // alert("................."+this.state.count)
                // const betbutton = document.getElementById("betbtn");
                // const foldbutton = document.getElementById("Foldbtncrbn");
                const betbutton = document.getElementById("betbtn");
                const foldbutton = document.getElementById("Foldbtncrbn");


                // document.getElementById("bonusRussian").classList.add("addpointercrbn");



                this.setState({ betbutton: "DEAL", buttontext: "clear", bsbtn: false });
                this.setState((prevState) => ({
                  carebbeanidincrement: prevState.carebbeanidincrement + 1,
                  //  totalbet:prevState.totalbet + 1,
                }));
                this.clertime18 = setTimeout(() => {
                  gsap.to(`#chipcentercarebean`, { zIndex: -15 })
                }, 550);
                // const betchipstoantebet = document.getElementById(
                //   `careabeanantebets${this.state.carebbeanidincrement}`
                // );
                console.log(document.getElementById('chipcentercarebean'))
               

                // var tl = gsap.timeline();
                // tl.to(this.chiprefcarebean.current, {
                //   x: -794,
                //   y: -151,
                //   duration: 10.5,
                //   ease: "power2.inOut",
                //   zIndex: 12,
                // });
                // // tl.to(this.chiprefcarebean.current, {
                // //   opacity: 0,
                // //   x: 0,
                // //   y: 0,
                // //   zIndex:-2,
                // //   duration: 0.00001,
                // // });
                // // tl.reverse();

                // tl.play();
              }
            }
          }

        }
        else {

          let totalBetAmountForLimitsCheck = 0;
          let totalAnteBetAmount = 0;
          for (let k = 1; k <= this.chipVal.length; k++) {
            if (this.state.count === k) {
              console.log(this.state.anteBetAmount)
              console.log(Number(this.chipVal[k - 1].val))
              totalBetAmountForLimitsCheck = this.state.anteBetAmount + Number(this.chipVal[k - 1].val);

              totalAnteBetAmount = this.state.anteBetAmount + Number(this.chipVal[k - 1].val)
            }
          }
          totalAnteBetAmount = totalBetAmountForLimitsCheck
          console.log(totalAnteBetAmount)
          if (Number((Number(totalAnteBetAmount)).toFixed(2)) > Number(this.props.balance)) {

            this.setState({
              betshigh_balnce: true,
              multibuttonsebledsble: false,

              lowBalaceErrorInfo: `Insufficient Funds to place the ANTE of ${(totalAnteBetAmount).toFixed(1)},Please DEPOSIT to continue playing`
            })

            this.setState({ betshigh_balnce: true, multibuttonsebledsble: true, disBetBtn: 1 })
            betbtnElement.classList.remove("addpointercrbn")
            betbtnElement.classList.add("drwnodrop");
            popupbetGreaterthanBalnceElement.className = "";

            gsap.fromTo(popupbetGreaterthanBalnceElement, { scale: 0, rotate: 0, opacity: 0, duration: 0.5 },
              { scale: 1, duration: 0.5, rotate: 720, opacity: 1, ease: "power2.out" });

            if (popupbetGreaterthanBalnceElement && textbetsHighElement && attentionElement) {
              popupbetGreaterthanBalnceElement.classList.add("popupbetshighwindowscrbn");
              textbetsHighElement.classList.add("poptextBetshighwindowscrbn");
              attentionElement.classList.add("attentionTextwindows");

            }



            // this.setState({ betshigh_balnce: true, multibuttonsebledsble: true, disablemultibutton: true })
          } else if (Number((Number(totalAnteBetAmount * 3)).toFixed(2)) > Number(this.props.balance)) {


            this.setState({
              betshigh_balnce: false,
              multibuttonsebledsble: false,


              lowBalaceErrorInfo: `Insufficient Funds to follow up your ANTE of ${(totalAnteBetAmount).toFixed(1)},with BET ${(totalAnteBetAmount * 2).toFixed(1)} Please DEPOSIT to continue playing.`
            })

            this.setState({ betshigh_balnce: true, disBetBtn: 1 })
            betbtnElement.classList.remove("addpointercrbn")
            betbtnElement.classList.add("drwnodrop");
            popupbetGreaterthanBalnceElement.className = "";

            gsap.fromTo(popupbetGreaterthanBalnceElement, { scale: 0, rotate: 0, opacity: 0, duration: 0.5 },
              { scale: 1, duration: 0.5, rotate: 720, opacity: 1, ease: "power2.out" });

            if (popupbetGreaterthanBalnceElement && textbetsHighElement && attentionElement) {
              popupbetGreaterthanBalnceElement.classList.add("popupbetshighwindowscrbn");
              textbetsHighElement.classList.add("poptextBetshighwindowscrbn");
              attentionElement.classList.add("attentionTextwindows");

            }

          }




          // this.setState({ betshigh_balnce: true, multibuttonsebledsble: true, disablemultibutton: true })


          // if (totalBetAmountForLimitsCheck > 50) {


          //  else if(this.state.anteBetAmount < this.props.minBetAmt || 
          //           this.state.anteBetAmount > this.props.maxBetAmt) {
          else if (totalAnteBetAmount > this.props.maxBetAmt) {

            this.setState({
              infoText: this.props.language.MinBetAmt + " " + this.props.minBetLbl + " " + this.props.language.MaxBetAmt + " " + this.props.maxBetLbl
            }, () => {
              console.log(this.state.infoText)
            })

          }
          else {

            this.setState({ multibuttonsebledsble: false });
            // let bnsactived = document.getElementById("bnsactive");
            // bnsactived.src = bnsactive;
            // bnsactived.classList.add("bnsctivewindows");
            // bnsactived.classList.add("bnsblink");
            if (this.chiprefcarebean) {


              var tl = gsap.timeline();
              tl.to(this.chiprefcarebean.current, {
                x: -300,
                y: -106,
                duration: 0.2,
                ease: "power2.inOut",
                autoAlpha:1,
                zIndex: 12,
                onComplete: () => {
                  gsap.set(this.chiprefcarebean.current, { autoAlpha: 0 });
                }
              });
              tl.to(this.chiprefcarebean.current, {
                autoAlpha: 0,

                x: 790,
                duration: 0.1,
                y: 45,
                zIndex: -2,
              
              });

            }



            const chipDetails = [
              { val: this.chipVal[0].val, src: chip_10, className: "carebeanantechip_10" ,textclass:"antebet_caribean10"},
              { val: this.chipVal[1].val, src: chip_25, className: "carebeanantechip_20",textclass:"antebet_caribean20" },
              { val: this.chipVal[2].val, src: chip_50, className: "carebeanantechip_50" ,textclass:"antebet_caribean50"},
              { val: this.chipVal[3].val, src: chip_100, className: "carebeanantechip_100",textclass:"antebet_caribean100" },
              { val: this.chipVal[4].val, src: chip_500, className: "carebeanantechip_500", textclass:"antebet_caribean500"},
              { val: this.chipVal[5].val, src: chip_1000, className: "carebeanantechip_500",textclass:"antebet_caribean500" },
            ];
            const chipData = chipDetails[this.state.count - 1];

            if (chipData) {
              betchipstoantebet.src = chipData.src;
              this.state.previousBets.push(Number(chipData.val))
              this.setState((prevState) => ({
                totalbet: prevState.totalbet + Number(chipData.val),
                betdeductions: prevState.totalbet + Number(chipData.val),
                anteBetAmount: prevState.anteBetAmount + Number(chipData.val),
              }));

              setTimeout(() => {
                this.setState((prevState) => ({
                  storebetchips: [
                    ...prevState.storebetchips,
                    <div id="chipcentercarebean">
                      <img
                        id="centerchips"
                        className={chipData.className}
                        src={this.ReturnChipBasedAmount(Number(totalAnteBetAmount))}
                        alt={`Chip ${chipData.val}`}
                      />
                      <span id={`antebetval_${chipData.val}`} className={chipData.textclass}>
                        {totalAnteBetAmount}
                      </span>
                    </div>,
                  ],
                }));
              }, 150);
            }


          }
          // this.clertime24=setTimeout(() => {
          // document.getElementById("bonustext").style.color = "#ffffff";
          Object.assign(betchipstoantebet.style, carebeanjson.Betchiptoantewindows)

          totalAnteBetAmount = this.topLable_rsp(totalBetAmountForLimitsCheck)
        }


        if (this.state.count > 0) {
          // alert("..........."+this.state.totalbet)
          this.TotalbetamountGreaterthanbalance(this.state.totalbet);

        }



        this.clertime25 = setTimeout(() => {
          betbutton.src = Betbtn;
          Object.assign(betbutton.style, carebeanjson.Betbtnwindows)
        
          document.getElementById("betbtntext").textContent = this.props.language.Deal;
          document.getElementById("betbtntext").classList.add("betwindowscrbn");

          foldbutton.src = Foldbnwindows;
          Object.assign(foldbutton.style, carebeanjson.foldbtnwindows)
          document.getElementById("foldtextcrbn").classList.add("foldtextwindowscrbn");
          document.getElementById("foldtextcrbn").classList.remove("rebettextwindows_crbn");
          document.getElementById("foldtextcrbn").textContent = this.props.language.RP_Clear;


          const buybutton = document.getElementById("undobutton");
          buybutton.src = Buybtnwindows;
          document.getElementById("undotext").classList.add("undotextwindows");
          document.getElementById("undotext").textContent = "UNDO"
          Object.assign(buybutton.style, carebeanjson.Buybtnwindows);

        }, 500)

      }

      else {

        this.betchipactivated = false;
        this.buydrawchipactivated = false;
        this.ConfirmbuydealercardactivePopup = false;
        this.resetPyrWnCpsAmn = false;


        document.getElementById("betpannelcarebbeancover").style.display = "block";
        if (document.getElementById("rankinginfodeler_crbn")) {
          document.getElementById("rankinginfodeler_crbn").style.cssText = "";
          document.getElementById("rankinginfo_crbn").style.cssText = "";
        };
        const betbtnElement = document.getElementById("betbtn");
        const betbtntextElement = document.getElementById("betbtntext");
        const foldtextElement = document.getElementById("foldtextcrbn");
        const popupbetGreaterthanBalnceElement = document.getElementById("popupbet_greaterthanbalncecrbn");
        const textbetsHighElement = document.getElementById("textbets_highcrbn");
        const attentionElement = document.getElementById("attentioncrbn");




        betbtntextElement.className = "";
        foldtextElement.className = "";
        betbtntextElement.textContent = this.props.language.Deal;
        foldtextElement.textContent = this.props.language.RP_Clear;
        betbutton.src = Betbtn;
        foldbutton.src = Foldbn;


        this.setState({
          multibuttonsebledsble: false,
          betbutton: "DEAL",
          buttontext: "clear",
        
        })

        const isPortrait = window.innerHeight > window.innerWidth;

        const rankinginfoElement = document.getElementById("rankinginfo_crbn");
        if (isPortrait) {
          document.getElementById("betpannelcarebbeancover").classList.add("PlayerCardpannelcovercrbn");
          if (rankinginfoElement) {
            if (this.state.isMobilePortraitLndsp) {
              rankinginfoElement.style.left = "35%";
            }
          }

          if (this.resetAnimationChips) {

            if (document.getElementById("chipcentercarebean")) {
              gsap.to("#chipcentercarebean", { x: 13, y: 1200, duration: 0.00001, zIndex: -2, opacity: 0 });
            }

            if (document.getElementById("betchipsaddtobetcaribn")) {
              gsap.to("#betchipsaddtobetcaribn", { x: 1, y: 76, duration: 0.00001, zIndex: -2, opacity: 0 });
              gsap.to("#betTextaddtoBetcrbn", { x: 1, y: 76, duration: 0.5, zIndex: -2, opacity: 0 });
            }




            // }
            if (this.wanttobuydelercrd === true) {
              gsap.to(this.buyDealerCardDrawn.current, { x: 0, y: 0, duration: 0.00001, zIndex: -2, opacity: 0 });
            }
          }

          this.resetAnimationChips = false;
          // bnsactived.classList.add("bnsactivePortrait");
          // bnsactived.classList.add("bnsblink");

          Object.assign(betbutton.style, carebeanjson.Betbtnportraite);
          betbtntextElement.classList.add('betportraitcrbn');
          Object.assign(foldbutton.style, carebeanjson.foldbtnportraite);
          foldtextElement.classList.add('foldtextportraitcaribben');



          let rebetstatus = 1;
          if (carebean_bet === "REBETX2") {
            rebetstatus = 2;
          }
          let totalBetAmountForLimitsCheck = 0;
          let totalAnteBetAmount = 0;
          if (carebean_bet == "REBETX2" || carebean_bet == "REBET") {


            if (Number((Number(this.state.previousAnteBetAmount * rebetstatus)).toFixed(2)) > Number(this.props.balance) ||
              Number((Number(this.state.previousAnteBetAmount * 3 * rebetstatus)).toFixed(2)) > Number(this.props.balance)) {
              // console.log("this.state.anteBetAmount :------", this.state.previousAnteBetAmount, this.state.previousBonusbetamount);
              this.setState({
                anteBetAmount: 0,
                bonusbetamount: 0,
                totalbet: 0,
              })
              // if (Number(this.state.previousAnteBetAmount)  + Number(this.state.previousBonusbetamount) > 39) {

              // if (Number(this.state.previousAnteBetAmount * 3 * rebetstatus) + Number(this.state.previousBonusbetamount * rebetstatus) > this.props.balance) {
              //   this.setState({ betshigh_balnce: false, multibuttonsebledsble: true, disablemultibutton: true })
              // } else if (Number(this.state.previousAnteBetAmount * rebetstatus) + Number(this.state.previousBonusbetamount * rebetstatus) > Number(this.props.balance)) {
              //   this.setState({ betshigh_balnce: true, multibuttonsebledsble: true, disablemultibutton: true })
              // }

              // betbutton.src ="" ;
              // foldbutton.src ="" ;
              // betbtntextElement.className ="";
              // foldtextElement.className ="";
              console.log(Number(Number(this.state.previousAnteBetAmount * rebetstatus).toFixed(2)))
              if (Number(Number(this.state.previousAnteBetAmount * rebetstatus).toFixed(2)) > Number(this.props.balance)) {


                // betbutton.src = "";
                // foldbutton.src = "";
                // betbutton.style.cssText = "";
                // foldbutton.style.cssText = "";
                // betbtntextElement.className = "";
                // foldtextElement.className = "";
                // betbtntextElement.textContent = "";
                // foldtextElement.textContent = "";


                // this.setState({
                //   betshigh_balnce: true,
                //   lowBalaceErrorInfo: `Insufficient Funds to place the ANTE of ${(this.state.previousAnteBetAmount * rebetstatus).toFixed(1)},Please DEPOSIT to continue playing.`
                // })

                this.setState({
                  betshigh_balnce: true,
                  lowBalaceErrorInfo: `Insufficient Funds to place the ANTE of ${(this.state.previousAnteBetAmount * rebetstatus).toFixed(1)},Please DEPOSIT to continue playing`
                })


                // this.setState({ betshigh_balnce: true, multibuttonsebledsble: true, disablemultibutton: true })
              } else if (Number((Number(this.state.previousAnteBetAmount * 3 * rebetstatus)).toFixed(2)) > this.props.balance) {
                // this.setState({
                //   betshigh_balnce: true,
                //   // betshigh_balnce: false,
                //   lowBalaceErrorInfo: `Insufficient Funds to follow up your ANTE of ${(this.state.previousAnteBetAmount * rebetstatus).toFixed(1)},with bet ${(this.state.previousAnteBetAmount * rebetstatus * 2).toFixed(1)} Please DEPOSIT to continue playing.`
                // })

                this.setState({
                  betshigh_balnce: true,
                  lowBalaceErrorInfo: `Insufficient Funds to follow up your ANTE of ${(this.state.previousAnteBetAmount * rebetstatus).toFixed(1)},with BET ${(this.state.previousAnteBetAmount * rebetstatus * 2).toFixed(1)} Please DEPOSIT to continue playing.`
                })

              }

              betbtnElement.classList.remove("addpointercrbn")
              betbtnElement.classList.add("drwnodrop");
              popupbetGreaterthanBalnceElement.className = "";
              gsap.fromTo(popupbetGreaterthanBalnceElement, { scale: 0, rotate: 0, opacity: 0 },
                { scale: 1, duration: 1, rotate: 720, opacity: 1, ease: "power2.out" });

              if (popupbetGreaterthanBalnceElement && textbetsHighElement && attentionElement) {

                popupbetGreaterthanBalnceElement.classList.add("popupbetshighPortrait_crbn");
                textbetsHighElement.classList.add("poptextBetshighPortraitcrbn");
                attentionElement.classList.add("attentionTextPortraitcrbn");

              }

            }
            // else if(this.state.anteBetAmount < this.props.minBetAmt || 
            //           this.state.anteBetAmount > this.props.maxBetAmt) {
            else if (Number((this.state.previousAnteBetAmount * rebetstatus).toFixed(2)) > this.props.maxBetAmt) {
             
              this.setState({
                infoText: this.props.language.MinBetAmt + " " + this.props.minBetLbl + " " + this.props.language.MaxBetAmt + " " + this.props.maxBetLbl,
                anteBetAmount: 0,
                bonusbetamount: 0,
                totalbet: 0,
                // multibuttonsebledsble: true,
                disablemultibutton: false,
              })
              document.getElementById("mobileAntiMaskCover_crbn").style.display = "none";

            } else {
              // this.setState({ multibuttonsebledsble: false });
              // bnsactived.src = bnsactive;
              this.previousantebonousamount(carebean_bet);
              console.log(this.state.anteBetAmount)
              const buybutton = document.getElementById("undobutton");
              document.getElementById("undotext").classList.remove("undotext_lndspe");
              document.getElementById("undotext").classList.add("undotext_portraite");
              buybutton.src = Buybtnwindows;

              document.getElementById("undotext").textContent = "UNDO";
              Object.assign(buybutton.style, carebeanjson.Buybtnportraite);
              betbtntextElement.textContent = this.props.language.Deal;
              foldtextElement.textContent = this.props.language.RP_Clear;

              // console.log(betbtntextElement.textContent, foldtextElement.textContent, this.props.language.Deal, this.props.language.RP_Clear)
              // let convrAntebt = this.topLable_rsp(totalAnteBetAmount);
              // this.playPokerChipSound(this.pokerchipsnd);
              this.state.previousBets.push(Number(this.state.previousAnteBetAmount * rebetstatus))
              this.setState({
                disablemultibutton: false,
                infoText: this.props.language.deal_Game,
                betchipincrementbtn: false,
                betdecreamentbtn: false,
                bsbtn: false,
                betbutton: "DEAL",
                buttontext: "clear",
                // storeantebetchips: [...this.state.prevStatestoreantebetchips],
                // storebonuschips: [...this.state.prevStatestorebonuschips],

                storeantebetchips: [
                  {
                    chip: this.ReturnChipBasedAmount(Number(this.state.previousAnteBetAmount * rebetstatus)),
                    chipvalue: this.topLable_rsp(Number(this.state.previousAnteBetAmount * rebetstatus)),
                    chipcolor1: "valtextPortrait1_crbn"
                  },

                ],
                storebonuschips: [
                  {
                    chip: this.ReturnChipBasedAmount(Number(this.state.previousBonusbetamount * rebetstatus)),
                    chipvalue: this.topLable_rsp(Number(this.state.previousBonusbetamount * rebetstatus)),
                    chipcolor1: "insuranceTextPortraitChip10"
                  },

                ],

              }, () => {

                // for (let k = 1; k <= this.chipVal.length; k++) {
                //   if (this.state.count === k) {
                //     totalBetAmountForLimitsCheck = this.state.totalbet + Number(this.chipVal[k - 1].val);
                //     if (carebean_bet == "REBETX2") {
                //       let chipcenterpositionElement = document.querySelectorAll("#chipcentercarebean span");
                //       let chip10centerbnsElement = document.querySelectorAll("#chip10centerbns span");
                //       for (let a = 0; a < chipcenterpositionElement.length; a++) {
                //         chipcenterpositionElement[a].innerHTML = this.topLable_rsp(this.state.previousAnteBetAmount * 2);
                //       }
                //       // if(this.state.previousBonusbetamount > 0){
                //       for (let b = 0; b < chip10centerbnsElement.length; b++) {
                //         chip10centerbnsElement[b].innerHTML = this.topLable_rsp(this.state.previousBonusbetamount * 2);
                //       }

                //       // }


                //       totalAnteBetAmount = (this.state.previousAnteBetAmount + Number(this.chipVal[k - 1].val)) * 2;
                //       rebetstatus = 2;
                //       // document.querySelector('antebetChipPortrait10').innerHTML = totalAnteBetAmount
                //     } else {
                //       totalAnteBetAmount = this.state.previousAnteBetAmount + Number(this.chipVal[k - 1].val);
                //       rebetstatus = 1;
                //     }
                //   }
                // }



                // document.getElementById("bonustext").style.color = "#ffffff";
                if (document.getElementById('chipcentercarebean')) {
                  gsap.to("#chipcentercarebean", {
                    x: 13, y: 1200, duration: 0.0001, zIndex: 0, opacity: 1, onStart: () => {
                      gsap.set("#chipcentercarebean", { opacity: 0 });
                    }
                  });
                  gsap.to("#chipcentercarebean", { x: 0, y: 0, duration: 0.2, zIndex: 1, });
                  // gsap.to("#chipcentercarebean", { x: 0, y: 0, duration: 0.5, autoAlpha:1, zIndex: 1, });
                  // gsap.to("#chipcentercarebean", { x: 0, y: -2170, duration: 0.5, opacity: 1, zIndex: 1, });
                  const chipcenterpositionElement = document.querySelectorAll("#chipcentercarebean");
                  // console.log(chipcenterpositionElement)
                  for (let q = 0; q < chipcenterpositionElement.length; q++) {

                    chipcenterpositionElement[q].style.position = 'relative';
                  }

                }
                // console.log(this.state.previousBonusbetamount, typeof (this.state.previousBonusbetamount))

                // if (this.state.previousBonusbetamount > 0 &&Number(this.state.previousBonusbetamount * rebetstatus) <= this.props.maxBetAmt) {
                if (this.state.previousBonusbetamount > 0) {
                  this.setState({ isbonuspattable: true });
                  let tl2 = gsap.timeline();
                  if (document.getElementById("chip10centerbns")) {
                    tl2.to("#chip10centerbns", { x: -170, y: 1200, duration: 0.00001, zIndex: -2, opacity: 1 });
                    tl2.to("#chip10centerbns", {
                      x: 0, y: 0, duration: 0.2, autoAlpha: 1, zIndex: 2, delay: 0.1, onStart: () => {
                        // x: 0, y: 0, duration: 0.5, autoAlpha:1, zIndex: 2, delay: 0.5, onStart: () => {
                        // this.playPokerChipSound(this.pokerchipsnd);
                      }
                    });
                  }


                } else {
                  if (document.getElementById("chip10centerbns")) {
                    gsap.to("#chip10centerbns", { duration: 0.000001, opacity: 0 })
                  }
                  // this.setState({ infoText: this.props.language.bonus_maxbet });

                }
              });


            }
          } else {

            let totalBetAmountForLimitsCheck = 0;
            let totalAnteBetAmount = 0;
            // console.log(this.chipVal)

            for (let k = 1; k <= this.chipVal.length; k++) {
              if (this.state.count === k) {
                totalBetAmountForLimitsCheck = this.state.totalbet + Number(this.chipVal[k - 1].val);
                totalAnteBetAmount = this.state.anteBetAmount + Number(this.chipVal[k - 1].val);

              }
            }


            // console.log("this.state.anteBetAmount :------", totalBetAmountForLimitsCheck,
            //   this.state.totalbet, this.props.minBetAmt, this.props.maxBetAmt,
            //   this.state.anteBetAmount + Number(this.chipVal[this.state.count - 1].val)

            // );


            if (Number((Number(totalAnteBetAmount)).toFixed(2)) > Number(this.props.balance) ||
              Number((totalAnteBetAmount * 3).toFixed(2)) > Number(this.props.balance)) {

              // if (totalAnteBetAmount > 50 || totalAnteBetAmount*3 >50) {

              // if (totalAnteBetAmount * 3 + Number(this.state.bonusbetamount) > this.props.balance) {
              //   this.setState({ betshigh_balnce: false, multibuttonsebledsble: true })
              // } else if (totalAnteBetAmount  + Number(this.state.bonusbetamount) > this.props.balance) {
              //   this.setState({ betshigh_balnce: true, multibuttonsebledsble: true })
              // }
              let amount = 0
              for (let k = 1; k <= this.chipVal.length; k++) {
                if (this.state.count === k) {
                  amount = Number(this.chipVal[k - 1].val);

                }
              }

              if (Number((totalAnteBetAmount).toFixed(2)) > this.props.balance) {
                this.setState({
                  betshigh_balnce: true,
                  multibuttonsebledsble: false,
                  // multibuttonsebledsble: true,
                  lowBalaceErrorInfo: `Insufficient Funds to place the ANTE of ${(this.state.anteBetAmount + amount).toFixed(1)},Please DEPOSIT to continue playing.`
                })


                // betbutton.src = "";
                // foldbutton.src = "";
                // betbutton.style.cssText = "";
                // foldbutton.style.cssText = "";
                // betbtntextElement.className = "";
                // foldtextElement.className = "";
                // betbtntextElement.textContent = "";
                // foldtextElement.textContent = "";


              } else if (Number((totalAnteBetAmount * 3).toFixed(2)) > this.props.balance) {
                this.setState({
                  betshigh_balnce: true,
                  // betshigh_balnce: false,
                  lowBalaceErrorInfo: `Insufficient Funds to follow up your ANTE of ${(this.state.anteBetAmount + amount).toFixed(1)},with BET ${((this.state.anteBetAmount + amount) * 2).toFixed(1)} Please DEPOSIT to continue playing.`
                }) // multibuttonsebledsble: true
              }

              betbtnElement.classList.remove("addpointercrbn")
              betbtnElement.classList.add("drwnodrop");
              popupbetGreaterthanBalnceElement.className = "";

              gsap.fromTo(popupbetGreaterthanBalnceElement, { scale: 0, rotate: 0, opacity: 0, duration: 0.5 },
                { scale: 1, duration: 0.5, rotate: 720, opacity: 1, ease: "power2.out" });

              if (popupbetGreaterthanBalnceElement && textbetsHighElement && attentionElement) {
                popupbetGreaterthanBalnceElement.classList.add("popupbetshighPortrait_crbn");
                textbetsHighElement.classList.add("poptextBetshighPortraitcrbn");
                attentionElement.classList.add("attentionTextPortraitcrbn");

              }

            }
            //  else if(this.state.anteBetAmount < this.props.minBetAmt || 
            //           this.state.anteBetAmount > this.props.maxBetAmt) {
            else if (totalAnteBetAmount > Number(this.props.maxBetAmt)) {
              this.setState({
                infoText: this.props.language.MinBetAmt + " " + this.props.minBetLbl + " " + this.props.language.MaxBetAmt + " " + this.props.maxBetLbl,
                // multibuttonsebledsble: true,
              }, () => {
                // console.log(this.state.infoText)
              });



            } else {
              const buybutton = document.getElementById("undobutton");

              buybutton.src = Buybtnwindows;
              document.getElementById("undotext").classList.remove("undotext_lndspe");
              document.getElementById("undotext").classList.add("undotext_portraite");
              document.getElementById("undotext").textContent = "UNDO";
              Object.assign(buybutton.style, carebeanjson.Buybtnportraite);

              // bnsactived.src = bnsactive;
              // document.getElementById("bonustext").style.color = "#ffffff";
              betbtntextElement.textContent = this.props.language.Deal;
              foldtextElement.textContent = this.props.language.RP_Clear;

              // console.log(betbtntextElement.textContent, foldtextElement.textContent, this.props.language.Deal, this.props.language.RP_Clear)
              this.setState({
                multibuttonsebledsble: false,
                betbutton: "DEAL",
                buttontext: "clear",
                bsbtn: false,
                infoText: this.props.language.deal_Game,
                betchipincrementbtn: false,
                betdecreamentbtn: false,
                previousAnteBetAmount: 0,
                previousBonusbetamount: 0,
              });
              let convrAntebt = this.topLable_rsp(totalAnteBetAmount);

              // antebetpdvcss:"chip-center-position_crbn",
              // antechpimgcss:"chip20centerpositionPortrait_crbn",
              // antespntxtcss:"antebetChipPortrait20_crbn",
              // this.playPokerChipSound(this.pokerchipsnd);


              Object.assign(betchipstoantebet.style, carebeanjson.Betchiptoanteprotrait);
              switch (this.state.count) {
                case 1:
                  this.state.previousBets.push(Number(this.chipVal[0].val))
                  this.setState((prevState) => ({
                    totalbet: prevState.totalbet + Number(this.chipVal[0].val),
                    anteBetAmount: prevState.anteBetAmount + Number(this.chipVal[0].val),
                    betdeductions: prevState.betdeductions + Number(this.chipVal[0].val),
                  }))
                  betchipstoantebet.src = chip_10;
                  this.clertime13 = setTimeout(() => {
                    this.setState((prevState) => ({
                      storeantebetchips: [
                        ...prevState.storeantebetchips,
                        { chip: this.ReturnChipBasedAmount(Number(prevState.anteBetAmount.toFixed(2))), chipvalue: convrAntebt, chipcolor1: "valtextPortrait1_crbn" },

                      ],
                    }));
                  }, 150);
                  break;
                case 2:
                  this.state.previousBets.push(Number(this.chipVal[1].val))
                  this.setState((prevState) => ({
                    totalbet: prevState.totalbet + Number(this.chipVal[1].val),
                    anteBetAmount: prevState.anteBetAmount + Number(this.chipVal[1].val),
                    betdeductions: prevState.betdeductions + Number(this.chipVal[1].val),
                  }))
                  betchipstoantebet.src = chip_25;
                  this.clertime14 = setTimeout(() => {
                    this.setState((prevState) => ({
                      storeantebetchips: [
                        ...prevState.storeantebetchips,
                        { chip: this.ReturnChipBasedAmount(Number(prevState.anteBetAmount.toFixed(2))), chipvalue: convrAntebt, chipcolor1: null },

                      ],
                    }));
                  }, 150);
                  break;
                case 3:
                  this.state.previousBets.push(Number(this.chipVal[2].val))
                  this.setState((prevState) => ({
                    totalbet: prevState.totalbet + Number(this.chipVal[2].val),
                    anteBetAmount: prevState.anteBetAmount + Number(this.chipVal[2].val),
                    betdeductions: prevState.betdeductions + Number(this.chipVal[2].val),
                  }))
                  betchipstoantebet.src = chip_50;
                  this.clertime15 = setTimeout(() => {
                    this.setState((prevState) => ({
                      storeantebetchips: [
                        ...prevState.storeantebetchips,
                        { chip: this.ReturnChipBasedAmount(Number(prevState.anteBetAmount.toFixed(2))), chipvalue: convrAntebt, chipcolor1: null },

                      ],
                    }));
                  }, 150);
                  break;
                case 4:
                  this.state.previousBets.push(Number(this.chipVal[3].val))
                  this.setState((prevState) => ({
                    totalbet: prevState.totalbet + Number(this.chipVal[3].val),
                    anteBetAmount: prevState.anteBetAmount + Number(this.chipVal[3].val),
                    betdeductions: prevState.betdeductions + Number(this.chipVal[3].val),
                  }))
                  betchipstoantebet.src = chip_100;
                  this.clertime16 = setTimeout(() => {
                    this.setState((prevState) => ({
                      storeantebetchips: [
                        ...prevState.storeantebetchips,
                        { chip: this.ReturnChipBasedAmount(Number(prevState.anteBetAmount.toFixed(2))), chipvalue: convrAntebt, chipcolor1: null },

                      ],
                    }));
                  }, 150);
                  break;
                case 5:
                  this.state.previousBets.push(Number(this.chipVal[4].val))
                  this.setState((prevState) => ({
                    totalbet: prevState.totalbet + Number(this.chipVal[4].val),
                    anteBetAmount: prevState.anteBetAmount + Number(this.chipVal[4].val),
                    betdeductions: prevState.betdeductions + Number(this.chipVal[4].val),
                  }))
                  betchipstoantebet.src = chip_500;
                  this.clertime17 = setTimeout(() => {
                    this.setState((prevState) => ({
                      storeantebetchips: [
                        ...prevState.storeantebetchips,
                        { chip: this.ReturnChipBasedAmount(Number(prevState.anteBetAmount.toFixed(2))), chipvalue: convrAntebt, chipcolor1: null },
                      ],
                    }));
                  }, 150);
                  break;
                case 6:
                  this.state.previousBets.push(Number(this.chipVal[5].val))
                  this.setState((prevState) => ({
                    totalbet: prevState.totalbet + Number(this.chipVal[5].val),
                    anteBetAmount: prevState.anteBetAmount + Number(this.chipVal[5].val),
                    betdeductions: prevState.betdeductions + Number(this.chipVal[5].val),
                  }))
                  betchipstoantebet.src = chip_1000;
                  this.clertime17 = setTimeout(() => {
                    this.setState((prevState) => ({
                      storeantebetchips: [
                        ...prevState.storeantebetchips,
                        { chip: this.ReturnChipBasedAmount(Number(prevState.anteBetAmount.toFixed(2))), chipvalue: convrAntebt, chipcolor1: null },
                      ],
                    }));
                  }, 150);
                  break;
                default:
                  break;
              }


              var tl = gsap.timeline();
              tl.to(this.chiprefcarebean.current, {
                x: -198,
                y: -684,
                duration: 0.2,
                // duration: 0.2,
                ease: "power2.inOut",
                zIndex: 12,
                autoAlpha: 1,

              });


              tl.to(this.chiprefcarebean.current, {
                x: 0,
                y: 0,
                duration: 0.0001,
                autoAlpha: 0,
                zIndex: -3,
              });
              tl.play();
            }


          }

        }

        else {




          if (chipsstoredcontainerElement && chipsstoredcontainerElement !== null) {
            chipsstoredcontainerElement.classList.add("chipsstoredcontainerLndsp_crbn");
          }

          if (rankinginfoElement) {
            if (this.state.isMobilePortraitLndsp) {
              // rankinginfoElement.style.left = "62.5%";
              rankinginfoElement.style.left = "43.5%";
            }
          }


          betbutton.style.cssText = "";
          foldbutton.style.cssText = "";
          betbtntextElement.className = "";
          foldtextElement.className = "";

          betbutton.src = Betbtn;
          Object.assign(betbutton.style, carebeanjson.Betbtnlndspe);
          betbtntextElement.classList.add('betLandscapecrbn');

          foldbutton.src = Foldbn;
          Object.assign(foldbutton.style, carebeanjson.foldbtnlndspe);
          foldtextElement.classList.add('foldtextLandscapecrbn');

          let rebetstatus = 1;
          let totalBetAmountForLimitsCheck;
          let totalAnteBetAmount;
          // console.log(this.state.previousAnteBetAmount)
          // console.log(this.state.previousBonusbetamount, bettext)
          if (carebean_bet === "REBETX2") {
            rebetstatus = 2;
          }
          if (carebean_bet === "REBETX2" || carebean_bet === "REBET") {

            document.getElementById("undobtn").style.display = "block";

            if (Number((Number(this.state.previousAnteBetAmount * rebetstatus)).toFixed(2)) > Number(this.props.balance) ||
              Number((Number(this.state.previousAnteBetAmount * 3 * rebetstatus)).toFixed(2)) > this.props.balance) {
              // if (Number(this.state.previousAnteBetAmount)  + Number(this.state.previousBonusbetamount) > 39) {
              // console.log("this.state.anteBetAmount :------", this.state.previousAnteBetAmount, this.state.previousBonusbetamount);
              this.setState({
                anteBetAmount: 0,
                bonusbetamount: 0,
                totalbet: 0,
              })

              // betbutton.src ="" ;
              // foldbutton.src ="" ;
              // betbtntextElement.className ="";
              // foldtextElement.className ="";
              if (Number((Number(this.state.previousAnteBetAmount * rebetstatus)).toFixed(2)) > Number(this.props.balance)) {


                // betbutton.src = "";
                // foldbutton.src = "";
                // betbutton.style.cssText = "";
                // foldbutton.style.cssText = "";
                // betbtntextElement.className = "";
                // foldtextElement.className = "";
                // betbtntextElement.textContent = "";
                // foldtextElement.textContent = "";

                // this.setState({
                //   betshigh_balnce: true,
                //   lowBalaceErrorInfo: `Insufficient Funds to place the ANTE of ${(this.state.previousAnteBetAmount * rebetstatus).toFixed(1)},Please DEPOSIT to continue playing.`
                // })

                this.setState({
                  betshigh_balnce: true,
                  lowBalaceErrorInfo: `Insufficient Funds to place the ANTE of ${(this.state.previousAnteBetAmount * rebetstatus).toFixed(1)},Please DEPOSIT to continue playing`
                })



                // this.setState({ betshigh_balnce: true, multibuttonsebledsble: true, disablemultibutton: true })
              } else if (Number((Number(this.state.previousAnteBetAmount * 3 * rebetstatus)).toFixed(2)) > this.props.balance) {


                this.setState({
                  betshigh_balnce: true,
                  lowBalaceErrorInfo: `Insufficient Funds to follow up your ANTE of ${(this.state.previousAnteBetAmount * rebetstatus).toFixed(1)},with BET ${(this.state.previousAnteBetAmount * rebetstatus * 2).toFixed(1)} Please DEPOSIT to continue playing.`
                })


                // this.setState({ betshigh_balnce: false, multibuttonsebledsble: true, disablemultibutton: true })
              }





              // if (Number(this.state.previousAnteBetAmount * 3 * rebetstatus) + Number(this.state.previousBonusbetamount * rebetstatus) > this.props.balance) {
              //   this.setState({ betshigh_balnce: false, multibuttonsebledsble: true, disablemultibutton: true })
              // } else if (Number(this.state.previousAnteBetAmount * rebetstatus) + Number(this.state.previousBonusbetamount * rebetstatus) > Number(this.props.balance)) {
              //   this.setState({ betshigh_balnce: true, multibuttonsebledsble: true, disablemultibutton: true })
              // }


              betbtnElement.classList.remove("addpointercrbn")
              betbtnElement.classList.add("drwnodrop");
              popupbetGreaterthanBalnceElement.className = "";
              gsap.fromTo(popupbetGreaterthanBalnceElement, { scale: 0, rotate: 0, opacity: 0 },
                { scale: 1, duration: 1, rotate: 720, opacity: 1, ease: "power2.out" });

              if (popupbetGreaterthanBalnceElement && textbetsHighElement && attentionElement) {

                popupbetGreaterthanBalnceElement.classList.add("popupbetshighLndsp_crbn");
                textbetsHighElement.classList.add("poptextBetshighPortraitcrbn");
                attentionElement.classList.add("attentionTextPortraitcrbn");

              }

            }
            // else if(this.state.anteBetAmount < this.props.minBetAmt || 
            //           this.state.anteBetAmount > this.props.maxBetAmt) {
            else if (Number((this.state.previousAnteBetAmount * rebetstatus).toFixed(2)) > this.props.maxBetAmt) {
              this.setState({
                infoText: this.props.language.MinBetAmt + " " + this.props.minBetLbl + " " + this.props.language.MaxBetAmt + " " + this.props.maxBetLbl,
                anteBetAmount: 0,
                bonusbetamount: 0,
                totalbet: 0,
                // multibuttonsebledsble: true,
                disablemultibutton: false,
              })

              document.getElementById("mobileAntiMaskCover_crbn").style.display = "none";

            } else {

              document.getElementById("undobtn").style.display = "block";

              this.previousantebonousamount(carebean_bet);

              Object.assign(betbutton.style, carebeanjson.Betbtnlndspe);
              betbtntextElement.classList.add('betLandscapecrbn');
              betbutton.src = Betbtn;

              foldbutton.src = Foldbn;
              Object.assign(foldbutton.style, carebeanjson.foldbtnlndspe);
              foldtextElement.classList.add('foldtextLandscapecrbn');

              const buybutton = document.getElementById("undobutton");

              buybutton.src = Buybtnwindows;
              document.getElementById("undotext").classList.add("undotext_lndspe");
              document.getElementById("undotext").textContent = "UNDO";
              Object.assign(buybutton.style, carebeanjson.Buybtnlndspe);

              this.state.previousBets.push(Number(this.state.previousAnteBetAmount * rebetstatus))
              // this.setState({ multibuttonsebledsble: false });
              // this.playPokerChipSound(this.pokerchipsnd);
              this.setState({
                disablemultibutton: false,
                betbutton: "DEAL",
                buttontext: "clear",
                bsbtn: false,
                infoText: this.props.language.deal_Game,
                betchipincrementbtn: false,
                betdecreamentbtn: false,
                // storeantebetchips: [...this.state.prevStatestoreantebetchips],
                // storebonuschips: [...this.state.prevStatestorebonuschips],

                storeantebetchips: [
                  {
                    chip: this.ReturnChipBasedAmount(Number(this.state.previousAnteBetAmount * rebetstatus)),
                    chipvalue: this.topLable_rsp(Number(this.state.previousAnteBetAmount * rebetstatus)),
                    chipcolor1: "valtextPortrait1"
                  },

                ],

              }, () => {


                // for (let k = 1; k <= this.chipVal.length; k++) {
                //   if (this.state.count === k) {
                //     totalBetAmountForLimitsCheck = this.state.totalbet + Number(this.chipVal[k - 1].val);
                //     if (bettext === "REBETX2") {
                //       let chipcenterpositionElement = document.querySelectorAll("#chipcenterposition span");
                //       let chip10centerbnsElement = document.querySelectorAll("#chip10centerbns span");
                //       for (let a = 0; a < chipcenterpositionElement.length; a++) {
                //         chipcenterpositionElement[a].innerHTML = this.topLable_rsp(this.state.previousAnteBetAmount * 2);
                //       }
                //       for (let b = 0; b < chip10centerbnsElement.length; b++) {
                //         chip10centerbnsElement[b].innerHTML = this.topLable_rsp(this.state.previousBonusbetamount * 2);
                //       }

                //       totalAnteBetAmount = (this.state.previousAnteBetAmount + Number(this.chipVal[k - 1].val)) * 2;
                //       rebetstatus = 2;
                //       // document.querySelector('antebetChipPortrait10').innerHTML = totalAnteBetAmount
                //     } else {
                //       totalAnteBetAmount = this.state.previousAnteBetAmount + Number(this.chipVal[k - 1].val);
                //       rebetstatus = 1;
                //     }
                //   }
                // }





                if (document.getElementById('chipcentercarebean')) {

                  gsap.fromTo("#chipcentercarebean",
                    { x: 1200, y: 0, autoAlpha: 1, duration: 0.00001 },
                    { x: 0, y: 0, autoAlpha: 1, duration: 0.2, zIndex: 1, ease: "power2.out" },
                  )
                  const chipcenterpositionElement = document.querySelectorAll("#chipcentercarebean");
                  // console.log(chipcenterpositionElement)
                  for (let q = 0; q < chipcenterpositionElement.length; q++) {

                    chipcenterpositionElement[q].style.position = 'relative';
                  }

                }


              });


            }
          } else {


            let totalBetAmountForLimitsCheck = 0;
            let totalAnteBetAmount = 0;
            // console.log(this.chipVal)

            for (let k = 1; k <= this.chipVal.length; k++) {
              if (this.state.count === k) {
                totalBetAmountForLimitsCheck = this.state.totalbet + Number(this.chipVal[k - 1].val);
                totalAnteBetAmount = this.state.anteBetAmount + Number(this.chipVal[k - 1].val);

              }
            }


            // console.log("this.state.anteBetAmount :------", totalBetAmountForLimitsCheck,
            //   this.state.totalbet, this.props.minBetAmt, this.props.maxBetAmt,
            //   this.state.anteBetAmount + Number(this.chipVal[this.state.count - 1].val)

            // );


            if (Number((Number(totalAnteBetAmount)).toFixed(2)) > Number(this.props.balance) ||
              Number((Number(totalAnteBetAmount * 3)).toFixed(2)) > Number(this.props.balance)) {

              // if (totalAnteBetAmount > 50 || totalAnteBetAmount*3 >50) {
              let amount = 0
              for (let k = 1; k <= this.chipVal.length; k++) {
                if (this.state.count === k) {
                  amount = Number(this.chipVal[k - 1].val);

                }
              }
              if (Number((totalAnteBetAmount).toFixed(2)) > this.props.balance) {
                this.setState({
                  betshigh_balnce: true,
                  multibuttonsebledsble: false,
                  // multibuttonsebledsble: true,
                  lowBalaceErrorInfo: `Insufficient Funds to place the ANTE of ${(this.state.anteBetAmount + amount).toFixed(1)},Please DEPOSIT to continue playing.`
                })

                // betbutton.src = "";
                // foldbutton.src = "";
                // betbutton.style.cssText = "";
                // foldbutton.style.cssText = "";
                // betbtntextElement.className = "";
                // foldtextElement.className = "";
                // betbtntextElement.textContent = "";
                // foldtextElement.textContent = "";

              } else if (Number((totalAnteBetAmount * 3).toFixed(2)) > this.props.balance) {
                this.setState({
                  betshigh_balnce: true,
                  lowBalaceErrorInfo: `Insufficient Funds to follow up your ANTE of ${(this.state.anteBetAmount + amount).toFixed(1)},with BET ${((this.state.anteBetAmount + amount) * 2).toFixed(1)} Please DEPOSIT to continue playing.`
                }) // multibuttonsebledsble: true
              }

              betbtnElement.classList.remove("addpointercrbn")
              betbtnElement.classList.add("drwnodrop");
              popupbetGreaterthanBalnceElement.className = "";

              gsap.fromTo(popupbetGreaterthanBalnceElement, { scale: 0, rotate: 0, opacity: 0, duration: 0.5 },
                { scale: 1, duration: 0.5, rotate: 720, opacity: 1, ease: "power2.out" });

              if (popupbetGreaterthanBalnceElement && textbetsHighElement && attentionElement) {
                popupbetGreaterthanBalnceElement.classList.add("popupbetshighLndsp_crbn");
                textbetsHighElement.classList.add("poptextBetshighPortraitcrbn");
                attentionElement.classList.add("attentionTextPortraitcrbn");

              }

            }
            //  else if(this.state.anteBetAmount < this.props.minBetAmt || 
            //           this.state.anteBetAmount > this.props.maxBetAmt) {
            else if (totalAnteBetAmount > Number(this.props.maxBetAmt)) {

              this.setState({
                infoText: this.props.language.MinBetAmt + " " + this.props.minBetLbl + " " + this.props.language.MaxBetAmt + " " + this.props.maxBetLbl,
                // multibuttonsebledsble: true,
              }, () => {
                // console.log(this.state.infoText)
              });
              document.getElementById("mobileAntiMaskCover_crbn").style.display = "none"

            } else {


              betbutton.src = Betbtn;
              Object.assign(betbutton.style, carebeanjson.Betbtnlndspe);
              betbtntextElement.classList.add('betLandscapecrbn');

              foldbutton.src = Foldbn;
              Object.assign(foldbutton.style, carebeanjson.foldbtnlndspe);
              foldtextElement.classList.add('foldtextLandscapecrbn');
              const buybutton = document.getElementById("undobutton");

              buybutton.src = Buybtnwindows;
              document.getElementById("undotext").classList.add("undotext_lndspe");
              document.getElementById("undotext").textContent = "UNDO";
              Object.assign(buybutton.style, carebeanjson.Buybtnlndspe);

              this.setState({
                multibuttonsebledsble: false,
                betbutton: "DEAL",
                buttontext: "clear",
                bsbtn: false,
                infoText: this.props.language.deal_Game,
                betchipincrementbtn: false,
                betdecreamentbtn: false,
                previousAnteBetAmount: 0,
                previousBonusbetamount: 0,
              });
              let convrAntebt = this.topLable_rsp(totalAnteBetAmount);


              var tl = gsap.timeline();
              tl.to(this.chiprefcarebean.current, {
                x: -551,
                y: 109,
                // duration: 0.5,
                duration: 0.2,
                ease: "power2.inOut",
                zIndex: 12,
                autoAlpha: 1,
              });


              tl.to(this.chiprefcarebean.current, {
                autoAlpha: 0,
                x: 0,
                y: 0,
                duration: 0.00001,
                zIndex: -3,
              });
              tl.play();

              // this.playPokerChipSound(this.pokerchipsnd);

              switch (this.state.count) {
                case 1:
                  this.state.previousBets.push(Number(this.chipVal[0].val))
                  this.setState((prevState) => ({
                    totalbet: prevState.totalbet + Number(this.chipVal[0].val),
                    anteBetAmount: prevState.anteBetAmount + Number(this.chipVal[0].val),
                    betdeductions: prevState.betdeductions + Number(this.chipVal[0].val),
                  }))
                  betchipstoantebet.src = chip_10;
                  this.clertime13 = setTimeout(() => {
                    this.setState((prevState) => ({
                      storeantebetchips: [
                        ...prevState.storeantebetchips,
                        { chip: this.ReturnChipBasedAmount(Number(prevState.anteBetAmount.toFixed(2))), chipvalue: convrAntebt, chipcolor1: "valtextPortrait1" },

                      ],
                    }));
                  }, 150);
                  break;
                case 2:
                  this.state.previousBets.push(Number(this.chipVal[1].val))
                  this.setState((prevState) => ({
                    totalbet: prevState.totalbet + Number(this.chipVal[1].val),
                    anteBetAmount: prevState.anteBetAmount + Number(this.chipVal[1].val),
                    betdeductions: prevState.betdeductions + Number(this.chipVal[1].val),
                  }))
                  betchipstoantebet.src = chip_25;
                  this.clertime14 = setTimeout(() => {
                    this.setState((prevState) => ({
                      storeantebetchips: [
                        ...prevState.storeantebetchips,
                        { chip: this.ReturnChipBasedAmount(Number(prevState.anteBetAmount.toFixed(2))), chipvalue: convrAntebt, chipcolor1: null },

                      ],
                    }));
                  }, 150);
                  break;
                case 3:
                  this.state.previousBets.push(Number(this.chipVal[2].val))
                  this.setState((prevState) => ({
                    totalbet: prevState.totalbet + Number(this.chipVal[2].val),
                    anteBetAmount: prevState.anteBetAmount + Number(this.chipVal[2].val),
                    betdeductions: prevState.betdeductions + Number(this.chipVal[2].val),
                  }))
                  betchipstoantebet.src = chip_50;
                  this.clertime15 = setTimeout(() => {
                    this.setState((prevState) => ({
                      storeantebetchips: [
                        ...prevState.storeantebetchips,
                        { chip: this.ReturnChipBasedAmount(Number(prevState.anteBetAmount.toFixed(2))), chipvalue: convrAntebt, chipcolor1: null },

                      ],
                    }));
                  }, 150);
                  break;
                case 4:
                  this.state.previousBets.push(Number(this.chipVal[3].val))
                  this.setState((prevState) => ({
                    totalbet: prevState.totalbet + Number(this.chipVal[3].val),
                    anteBetAmount: prevState.anteBetAmount + Number(this.chipVal[3].val),
                    betdeductions: prevState.betdeductions + Number(this.chipVal[3].val),
                  }))
                  betchipstoantebet.src = chip_100;
                  this.clertime16 = setTimeout(() => {
                    this.setState((prevState) => ({
                      storeantebetchips: [
                        ...prevState.storeantebetchips,
                        { chip: this.ReturnChipBasedAmount(Number(prevState.anteBetAmount.toFixed(2))), chipvalue: convrAntebt, chipcolor1: null },

                      ],
                    }));
                  }, 150);
                  break;
                case 5:
                  this.state.previousBets.push(Number(this.chipVal[4].val))
                  this.setState((prevState) => ({
                    totalbet: prevState.totalbet + Number(this.chipVal[4].val),
                    anteBetAmount: prevState.anteBetAmount + Number(this.chipVal[4].val),
                    betdeductions: prevState.betdeductions + Number(this.chipVal[4].val),
                  }))
                  betchipstoantebet.src = chip_500;
                  this.clertime17 = setTimeout(() => {
                    this.setState((prevState) => ({
                      storeantebetchips: [
                        ...prevState.storeantebetchips,
                        { chip: this.ReturnChipBasedAmount(Number(prevState.anteBetAmount.toFixed(2))), chipvalue: convrAntebt, chipcolor1: null },

                      ],
                    }));
                  }, 150);
                  break;
                case 6:
                  this.state.previousBets.push(Number(this.chipVal[5].val))
                  this.setState((prevState) => ({
                    totalbet: prevState.totalbet + Number(this.chipVal[5].val),
                    anteBetAmount: prevState.anteBetAmount + Number(this.chipVal[5].val),
                    betdeductions: prevState.betdeductions + Number(this.chipVal[5].val),
                  }))
                  betchipstoantebet.src = chip_1000;
                  this.clertime17 = setTimeout(() => {
                    this.setState((prevState) => ({
                      storeantebetchips: [
                        ...prevState.storeantebetchips,
                        { chip: this.ReturnChipBasedAmount(Number(prevState.anteBetAmount.toFixed(2))), chipvalue: convrAntebt, chipcolor1: null },

                      ],
                    }));
                  }, 150);
                  break;
                default:
                  break;
              }
              console.log(this.state.previousBets)
              Object.assign(betchipstoantebet.style, carebeanjson.betchipantelndspe);
            }
          }

        }
      }


    }
    else {

      if (
        navigator.userAgentData &&
        navigator.userAgentData.platform &&
        navigator.userAgentData.platform == "Windows"
      ) {

        this.cardsRef.current.ReBet();
        this.dealerCardsContainer.current.ReBet();
        document.getElementById("betbtntext").textContent = "";
        document.getElementById("foldtextcrbn").textContent = "";
        document.getElementById("betbtn").classList.remove("addpointercrbn")
        document.getElementById("betbtn").classList.add("bnspoiner")
        document.getElementById("betbtntext").textContent = this.props.language.Deal;
        document.getElementById("foldtextcrbn").textContent = this.props.language.RP_Clear;
        this.setState({
          muteunmutesounds: true
        })
      } else {

        this.setState({
          disablemultibutton: true,
        })

      }

      this.setState({

        infoText: this.props.language.MinBetAmt + " " + this.props.minBetLbl + " " + this.props.language.MaxBetAmt + " " + this.props.maxBetLbl
      })

    }



  }


  previousantebonousamount(bettext) {
    this.setState({showpayouts:false})

    if (
      navigator.userAgentData &&
      navigator.userAgentData.platform &&
      navigator.userAgentData.platform == "Windows"

    ) {
      this.setState({ multibuttonsebledsble: false, infoText: this.props.language.deal_Game })
      setTimeout(() => {
        this.setState({ infoText: "" })
      }, 20)


    }
    let rebetamount = 0;
    let rebetorrebetX2 = 1;
    if (bettext == "REBET") {
      //  alert("................"+this.state.previousAnteBetAmount)
      rebetorrebetX2 = 1;
      // console.log(bettext)
      rebetamount = this.state.previousAnteBetAmount
      // this.setState({ totalbet: rebetamount }, () => {
      //   this.balnce = this.topLable_rsp(Math.floor(this.props.balance - rebetamount));
      // })
      this.setState({
        totalbet: rebetamount,
        anteBetAmount: this.state.previousAnteBetAmount,

        betdeductions: this.state.previousAnteBetAmount,

      })

      // console.log("rebet    : ", this.state.anteBetAmount, this.state.bonusbetamount, this.state.previousAnteBetAmount, this.state.previousBonusbetamount);

    }
    // console.log("this.state.previousAnteBetAmount", this.state.previousAnteBetAmount,
    //   "this.state.previousBonusbetamount", this.state.previousBonusbetamount
    // );
    else if (bettext == "REBETX2") {
      rebetorrebetX2 = 2;
      let rebetdouble = (this.state.previousAnteBetAmount) * 2;
      rebetamount = rebetdouble;
      this.setState((prevState) => ({
        totalbet: rebetamount,
        anteBetAmount: prevState.previousAnteBetAmount * 2,
        betdeductions: (this.state.previousAnteBetAmount) * 2,


      }));
      // this.balnce = this.topLable_rsp(Math.floor(this.props.balance - rebetdouble));
      // console.log("rebetX2    : ", this.state.previousAnteBetAmount, this.state.previousBonusbetamount, this.state.previousAnteBetAmount, this.state.previousBonusbetamount)
    }
  }


  Showoptionsmenu() {
   
    // this.getClickSound();
    this.setState((prevState) => {
      const isMenuOpen = !prevState.isoptionmenu;
      return {
        isoptionmenu: isMenuOpen,
        isactiveoptionsmenu: true,
        ShowLimits: isMenuOpen ? prevState.ShowLimits : false,
        ShowHistory: isMenuOpen ? prevState.ShowHistory : false,
        ShowGamerules: isMenuOpen ? prevState.ShowGamerules : false,
        showpayouts:false
      };
    }, () => {
      // this.setState((prevState) => ({ isoptionmenu:!prevState.isoptionmenu,isactiveoptionsmenu: true }), () => { 
      // this.setState({ isoptionmenu:true,isactiveoptionsmenu: true }, () => {
      // this.setState({ isoptionmenu:true,isactiveoptionsmenu: true }, () => {


      var tl = gsap.timeline();
      if (document.getElementById("optionsmenubutton")) {

        tl.to("#optionsmenubutton", {
          x: 513,
          y: 75,
          duration: 0.5,
          ease: "power2.inOut",
          zIndex: 12,
          autoAlpha: 1,
          // opacity: 1,

        });
      }
      // if (document.getElementById("optiontopmenu")) {

      //   tl.to("#optiontopmenu", {
      //     x: 316,
      //     y: 0,
      //     duration: 0.5,
      //     ease: "power2.inOut",
      //     zIndex: 12,
      //     autoAlpha: 1,
      //     // opacity: 1,

      //   });
      // }


      if (document.getElementById("limitscrbn")) {

        tl.to("#limitscrbn", {
          x: 294,
          y: -5,
          duration: 0.2,
          ease: "power2.inOut",
          zIndex: 12,
          autoAlpha: 1,
          // opacity: 1,
          stagger: 2,

        });
      }

      if (document.getElementById("help_crbn")) {
        tl.to("#help_crbn", {
          x: 294,
          y: -5,
          duration: 0.2,
          ease: "power2.inOut",
          zIndex: 12,
          opacity: 1,
          // opacity: 0.5,
          stagger: 3,

        });
      }
      if (document.getElementById("historytext_crbn")) {
        tl.to("#historytext_crbn", {
          x: 294,
          y: -5,
          duration: 0.2,
          ease: "power2.inOut",
          zIndex: 12,
          autoAlpha: 1,
          // opacity: 1,
          stagger: 4,

        });
      }
      // if (document.getElementById("exitmenu")) {
      //   tl.to("#exitmenu", {
      //     x: 313,
      //     y: -2,
      //     duration: 0.2,
      //     ease: "power2.inOut",
      //     zIndex: 12,
      //     autoAlpha: 1,
      //     // opacity: 1,
      //     stagger: 5,

      //   });
      // }



      tl.play()

      if (
        navigator.userAgentData &&
        navigator.userAgentData.platform &&
        navigator.userAgentData.platform == "Windows"
      ) {

        if (document.getElementById("optionsmenubutton")) {
          document.getElementById("optionsmenubutton").classList.remove("optionsmenu_lndspe_crbn");
        }

        if (document.getElementById("optionsmenubutton")) {
          document.getElementById("optionsmenubutton").classList.remove("optionsmenu_portraite_crbn");
        }



        if (document.getElementById("optionsmenubutton")) {
          document.getElementById("optionsmenubutton").classList.add("optionsmenucrbn");
        }
        // if (document.getElementById("optiontopmenu")) {
        //   document.getElementById("optiontopmenu").classList.add("topmenu");
        // }
        if (document.getElementById("optiontext")) {
          document.getElementById("optiontext").classList.add("optionheadingcrbn");
        }
        if (document.getElementById("limitscrbn")) {
          document.getElementById("limitscrbn").classList.add("limitstext_crbn");
        }
        if (document.getElementById("help_crbn")) {
          document.getElementById("help_crbn").classList.add("helptext_crbn");
        }
        if (document.getElementById("historytext_crbn")) {
          document.getElementById("historytext_crbn").classList.add("historytext_crbn");
        }
        if (document.getElementById("arraow")) {
          document.getElementById("arraow").classList.add("arraowtextcrbn");
        }
        // if (document.getElementById("exitmenu")) {
        //   document.getElementById("exitmenu").classList.add("exitoptionmenu");
        // }


      }
      else {
        if (window.innerHeight > window.innerWidth) {

          if (document.getElementById("optionsmenubutton")) {
            document.getElementById("optionsmenubutton").classList.remove("optionsmenu_lndspe_crbn");
          }


          if (document.getElementById("optionsmenubutton")) {
            document.getElementById("optionsmenubutton").classList.add("optionsmenu_portraite_crbn");
          }
          // if (document.getElementById("optiontopmenu")) {
          //   document.getElementById("optiontopmenu").classList.add("topmenu");
          // }
          if (document.getElementById("optiontext")) {
            document.getElementById("optiontext").classList.add("optionheadingcrbn");
          }
          if (document.getElementById("limitscrbn")) {
            document.getElementById("limitscrbn").classList.add("limitstext_crbn");
          }
          if (document.getElementById("help_crbn")) {
            document.getElementById("help_crbn").classList.add("helptext_crbn");
          }
          if (document.getElementById("historytext_crbn")) {
            document.getElementById("historytext_crbn").classList.add("historytext_crbn");
          }
          if (document.getElementById("arraow")) {
            document.getElementById("arraow").classList.add("arraowtextcrbn");
          }
          // if (document.getElementById("exitmenu")) {
          //   document.getElementById("exitmenu").classList.add("exitoptionmenu");
          // }


        }
        else {
         

          if (document.getElementById("optionsmenubutton")) {
            document.getElementById("optionsmenubutton").classList.remove("optionsmenu_portraite_crbn");
          }

          if (document.getElementById("optionsmenubutton")) {
            document.getElementById("optionsmenubutton").classList.add("optionsmenu_lndspe_crbn");
          }
          // if (document.getElementById("optiontopmenu")) {
          //   document.getElementById("optiontopmenu").classList.add("topmenu");
          // }
          if (document.getElementById("optiontext")) {
            document.getElementById("optiontext").classList.add("optionheadingcrbn");
          }
          if (document.getElementById("limitscrbn")) {
            document.getElementById("limitscrbn").classList.add("limitstext_crbn");
          }
          if (document.getElementById("help_crbn")) {
            document.getElementById("help_crbn").classList.add("helptext_crbn");
          }
          if (document.getElementById("historytext_crbn")) {
            document.getElementById("historytext_crbn").classList.add("historytext_crbn");
          }
          if (document.getElementById("arraow")) {
            document.getElementById("arraow").classList.add("arraowtextcrbn");
          }
          // if (document.getElementById("exitmenu")) {
          //   document.getElementById("exitmenu").classList.add("exitoptionmenu");
          // }


        }

      }
    });





  }


  ShowLimits() {
    this.setState({ ShowLimits: !this.state.ShowLimits, ShowGamerules: false, ShowHistory: this.state.ShowHistory ? false : this.state.ShowHistory }, () => {

      // exitLimits
      const exitLimitsElement = document.getElementById("exitLimits");
      // exitLimitsElement.className = "";
      if (
        navigator.userAgentData &&
        navigator.userAgentData.platform &&
        navigator.userAgentData.platform == "Windows"
      ) {

        document.getElementById("limitscrbnpopup")?.classList.remove("LimitsPopup_landspecrbn")
        document.getElementById("limitscrbnpopup")?.classList.remove("LimitsPopup_portraitecrbn")
        document.getElementById("limitscrbnpopup")?.classList.add("LimitsPopupcrbn");


        // exitLimitsElement.classList.add("exihstrybuttonportraite");
      }
      else {
        if (window.innerHeight > window.innerWidth) {
          document.getElementById("limitscrbnpopup")?.classList.remove("LimitsPopupcrbn")
          document.getElementById("limitscrbnpopup")?.classList.remove("LimitsPopup_landspecrbn")
          document.getElementById("limitscrbnpopup")?.classList.add("LimitsPopup_portraitecrbn")
          // exitLimitsElement.classList.add("exihstrybuttonportraite");

        }
        else {

          document.getElementById("limitscrbnpopup")?.classList.remove("LimitsPopupcrbn")
          document.getElementById("limitscrbnpopup")?.classList.remove("LimitsPopup_portraitecrbn")
          document.getElementById("limitscrbnpopup")?.classList.add("LimitsPopup_landspecrbn")
          // exitLimitsElement.classList.add("exihstrybuttonportraite");

        }
      }

    })

  }


  CaribbeanBet() {
    this.playPokerChipSound(this.pokerchipsnd);
    if (this.state.isoptionmenu === true) {
      this.Showoptionsmenu();
    }
    let doubleamt = this.state.anteBetAmount * 2
    this.setState((prevState) => ({
      betdeductions: prevState.betdeductions + doubleamt,
      totalbet: prevState.totalbet + doubleamt,
      disBetBtn: 1,
      keyboardhandle: false,
      infoText: "",
      showpayouts:false
    }));


    // if (this.state.mutesounds) {
    //   this.pokerchipsnd = new Audio(pokerchipsnd);
    //   this.pokerchipsnd.pause();
    //   this.betrussiansnd.pause();

    // }
    // else {
    //   this.pokerchipsnd = new Audio(pokerchipsnd);
    //   this.pokerchipsnd.play();
    //   this.betrussiansnd.play();

    // }


    const buybutton = document.getElementById("undobutton");
    const betbutton = document.getElementById("betbtn");
    const foldbutton = document.getElementById("Foldbtncrbn");
    let betTextaddtoBetElement = document.getElementById("betTextaddtoBetcrbn");
    let betchipsaddtobetwindows = document.getElementById("betchipsaddtobetcaribn");
    // document.getElementById("betglow").classList.remove("betglow")

    if (Number((this.state.betdeductions + doubleamt).toFixed(2)) <= this.props.balance) {
      document.getElementById("betbtncrbn").style.display = "none"
      document.getElementById("undobtn").style.display = "none";

      if (
        navigator.userAgentData &&
        navigator.userAgentData.platform &&
        navigator.userAgentData.platform === "Windows"
      ) {
        const chipDetails = [
          { src: chip_10, className: "bet-text-addtoBet10crbn" },
          { src: chip_25, className: "bet-text-addtoBet20crbn" },
          { src: chip_50, className: "bet-text-addtoBet50" },
          { src: chip_100, className: "bet-text-addtoBet100" },
          { src: chip_500, className: "bet-text-addtoBet500" },
          { src: chip_1000, className: "carebeanantechip_500" },
        ];


        document.getElementById("undotext").textContent = "";
        document.getElementById("foldtextcrbn").textContent = "";
        document.getElementById("betbtntext").classList.remove("betwindowscrbn");
        betbutton.src = "";
        document.getElementById("betbtntext").style.cssText = ""


        foldbutton.src = "";
        foldbutton.style.cssText = ""
        document.getElementById("foldtextcrbn").classList.remove("foldtextwindowscrbn");



        buybutton.src = "";
        buybutton.style.cssText = ""


        let betTextaddtoBetClass = ""
        const chipData = chipDetails[this.state.count - 1];
        let Caribbeanbet = this.state.anteBetAmount * 2

        if (chipData) {
          betchipsaddtobetwindows.src = this.ReturnChipBasedAmount(Number(Caribbeanbet.toFixed(2)));

          const russianbet_amount = this.topLable_rsp(Caribbeanbet);
          betTextaddtoBetElement.textContent = russianbet_amount;

          betTextaddtoBetClass = chipData.className;
          betTextaddtoBetElement.classList.add(betTextaddtoBetClass, "bet-text-addtoBet_carr");

        }
        Object.assign(betchipsaddtobetwindows.style, carebeanjson.Betchiptobetwindows)
        gsap.to(this.chipbetref.current, {
          x: -851,
          y: -144,
          duration: 0.1,
          ease: "power2.inOut",
          zIndex: 2,
          opacity: 1,
          onComplete: () => {
            gsap.to("#betTextaddtoBetcrbn", { zIndex: 2, opacity: 1 })


          }
        });



        // gsap.to(this.chipbetref.current, {
        //   x: 1130,
        //   y: 170,
        //   duration: .1,
        //   ease: "power2.inOut",
        //   zIndex: 2,
        //   opacity: 1
        // });




        // this.clertime36=setTimeout(() => {
        //   betTextaddtoBetElement.classList.add(betTextaddtoBetClass,  "bet-text-addtoBet_carr");
        //   gsap.to(betTextaddtoBetElement, {
        //     zIndex:7
        //    });
        // },  250)
        // Object.assign(betchipsaddtobetwindows.style,carebeanjson.Betchiptobetwindows)

      }



      else {

       

        let betTextaddtoBetElement = document.getElementById("betTextaddtoBetcrbn");
        let betchipsaddtobetwindows = document.getElementById("betchipsaddtobetcaribn");
        let betTextaddtoBetClass = "bet-text-addtoBet20crbn";
        // document.getElementById("drawtext").textContent = "";
        // document.getElementById("buytext").textContent = "";
        document.getElementById("foldtextcrbn").textContent = "";
        betbutton.src = "";
        betbutton.style.cssText = "";
        document.getElementById("betbtntext").classList.remove("betwindowscrbn");
        document.getElementById("betbtntext").textContent = "";
        betbutton.style.position = "";
        betbutton.style.width = "";
        betbutton.style.transform = "";

        foldbutton.src = "";
        foldbutton.style.cssText = "";
        document.getElementById("foldtextcrbn").classList.remove("foldtextwindowscrbn");
        foldbutton.style.position = "";
        foldbutton.style.width = "";
        foldbutton.style.transform = "";



        buybutton.src = "";
        buybutton.style.cssText = "";
        buybutton.style.position = "";
        buybutton.style.width = "";
        buybutton.style.transform = "";


        // document.getElementById("betglow").src = "";
        // document.getElementById("betglow").className = "";
        document.getElementById("mobileAntiMaskCover_crbn").style.display = "block";
        this.setState({ bsbtn: true })

        // let doubleamt = this.state.totalbet * 2;
        let convrBet = this.topLable_rsp(this.state.anteBetAmount * 2);

        betchipsaddtobetwindows.src = this.ReturnChipBasedAmount(Number((this.state.anteBetAmount * 2).toFixed(2)));
        switch (this.state.count) {
          case 1:
            // betchipsaddtobetwindows.src = chip_10;
            // betTextaddtoBetElement.textContent = 10;
            betTextaddtoBetElement.textContent = convrBet;
            betTextaddtoBetClass = "bet-text-crbnBet10";
            break;
          case 2:
            // betchipsaddtobetwindows.src = chip_25;
            betTextaddtoBetElement.textContent = convrBet;
            break;
          case 3:
            // betchipsaddtobetwindows.src = chip_50;
            betTextaddtoBetElement.textContent = convrBet;
            break;
          case 4:
            // betchipsaddtobetwindows.src = chip_100;
            betTextaddtoBetElement.textContent = convrBet;
            break;
          case 5:
            // betchipsaddtobetwindows.src = chip_500;
            betTextaddtoBetElement.textContent = convrBet;
            break;
          case 6:
            // betchipsaddtobetwindows.src = chip_1000;
            betTextaddtoBetElement.textContent = convrBet;
            break;

          default:
            betTextaddtoBetClass = "bet-text-crbn20"
            break;
        }
        this.betchipactivated = true;

        if (window.innerHeight > window.innerWidth) {
          document.getElementById("betpannelcarebbeancover").style.display = "block";
          betTextaddtoBetElement.className = "";
          betTextaddtoBetElement.style.cssText = "";
          betTextaddtoBetElement.classList.add(betTextaddtoBetClass, "bet-text-portrait_crbn");
          Object.assign(betchipsaddtobetwindows.style, carebeanjson.BetchiptobetPortrait);
          gsap.to(this.chipbetref.current, {
            // x: -10,
            // y: -950,
            x: 156,
            y: -582,
            duration: 0.3,
            ease: "power2.inOut",
            zIndex: 2,
            autoAlpha: 1,
            opacity: 1,
          });


          gsap.to(betTextaddtoBetElement, {
            // x: 12,
            // y: -1739,
            x: -3,
            y: -1710,
            duration: 0.3,
            ease: "power2.inOut",
            zIndex: 2,
            opacity: 1,
            visibility: true,
            onStart: function () {
              betTextaddtoBetElement.style.zIndex = 2;
              betTextaddtoBetElement.style.visibility = 'visible';
            }
          });

          if (this.playerWinBonus === true) {
            if (document.getElementById("chip10centerbns")) {
              let ml = gsap.timeline()
              ml.to("#chip10centerbns", { x: 0, y: -900, duration: 0.2, autoAlpha: 1 })
              ml.to("#chip10centerbns", { x: -170, y: 1200, duration: 0.00001, zIndex: -2, autoAlpha: 0 });
              this.playerWinBonus = false;
            }
          }




        }

        else {
          document.getElementById("betpannelcarebbeancover").style.display = "block";
          betTextaddtoBetElement.className = "";
          betTextaddtoBetElement.style.cssText = "";
          betTextaddtoBetElement.classList.add(betTextaddtoBetClass, "bet-text-lndsp_crbn");
          Object.assign(betchipsaddtobetwindows.style, carebeanjson.BetchiptobetLndsp);
          gsap.to(this.chipbetref.current, {

            // x: -27,
            // y: -925,

            x: -530,
            y: -189,
            duration: 0.3,
            ease: "power2.inOut",
            zIndex: 2,
            autoAlpha: 1
          });


          gsap.to(betTextaddtoBetElement, {
            // x: 12,
            // y: -1739,
            x: -583,
            y: -168,
            duration: 0.3,
            ease: "power2.inOut",
            zIndex: 2,
            autoAlpha: 1,
            onStart: function () {
              betTextaddtoBetElement.style.zIndex = 2;
              betTextaddtoBetElement.style.visibility = 'visible';
            }
          });



        }

      }


      const body = {
        sessionId: sessionStorage.getItem("sessionId"),
        CN: "CARIBBEAN_POKER_BET",
        object: {
          action: "BET",
          gameId: this.props.gameId,
          playerId: Number(this.props.playerId),
          handId: Number(this.props.gameState.handId)
        },
      };
      console.log(body);
      this.props.network.sendof(body);
      // const body2 = {
      //   CN: "GET_PLAYER_INFO",
      //   object: {
      //     gameId: Number(this.props.gameId,),
      //     playerId: Number(this.props.playerId),

      //   },
      //   sessionId: sessionStorage.getItem('sessionId'),
      // }
      // this.props.network.sendof(body2);
      // this.body2 = {};
    }

    else {

      const popupbetGreaterthanBalnceElement = document.getElementById("popupbet_greaterthanbalncecrbn");
      const textbetsHighElement = document.getElementById("textbets_highcrbn");
      const attentionElement = document.getElementById("attentioncrbn");

      popupbetGreaterthanBalnceElement.className = "";
      textbetsHighElement.className = "";
      attentionElement.className = "";
      this.setState({
        betshigh_balnce: true,
        lowBalaceErrorInfo: `Insufficient Funds to place the BET of ${doubleamt.toFixed(1)},Please DEPOSIT to continue playing.`
      })
      gsap.fromTo(popupbetGreaterthanBalnceElement, { scale: 0, rotate: 0, opacity: 0, duration: 0.5 },
        { scale: 1, duration: 0.5, rotate: 720, opacity: 1, ease: "power2.out" });

      if (
        navigator.userAgentData &&
        navigator.userAgentData.platform &&
        navigator.userAgentData.platform == "Windows"
      ) {

        if (popupbetGreaterthanBalnceElement && textbetsHighElement && attentionElement) {
          popupbetGreaterthanBalnceElement.classList.add("popupbetshighwindowscrbn");
          textbetsHighElement.classList.add("poptextBetshighwindowscrbn");
          attentionElement.classList.add("attentionTextwindows");

        }
      } else {
        if (window.innerHeight > window.innerWidth) {
          if (popupbetGreaterthanBalnceElement && textbetsHighElement && attentionElement) {

            popupbetGreaterthanBalnceElement.classList.add("popupbetshighPortrait_crbn");
            textbetsHighElement.classList.add("poptextBetshighPortraitcrbn");
            attentionElement.classList.add("attentionTextPortraitcrbn");

          }
        } else {
          if (popupbetGreaterthanBalnceElement && textbetsHighElement && attentionElement) {

            popupbetGreaterthanBalnceElement.classList.add("popupbetshighLndsp_crbn");
            textbetsHighElement.classList.add("poptextBetshighPortraitcrbn");
            attentionElement.classList.add("attentionTextPortraitcrbn");

          }
        }
      }

    }
  }


  Exitoptionmenu() {
    this.setState({ isoptionmenu: false });
    var tl = gsap.timeline();
    tl.to("#optionsmenubutton", {
      x: 458,
      y: 75,
      duration: 0.5,
      ease: "power2.inOut",
      zIndex: 12,
      opacity: 1
    });
    tl.play();

  }
  topLable_rsp(value) {

    // // Nine Zeroes for Billions
    // return Math.abs(Number(labelValue)) >= 1.0e+9

    //   ? Math.abs(Number(labelValue)) / 1.0e+9 + "B"
    //   // Six Zeroes for Millions 
    //   : Math.abs(Number(labelValue)) >= 1.0e+6

    //     ? Math.abs(Number(labelValue)) / 1.0e+6 + "M"
    //     // Three Zeroes for Thousands
    //     : Math.abs(Number(labelValue)) >= 1.0e+3

    //       ? Math.abs(Number(labelValue)) / 1.0e+3 + "K"

    //       : Math.abs(Number(labelValue));


    // console.log(value)
    // Nine Zeroes for Billions
    const labelValue = Math.abs(Number(value));
    return labelValue >= 1.0e+9

      ? Number((labelValue / 1.0e+9).toFixed(2)) + "B"
      // Six Zeroes for Millions 
      : labelValue >= 1.0e+6

        ? Number((labelValue / 1.0e+6).toFixed(2)) + "M"
        // Three Zeroes for Thousands
        : labelValue >= 1.0e+3

          ? Number((labelValue / 1.0e+3).toFixed(2)) + "K"

          : Number(labelValue.toFixed(2));
  }
  // disabledrwbutton(){

  //    this.setState({drawbuttonhandle:false})
  //     document.getElementById("drawtext").classList.remove("drawtextopacity");
  // }


  checkpayerordealerwins(res) {

    this.clearallsettimeouts()

    const betbutton = document.getElementById("betbtn");
    const foldbutton = document.getElementById("Foldbtncrbn");

    // let bnsactived = document.getElementById("bnsactive");
    // this.clertime37=setTimeout(() => {


    document.getElementById("betbtn_carebean").classList.add("crbn_nodrop")
    switch (res.winner) {
      case "Dealer":

        this.updateplayerbalnace();


        document.getElementById("foldtextcrbn").classList.remove("insurence");
        document.getElementById("betdecrementcrbn").classList.remove("drwnodrop")
        document.getElementById("betincrenetcrbn").classList.remove("drwnodrop")


        this.setState({ betchipincrementbtn: false, betdecreamentbtn: false })
        // if (this.state.mutesounds) {
        //   this.pokerchipsnd = new Audio(pokerchipsnd);
        //   this.pokerchipsnd.pause();

        // }
        // else {
        //   this.pokerchipsnd = new Audio(pokerchipsnd);
        //   this.pokerchipsnd.play();

        // }

        // gsap.from("#chip10centerbns",{x:1046,y:276,zIndex:-2,duration:1})
        // document.getElementById("betglow").src = "";
        // document.getElementById("carebean_bet").style.color = "";
        // document.getElementById("betglow").classList.remove("betglow");


        let t1 = gsap.timeline()
        t1.to("#chipcentercarebean", {
          x: -3,
          y: -1062,
          duration: .5,
          zIndex: 12,
          delay: 1.5,
          ease: "power2.inOut",
          opacity: 1,
          onComplete: () => {
            gsap.set("#chipcentercarebean", { opacity: 0, zIndex: -2 });
          }
        });
        t1.to("#chipcentercarebean", {
          x: 1128,
          y: 156,
          zIndex: -10,
          opacity: 0,
          duration: .5,


        })


        let t2 = gsap.timeline();
        t2.to("#betchipsaddtobetcaribn", {
          x: -847,
          y: -1207,
          duration: .5,
          zIndex: 12,
          delay: 1.5,
          ease: "power2.inOut",
          opacity: 1,

          onComplete: () => {
            gsap.set("#betchipsaddtobetcaribn", { opacity: 0 });
          }
        });
        t2.to("#betchipsaddtobetcaribn", {
          x: 1128,
          y: 156,
          duration: 0.1,


        });

        gsap.to("#betTextaddtoBetcrbn", { duration: 0.1, delay: 1.5, zIndex: -2, opacity: 0 })



        // gsap.set(["#chipcentercarebean", "#betchipsaddtobetcaribn"], { clearProps: "all" });




        // let centerchips = gsap.timeline();
        // centerchips.to("#centerchips", { 
        //   zIndex: -2, 
        //   opacity: 0, 
        //   onComplete: () => {
        //     document.getElementById("centerchips").removeAttribute("src");
        //     document.getElementById("centerchips").classList.remove("carebeanantechip_10");
        //   }
        // });
        // centerchips.pause();




        setTimeout(() => {
          this.playRemainingSounds(this.playerLose)
          document.getElementById("betbtncrbn").style.display = "block"
          document.getElementById("anteglow").classList.add("anteglow");
          betbutton.src = Betbtn;
          Object.assign(betbutton.style, carebeanjson.Betbtnwindows)

          foldbutton.src = Foldbnwindows;
          Object.assign(foldbutton.style, carebeanjson.foldbtnwindows)

          document.getElementById("foldtextcrbn").textContent = "";

          document.getElementById("betbtntext").textContent = this.props.language.Rebet;
          document.getElementById("betbtntext").classList.add("betwindowscrbn");
          document.getElementById("foldtextcrbn").classList.add("rebettextwindows_crbn");


          document.getElementById("foldtextcrbn").textContent = this.props.language.RebetX2;
      

          // document.getElementById("bonusRussian").classList.remove("addpointercrbn");
          // document.getElementById("bonusRussian").classList.add("drwnodrop");
          document.getElementById("rankinginfodeler_crbn").classList.remove("windowscareabean_dealer");
          document.getElementById("rankinginfo_crbn").className = "";
          document.getElementById("rankinginfodeler_crbn").textContent = "";
          document.getElementById("rankinginfo_crbn").textContent = "";
          this.cardsRef.current.ReBet();
          this.dealerCardsContainer.current.ReBet();
          this.setState({
            previousAnteBetAmount: res.anteBetAmount,
            totalbet: 0,
            totalwinRussian: 0,
            anteBetAmount: 0,
            betbutton: "REBET",
            buttontext: "REBETX2",
            infoText: res.message,

          })

          document.getElementById("betpannelcarebbeancover").style.display = "none";
          document.getElementById("betpannelcarebbeancover").classList.remove("bet_pannelcaribbean_CRBN");
          this.setState({ infoText: this.props.language.Place_antebet, previousAnteBetAmount: res.anteBetAmount, bsbtn: true, disBetBtn: 0, totalbet: 0, totalwinRussian: 0, updateBet: 0, anteBetAmount: 0 })
        }, 4000);


        break;

      //  case `CONGRATULATIONS! You won FUN ${this.state.totalwinRussian}`:



      case "Player":




        const betText = document.querySelector("#betTextaddtoBetcrbn");
        // betText.forEach(el => el.textContent = res.anteWinAmount || "");


        console.log(betText)
        gsap.to("#chipcentercarebean", {
          x: 695,
          y: -391, delay: 1.5, duration: 0.5
        });
        gsap.to("#betchipsaddtobetcaribn", {
          x: -396, y: -535, duration: 0.5, delay: 1.5, onComplete: () => {
            betText.textContent = Number((res.anteWinAmount + res.winAmount).toFixed(2));
          }
        });
        gsap.to("#betTextaddtoBetcrbn", {
          x: 452,
          y: -391, duration: 0.5, delay: 1.5
        });









        setTimeout(() => {
          this.updateplayerbalnace();

          this.updateplayerbalnaceIfplayerWins();

        }, 5000)

        this.Dealerbetstoplayer();


        document.getElementById("foldtextcrbn").textContent = "";

        document.getElementById("betdecrementcrbn").classList.remove("drwnodrop")
        document.getElementById("betincrenetcrbn").classList.remove("drwnodrop")

        document.getElementById("foldtextcrbn").classList.remove("insurence");
        document.getElementById("foldtextcrbn").classList.add("rebettextwindows_crbn");




        if (this.state.mutesounds) {
          this.plyerwin_snd = new Audio(plyerwin_snd);
          this.plyerwin_snd.pause();

        }
        else {
          this.plyerwin_snd = new Audio(plyerwin_snd);
          this.plyerwin_snd.play();

        }
        this.setState(prevState => ({
          previousAnteBetAmount: prevState.anteBetAmount, previousBonusbetamount: prevState.bonusbetamount

        }))


        // gsap.to("#betTextaddtoBetcrbn", { zIndex: -20 })
       
        // alert("..........congratss")
        setTimeout(() => {
          if (document.getElementById("winpopup_crbn")) {
            document.getElementById("winpopup_crbn").classList.add("winpopup_caribbean")
            document.getElementById("winamt_crbn").classList.add("winamt_crbn")
            this.playRemainingSounds(this.playerWin)

          }
          document.getElementById("glowonwinchip_carri").classList.add("chipglow_caribbean")

          this.setState({
            totalwinRussian: res.totalWinAmount,
            betchipincrementbtn: false, betdecreamentbtn: false,
            previousAnteBetAmount: res.anteBetAmount,
            previousBonusbetamount: res.bonusBetAmount,
           
            betbutton: "REBET",
            buttontext: "REBETX2",
            infoText: res.message,
          })
        }, 2500)





        // setTimeout(() => {
        //   betbutton.src = Betbtn;
        //   Object.assign(betbutton.style, carebeanjson.Betbtnwindows)
        //   document.getElementById("betbtntext").textContent = this.props.language.Rebet;

        //   document.getElementById("betbtntext").classList.add("betwindows");

        //   foldbutton.src = Foldbnwindows;
        //   Object.assign(foldbutton.style, carebeanjson.foldbtnwindows)

        //   document.getElementById("foldtext").textContent = this.props.language.RebetX2;
        //   document.getElementById("foldtext").classList.add("rebettextwindows_crbn");

        //   // gsap.to("#betchipsaddtobetcaribn", { x: 27, y: 0, duration: 0 });
        //   if (document.getElementById("winpopup_crbn")) {
        //     document.getElementById("winpopup_crbn").classList.remove("winpopup_caribbean")
        //     document.getElementById("winamt_crbn").classList.remove("winamt_crbn")
        //   }
        //   gsap.to("#chipcentercarebean", { opacity: 0, zIndex: -20 })
        //   gsap.to("#betTextaddtoBetcrbn", { zIndex: -20 })

        //   document.getElementById("glowonwinchip_carri").classList.remove("chipglow_caribbean")
        //   this.setState({ infoText: this.props.language.Place_antebet, disBetBtn: 0, bsbtn: true, totalwinRussian: 0, updateBet: 0, bonusBetAmount: 0 });

        //   // gsap.to("#chipcentercarebean", {
        //   //    x: 955, y: 290, duration: 0.1, opacity: 0,

        //   // });
        //   document.getElementById("rankinginfodeler_crbn").classList.remove("windowscareabean_dealer");

        //   // gsap.to("#betchipsaddtobetcaribn",{zIndex:-2,opacity:0})
        //   gsap.to("#betchipsaddtconfrm", { zIndex: -2 })
        //   gsap.to("#betchipsaddtodraw", { zIndex: -2 })
        //   gsap.to("#chipcentercarebean", { zIndex: -2 })

        //   document.getElementById("rankinginfo_crbn").className = "";
        //   document.getElementById("rankinginfodeler_crbn").textContent = "";
        //   document.getElementById("rankinginfo_crbn").textContent = "";
        //   this.cardsRef.current.ReBet();
        //   this.dealerCardsContainer.current.ReBet();

        //   document.getElementById("betpannelcarebbeancover").classList.remove("bet_pannelcaribbean_CRBN");
        // }, 4000);




        // bnsactived.src = "";
        // bnsactived.classList.remove("bnsctivewindows");
        // bnsactived.classList.remove("bnsblink");

        break;


      default:
        break;
    }
    // }, 3500)
  }


  Showebetchipsgamestatehandle = (res) => {


    let betTextaddtoBetElement = document.getElementById("betTextaddtoBetcrbn");
    let betchipsaddtobetwindows = document.getElementById("betchipsaddtobetcaribn");
    betchipsaddtobetwindows.src = chip_10;
    // alert("............"+betchipsaddtobetwindows)
    betTextaddtoBetElement.textContent = res.betAmount
    betTextaddtoBetElement.classList.add("bet-text-addtoBet_carr")
    gsap.to(this.chipbetref.current, {
      x: -965,
      y: -484,
      duration: 0.3,
      ease: "power2.inOut",
      zIndex: 2,
      opacity: 1
    });
    // document.getElementById("rankinginfodeler_crbn").classList.add("windowscareabean_dealer");
    // document.getElementById("rankinginfo_crbn").classList.add("crbn_playerrank");
    Object.assign(betchipsaddtobetwindows.style, carebeanjson.Betchiptobetwindows)

    this.setState({ infoText: res.message, pokerrankingdeler: res.dealerHand, pokerranking: res.playerHand })
  }


  showcheckpayerordealerwins(res) {

    this.clearallsettimeouts()
    // document.getElementById("insurenceactive").src = "";
    // document.getElementById("insurenceactive").classList.remove("insurenceactivewindows");
    // document.getElementById("insurenceactive").classList.remove("insurenceblink");
    const betbutton = document.getElementById("betbtn");
    const foldbutton = document.getElementById("Foldbtncrbn");


    // this.clertime37=setTimeout(() => {
    this.setState((prevState) => ({
      previousAnteBetAmount: prevState.anteBetAmount,
      previousBonusbetamount: prevState.bonusbetamount,
      totalbet: 0,
      // anteBetAmount: 0,
      // bonusbetamount: 0,
      insurencetbet: 0,

      // betdeductions: 0
    }));




    switch (res.winner) {
      case "Dealer":


        this.updateplayerbalnace();

        document.getElementById("foldtextcrbn").classList.remove("insurence");
        document.getElementById("betdecrementcrbn").classList.remove("drwnodrop")
        document.getElementById("betincrenetcrbn").classList.remove("drwnodrop")
        document.getElementById("betpannelcarebbeancover").style.display = "none";
        document.getElementById("betpannelcarebbeancover").classList.remove("bet_pannelcaribbean_CRBN");

        this.setState({ betchipincrementbtn: false, betdecreamentbtn: false })
        // if (this.state.mutesounds) {
        //   this.pokerchipsnd = new Audio(pokerchipsnd);
        //   this.pokerchipsnd.pause();

        // }
        // else {
        //   this.pokerchipsnd = new Audio(pokerchipsnd);
        //   this.pokerchipsnd.play();

        // }

        // gsap.from("#chip10centerbns",{x:1046,y:276,zIndex:-2,duration:1})
        // document.getElementById("betglow").src = "";
        // document.getElementById("carebean_bet").style.color = "";
        // document.getElementById("betglow").classList.remove("betglow");
        this.setState({
          betbutton: "REBET", previousAnteBetAmount: res.anteBetAmount,
          anteBetAmount: 0,
          bonusbetamount: 0,
          insurencetbet: 0,

          previousBonusbetamount: res.bonusBetAmount,


          buttontext: "REBETX2", disBetBtn: 0, betBtnValue: 2, keyboardhandle: true, totalWinAmount: 0
        });


        gsap.from("#betTextaddtoinsurence", { zIndex: -2 })

        // setTimeout(() => {
        betbutton.src = Betbtn;
        Object.assign(betbutton.style, carebeanjson.Betbtnwindows)
        document.getElementById("betbtntext").textContent = this.props.language.Rebet;
        document.getElementById("betbtntext").classList.add("betwindows_rebetcrbn");




        foldbutton.src = Foldbnwindows;
        Object.assign(foldbutton.style, carebeanjson.foldbtnwindows)
        document.getElementById("foldtextcrbn").textContent = "";
        document.getElementById("foldtextcrbn").textContent = this.props.language.RebetX2;
        document.getElementById("foldtextcrbn").classList.add("rebettextwindows_crbn");
        this.setState({ infoText: this.props.language.Place_antebet, bsbtn: true, disBetBtn: 0, totalwinRussian: 0, updateBet: 0, bonusBetAmount: 0 });

        // document.getElementById("bonusRussian").classList.remove("addpointercrbn");
        // document.getElementById("bonusRussian").classList.add("drwnodrop");
        document.getElementById("rankinginfodeler_crbn").classList.remove("windowscareabean_dealer");
        document.getElementById("rankinginfo_crbn").className = "";
        document.getElementById("rankinginfodeler_crbn").textContent = "";
        document.getElementById("rankinginfo_crbn").textContent = "";
        this.cardsRef.current.ReBet();
        this.dealerCardsContainer.current.ReBet();

        // }, 4000);


        gsap.to("#betchipsaddtconfrm", { zIndex: -2 })
    
       

        gsap.to("#betchipsaddtobetcaribn", { zIndex: -2 })

        // this.showcardsAdBetsParamState(res)

        break;

      //  case `CONGRATULATIONS! You won FUN ${this.state.totalwinRussian}`:
      case "Player":

        this.setState({
          infoText: this.props.language.Place_antebet, anteBetAmount: 0,
          bonusbetamount: 0,

          disBetBtn: 0, bsbtn: true, totalwinRussian: 0, updateBet: 0, bonusBetAmount: 0
        });

        gsap.to("#betTextaddtoinsurence", { zIndex: -2 })
         gsap.to("#betTextaddtoBetcrbn", { zIndex: -20 })


        this.updateplayerbalnace();
        // this.Dealerbetstoplayer()



        document.getElementById("foldtextcrbn").textContent = "";
        document.getElementById("betpannelcarebbeancover").style.display = "none";
        document.getElementById("betdecrementcrbn").classList.remove("drwnodrop")
        document.getElementById("betincrenetcrbn").classList.remove("drwnodrop")

        document.getElementById("foldtextcrbn").classList.remove("insurence");
        document.getElementById("foldtextcrbn").classList.add("rebettextwindows_crbn");




        if (this.state.mutesounds) {
          this.plyerwin_snd = new Audio(plyerwin_snd);
          this.plyerwin_snd.pause();

        }
        else {
          this.plyerwin_snd = new Audio(plyerwin_snd);
          this.plyerwin_snd.play();

        }
        this.setState(prevState => ({
          previousAnteBetAmount: prevState.anteBetAmount, previousBonusbetamount: prevState.bonusbetamount

        }))

        //  gsap.to("#chip10centerbns",{x:-950,y:338,zIndex:-2})


        gsap.to("#betTextaddtoBetcrbn", { zIndex: -20 })
        this.setState({
          betbutton: "REBET", buttontext: "REBETX2",
          keyboardhandle: true,
          totalwinRussian: 0,
          betchipincrementbtn: false, betdecreamentbtn: false,
          previousAnteBetAmount: res.anteBetAmount, previousBonusbetamount: res.bonusBetAmount,

          betBtnValue: 2
        })




        if (document.getElementById("winpopup_crbn")) {
          document.getElementById("winpopup_crbn").classList.add("winpopup_caribbean")
          document.getElementById("winamt_crbn").classList.add("winamt_crbn")

        }
        document.getElementById("glowonwinchip_carri").classList.add("chipglow_caribbean")


        // setTimeout(() => {
        betbutton.src = Betbtn;
        Object.assign(betbutton.style, carebeanjson.Betbtnwindows)
        document.getElementById("betbtntext").textContent = this.props.language.Rebet;
        document.getElementById("betbtntext").classList.add("betwindows_rebetcrbn");



        foldbutton.src = Foldbnwindows;
        Object.assign(foldbutton.style, carebeanjson.foldbtnwindows)

        document.getElementById("foldtextcrbn").textContent = this.props.language.RebetX2;

        document.getElementById("foldtextcrbn").classList.add("rebettextwindows_crbn");

        // gsap.to("#betchipsaddtobetcaribn", { x: 27, y: 0, duration: 0 });
        if (document.getElementById("winpopup_crbn")) {
          document.getElementById("winpopup_crbn").classList.remove("winpopup_caribbean")
          document.getElementById("winamt_crbn").classList.remove("winamt_crbn")
        }
        document.getElementById("glowonwinchip_carri").classList.remove("chipglow_caribbean")


        gsap.to("#betchipsaddtobetcaribn", { zIndex: -2 })
        gsap.to("#betchipsaddtconfrm", { zIndex: -2 })
       
        gsap.to("#chipcentercarebean", { zIndex: -2, opacity: 0 })
        document.getElementById("rankinginfodeler_crbn").classList.remove("windowscareabean_dealer");
        document.getElementById("rankinginfo_crbn").className = "";
        document.getElementById("rankinginfodeler_crbn").textContent = "";
        document.getElementById("rankinginfo_crbn").textContent = "";
        this.cardsRef.current.ReBet();
        this.dealerCardsContainer.current.ReBet();

        // }, 4000);


        document.getElementById("rankinginfodeler_crbn").classList.remove("windowscareabean_dealer");



        break;

      default:
        break;
    }
    // }, 3500)

  }


 







  ifPlayerWinsMobilePortraitAction = (res) => {
    // this.playerWin = new Audio(playerWin);
    // this.playerWin.currentTime = 0;

    const winpopuprspelement = document.getElementById("winpopup_crbn");
    const winamtElement = document.getElementById("winamt_crbn");


    const anteText = document.querySelectorAll("#chipcentercarebean span");
    const betText = document.querySelector("#betTextaddtoBetcrbn");

    const delaerChipAnimDiv = document.getElementById('delaerchipanim_crbn')
    const dealerchipElement = document.getElementById('dealerchip_crbn');


    if (Number(res.anteWinAmount) > Number(res.anteBetAmount)) {
      dealerchipElement.src = this.ReturnChipBasedAmount(Number(res.anteWinAmount));
      Object.assign(dealerchipElement.style, carebeanjson.dealerchipstoplayerPortrait)

      let t1 = gsap.timeline()
      t1.to("#dealerchip_crbn", {
        autoAlpha: 1,
        zIndex: 2,
        x: -445,
        y: 2143,

        duration: 0.3,
        ease: "power2.inOut",
        onComplete: () => {
          gsap.set('#dealerchip_crbn', { opacity: 0, zIndex: -10 })
        },


      });
      t1.to("#dealerchip_crbn", {
        x: -75,
        y: 20,
        duration: 0.002,
        ease: "power2.inOut",
        autoAlpha: 0,
        zIndex: -10

      })
    }

    for (let b = 0; b < anteText.length; b++) {
      anteText[b].textContent = res.anteWinAmount;
    }
    // for(let b=0; b<insuranceText.length; b++){
    //   insuranceText[b].textContent = res.insuranceWinAmount;
    // }
    // betText.textContent = res.winAmount;

    this.clertime52 = setTimeout(() => {
      // this.updateplayerbalnace();

      this.clertime53 = setTimeout(() => {
        this.clearActivesWeDid();


        this.setState((prevState) => ({

          previousAnteBetAmount: res.anteBetAmount,

          prevStatestoreantebetchips: [...prevState.storeantebetchips],


        }));

        // }, 1000);
      }, 3000);

      // console.log(this.state.anteBetAmount, this.state.bonusbetamount);







      delaerChipAnimDiv.className = "";
      dealerchipElement.src = "";
      dealerchipElement.className = "";

      this.resetAnimationChips = true;




      setTimeout(() => {
        // this.playRemainingSounds(this.playerWin)

        // try{

        // if (this.state.mutesounds) {
        //   this.playerWin.pause();
        // } else {
        //   this.playerWin.play()

        // }
        // }catch(error){
        //   console.log(error)
        // }
        this.playRemainingSounds(this.playerWin)

        winpopuprspelement.classList.add("winPopupForMptSw_crbn");
        winamtElement.classList.add("winamt_crbn");
        this.setState({
          infoText: res.message,
          totalwinRussian: res.totalWinAmount,
        }
        )
      }, 1800)



      gsap.fromTo(winpopuprspelement,
        {
          autoAlpha: 0, scale: 0, duration: 0.5,

        },
        {
          autoAlpha: 1, scale: 1, duration: 0.5, delay: 1.8, rotate: 0, onStart: () => {
            setTimeout(() => {
              // winpopuprspelement.classList.add("winPopupForMptSw_crbn");
              // winamtElement.classList.add("winamtMpSw_crbn");
              // this.setState({
              //   infoText: res.message,
              //   totalwinRussian: res.totalWinAmount,
              // })
            }, 1500)

          }
        },
      )

      // document.getElementById("betglow").src = "";
      document.getElementById("carebean_bet").style.color = "";
      // document.getElementById("betglow").classList.remove("betactivelndspe_crbn");

      // let t1 = gsap.timeline()
      gsap.to("#chipcentercarebean", { x: -344, y: 418, duration: 0.5 });
      gsap.to("#betchipsaddtobetcaribn", {
        x: -494, y: -183, duration: 0.5, delay: 1, onComplete: () => {
          betText.textContent = Number((res.anteWinAmount + res.winAmount).toFixed(2));

        }
      });
      gsap.to("#betTextaddtoBetcrbn", { x: -659, y: -1302, duration: 0.5, delay: 1 });



      this.setState({ betbutton: "REBET", buttontext: "REBETX2", disablemultibutton: false, bsbtn: true, betchipincrementbtn: false, betdecreamentbtn: false })




    }, 2000)

    this.resetPyrWnCpsAmn = true;

    setTimeout(() => {
      this.updateplayerbalnace();
      this.updateplayerbalnaceIfplayerWins()
    }, 6000)



  }
  UpdateplayerinfoPlayerWins() {
    const betbutton = document.getElementById("betbtn");
    const foldbutton = document.getElementById("Foldbtncrbn");
    const betbtntextElement = document.getElementById("betbtntext");
    const foldtextElement = document.getElementById("foldtextcrbn");

    const winpopuprspelement = document.getElementById("winpopup_crbn");
    const winamtElement = document.getElementById("winpopup_crbn");


    const anteText = document.querySelectorAll("#chipcentercarebean span");
    const betText = document.querySelector("#betTextaddtoBetcrbn");
    const insuranceText = document.querySelectorAll("#chiptoinsurence span");
    document.getElementById("betbtncrbn").style.display = "block"
    document.getElementById("undobtn").style.display = "block"
    if (
      navigator.userAgentData &&
      navigator.userAgentData.platform &&
      navigator.userAgentData.platform == "Windows"
    ) {
      setTimeout(() => {


        gsap.to("#betTextaddtoBetcrbn", { zIndex: -20 })


        this.setState({
          infoText: this.props.language.Place_antebet,

          totalbet: 0,
          anteBetAmount: 0,
       
  
          disablemultibutton: false,

           totalwinRussian: 0, updateBet: 0
        });

        // gsap.to("#chipcentercarebean", {
        //    x: 955, y: 290, duration: 0.1, opacity: 0,

        // });
        document.getElementById("rankinginfodeler_crbn").classList.remove("windowspokerrankingdeler");


        // document.getElementById("betcircle").classList.remove("nonpointer")

        // gsap.to("#betchipsaddtobetcaribn", { zIndex: -2 })
        // gsap.to("#betchipsaddtconfrm", { zIndex: -2 })
        // gsap.to("#betchipsaddtodraw", { zIndex: -2 })
        // gsap.to("#chipcentercarebean", { zIndex: -2 })



        document.getElementById("rankinginfo_crbn").className = "";
        document.getElementById("rankinginfodeler_crbn").textContent = "";
        document.getElementById("rankinginfo_crbn").textContent = "";
        this.cardsRef.current.ReBet();
        this.dealerCardsContainer.current.ReBet();


        if (document.getElementById("winpopup_crbn")) {
          // document.getElementById("winpopup_crbn").classList.remove("winpopup_russian")
          // document.getElementById("winpopup_crbn").classList.remove("winamt_russian")
        }
        document.getElementById("betbtncrbn").style.display = "block"
        document.getElementById("anteglow").classList.add("anteglow");
        betbutton.src = Betbtn;
        Object.assign(betbutton.style, carebeanjson.Betbtnwindows)
        document.getElementById("betbtntext").textContent = this.props.language.Rebet;

        document.getElementById("betbtntext").classList.add("betwindowscrbn");

        foldbutton.src = Foldbnwindows;
        Object.assign(foldbutton.style, carebeanjson.foldbtnwindows)

        document.getElementById("foldtextcrbn").textContent = this.props.language.RebetX2;
        document.getElementById("foldtextcrbn").classList.add("rebettextwindows_crbn");

        document.getElementById("betpannelcarebbeancover").classList.remove("bet_pannelcaribbean_CRBN");

        // gsap.to("#betchipsaddtobetcaribn", { x: 27, y: 0, duration: 0 });
        if (document.getElementById("winpopup_crbn")) {
          document.getElementById("winpopup_crbn").classList.remove("winpopup_caribbean")
          document.getElementById("winamt_crbn").classList.remove("winamt_crbn")
        }
        gsap.to("#chipcentercarebean", { opacity: 0, zIndex: -20 })
        // gsap.to("#betTextaddtoBetcrbn", { zIndex: -20 })

        document.getElementById("glowonwinchip_carri").classList.remove("chipglow_caribbean")
        this.setState({ infoText: this.props.language.Place_antebet, disBetBtn: 0, bsbtn: true, totalwinRussian: 0, updateBet: 0, bonusBetAmount: 0 });

        // gsap.to("#chipcentercarebean", {
        //    x: 955, y: 290, duration: 0.1, opacity: 0,

        // });
        document.getElementById("rankinginfodeler_crbn").classList.remove("windowscareabean_dealer");

        // gsap.to("#betchipsaddtobetcaribn",{zIndex:-2,opacity:0})
        gsap.to("#betchipsaddtconfrm", { zIndex: -2 })
     
        gsap.to("#chipcentercarebean", { zIndex: -2 })

        document.getElementById("rankinginfo_crbn").className = "";
        document.getElementById("rankinginfodeler_crbn").textContent = "";
        document.getElementById("rankinginfo_crbn").textContent = "";
        this.cardsRef.current.ReBet();
        this.dealerCardsContainer.current.ReBet();
        if (document.getElementById("chipcentercarebean")) {
          gsap.to("#chipcentercarebean", { x: 13, y: 1200, duration: 0.00001, zIndex: -2, opacity: 0 });
        }

        if (document.getElementById("betchipsaddtobetcaribn")) {
          gsap.to("#betchipsaddtobetcaribn", { x: 26, y: -66, duration: 0.00001, zIndex: -2, opacity: 0 });
          gsap.to("#betTextaddtoBetcrbn", { x: 1, y: -4, duration: 0.00001, zIndex: -2, opacity: 0 });
        }


      }, 1000);





    } else {
      document.getElementById("undobtn").style.display = "block"
      document.getElementById("betbtncrbn").style.display = "block"
      // document.getElementById("betglow").src = "";
      // document.getElementById("betglow").classList.remove("betactivePortrait_crbn")
      // document.getElementById("betglow").classList.remove("betactivelndspe_crbn")

      const delaerChipAnimDiv = document.getElementById('delaerchipanim_crbn')
      const dealerchipElement = document.getElementById('dealerchip_crbn');
      // const betglowcrbn = document.getElementById('betglow');
      if (window.innerHeight > window.innerWidth) {
        this.clertime54 = setTimeout(() => {
          document.getElementById("mobileAntiMaskCover_crbn").style.display = "none";
          gsap.fromTo(winpopuprspelement, { scale: 1, duration: 0.5 }, {
            autoAlpha: 0, scale: 0, duration: 0.5, onComplete: () => {
              winpopuprspelement.classList.remove("winPopupForMptSw_crbn");
              winamtElement.classList.remove("winamt_crbn");
            }
          })
          // this.updateplayerbalnace();
          this.setState({
             totalwinRussian: 0,
            totalbet: 0,
            infoText: this.props.language.Play_Njoy,
            storeantebetchips: [],

          });

          betbutton.src = Betbtn;
          // Object.assign(betbutton.style, carebeanjson.Betbtnportraite);
          Object.assign(betbutton.style, this.state.betbuttoncss);

          betbtntextElement.classList.remove('betportraitcrbne');

          betbtntextElement.textContent = this.props.language.Rebet;


          // betbtntextElement.classList.add("betportraitcrbn");
          betbtntextElement.classList.add(`${this.state.betbtntextcss}`);

          // Object.assign(betbtntextElement.style, carebeanjson.betportraitcrbne);

          foldbutton.src = Foldbnwindows;
          // Object.assign(foldbutton.style, carebeanjson.foldbtnportraite);
          Object.assign(foldbutton.style, this.state.foldbuttoncss);

          foldtextElement.classList.remove('insurencePortrait', 'foldtextportraitecrbn', 'foldtextportraitcaribben');
          foldtextElement.textContent = this.props.language.RebetX2;
          // foldtextElement.classList.add("rebettextportraitcrbn");
          foldtextElement.classList.add(`${this.state.foldbtntextcss}`);
          // betglowcrbn.classList.remove("betactivePortrait_crbn")

          document.getElementById("anteglow").classList.add("anteglowportraite");


          if (this.resetAnimationChips) {
            if (document.getElementById("chipcentercarebean")) {
              gsap.to("#chipcentercarebean", { x: 13, y: 1200, duration: 0.00001, zIndex: -2, opacity: 0 });
            }

            if (document.getElementById("betchipsaddtobetcaribn")) {
              gsap.to("#betchipsaddtobetcaribn", { x: 1, y: 76, duration: 0.00001, zIndex: -2, opacity: 0 });
              gsap.to("#betTextaddtoBetcrbn", { x: 1, y: 76, duration: 0.00001, zIndex: -2, opacity: 0 });
            }


          }

          // }, 4000)
        }, 1000)



      } else {


        this.clertime54 = setTimeout(() => {

          delaerChipAnimDiv.className = "";
          dealerchipElement.src = "";
          dealerchipElement.className = "";

          document.getElementById("mobileAntiMaskCover_crbn").style.display = "none";
          document.getElementById("anteglow").classList.remove("anteglowportraite");
          document.getElementById("anteglow").classList.add("anteglowlndspe");

          betbutton.src = Betbtn;
          // Object.assign(betbutton.style, carebeanjson.Betbtnlndspe);
          Object.assign(betbutton.style, this.state.betbuttoncss);
          betbtntextElement.textContent = this.props.language.Rebet;
          // betbtntextElement.classList.add('betLandscapecrbn');
          betbtntextElement.classList.add(`${this.state.betbtntextcss}`);

          foldbutton.src = Foldbnwindows;
          // Object.assign(foldbutton.style, carebeanjson.foldbtnlndspe);
          Object.assign(foldbutton.style, this.state.foldbuttoncss);
          foldtextElement.textContent = this.props.language.RebetX2;
          // foldtextElement.classList.add("foldtextLandscapecrbn");
          foldtextElement.classList.add(`${this.state.foldbtntextcss}`);




          winpopuprspelement.classList.remove("winPopupLand_crbn");
          winamtElement.classList.remove("winamtlnd_crbn");


          this.setState({
            totalbet: 0,
            totalwinRussian: 0,
            infoText: this.props.language.Play_Njoy,
            storeantebetchips: [],

          });





          gsap.to("#chipcentercarebean", { x: 0, y: 0, duration: 0.0001, zIndex: -2, opacity: 0 });
          gsap.to("#betTextaddtoBetcrbn", { x: 0, y: 0, duration: 0.0001, zIndex: -2, opacity: 0 });
          gsap.to("#betchipsaddtobetcaribn", { x: 0, y: 0, duration: 0.0001, zIndex: -2, opacity: 0 });

          gsap.set('#dealerchip_crbn', { x: 0, y: 0, opacity: 0, zIndex: -10, duration: 0.00001 })
          // }, 5000)
        }, 1000)




      }
    }




  }
  updateplayerbalnaceIfplayerWins = () => {
    this.props.gamstateHandle();
    // this.setState({betdeductions:0,noupdateblnce:true})
    const body2 = {
      CN: "GET_UPDATED_PLAYER_INFO",
      object: {
        gameId: Number(this.props.gameId,),
        playerId: Number(this.props.playerId),

      },
      sessionId: sessionStorage.getItem('sessionId'),
    }
    this.props.network.sendof(body2);
    this.body2 = {};

  }
  // updateplayerbalnaceIfplayerWins = () => {
  //   this.props.gamstateHandle();
  //   // this.setState({betdeductions:0,noupdateblnce:true})
  //   const body2 = {
  //     CN: "GET_UPDATED_PLAYER_INFO",
  //     object: {
  //       gameId: Number(this.props.gameId,),
  //       playerId: Number(this.props.playerId),

  //     },
  //     sessionId: sessionStorage.getItem('sessionId'),
  //   }
  //   this.props.network.sendof(body2);
  //   this.body2 = {};

  // }

  // muteunmutesounds() {
  //   // alert("............"+this.state.mutesounds)
  //   if (!this.state.mutesounds) {
  //     // alert("............1"+this.state.mutesounds)
  //     this.setState({ mutesounds: true })
  //     document.getElementById('volumeRussian').src = mutesnds
  //     document.getElementById('volumeRussian').classList.add("addpointercrbn")
  //   }
  //   else {
  //     // alert("............2"+this.state.mutesounds)
  //     this.setState({ mutesounds: false })
  //     document.getElementById('volumeRussian').src = volumeimg
  //     document.getElementById('volumeRussian').classList.add("addpointercrbn")

  //   }
  // }
  muteunmutesounds() {
    // this.getClickSound();

    this.selectCard.currentTime = 0;
    if (this.state.mutesounds) {
      this.selectCard.play();
    } else {
      this.selectCard.pause();
    }

    if (
      navigator.userAgentData &&
      navigator.userAgentData.platform &&
      navigator.userAgentData.platform == "Windows" || (navigator.userAgent.indexOf("Windows") !== -1)
    ) {
      document.getElementById('volumecrbn').classList.add("addpointer")
    }

    if (!this.state.mutesounds) {
      this.setState({ mutesounds: true })
      document.getElementById('volumecrbn').src = mutesnds;

    }
    else {

      this.setState({ mutesounds: false })
      document.getElementById('volumecrbn').src = volumeimg;

    }
  }

  Dealerbetstoplayer = () => {
    let dealerchip_crbn = document.getElementById("dealerchip_crbn")

    switch (this.state.count) {

      case 1:
        dealerchip_crbn.src = chip_10;

        break;
      case 2:
        dealerchip_crbn.src = chip_25;

        break;
      case 3:
        dealerchip_crbn.src = chip_50;

        break;
      case 4:
        dealerchip_crbn.src = chip_100;

        break;
      case 5:
        dealerchip_crbn.src = chip_500;

        break;

      default:
        break;
    }
    Object.assign(dealerchip_crbn.style, carebeanjson.dealerchipstoplayer)
    let tl = gsap.timeline()
    tl.to(dealerchip_crbn, {
      // x: -21,
      // y: 952,
      x: 501,
      y: 691,
      duration: 0.5,
      opacity: 1,
      delay: 1.5,
      ease: "power2.inOut",
      onComplete: () => {
        gsap.set(dealerchip_crbn, { opacity: 0 })

      }
    });
    tl.to(dealerchip_crbn, {
      x: 31,
      y: 0,
      duration: 0.5,
      ease: "power2.inOut",

    });
    tl.play();

  }


  hidepopup_betshigh = () => {

    if (
      navigator.userAgentData &&
      navigator.userAgentData.platform &&
      navigator.userAgentData.platform == "Windows"
    ) {

      const popupbetGreaterthanBalnceElement = document.getElementById("popupbet_greaterthanbalncecrbn");
      if (document.getElementById("popupbet_greaterthanbalncecrbn")) {
        gsap.to(popupbetGreaterthanBalnceElement, {
          scale: 0,
          duration: 1,
          rotate: 0,
          opacity: 0,
          ease: "power2.out",
          onComplete: () => {
            popupbetGreaterthanBalnceElement.className = "";
            popupbetGreaterthanBalnceElement.classList.add("popupbetshighMobilecrbn");
          }

        }
        );

      }
    } else {
      const popupbetGreaterthanBalnceElement = document.getElementById("popupbet_greaterthanbalncecrbn");
      if (document.getElementById("popupbet_greaterthanbalncecrbn")) {
        gsap.to(popupbetGreaterthanBalnceElement, {
          scale: 0,
          duration: 1,
          rotate: 0,
          opacity: 0,
          ease: "power2.out",
          onComplete: () => {
            popupbetGreaterthanBalnceElement.className = "";
            popupbetGreaterthanBalnceElement.classList.add("popupbetshighMobilecrbn");
          }

        }
        );

      }


    }






  }

  updatePlayerInfo() {
    // alert("noe bet shold be deleted successfully")
    this.setState({
      betdeductions: 0
    });


    //  const rankinginfoElement = document.getElementById("rankinginfo_crbn");
    //  if (rankinginfoElement) {
    //   if (this.state.isMobilePortraitLndsp) {
    //      rankinginfoElement.style.left = "34%";
    //    }
    //  }

  }


  showAnteBetchips = () => {


    return (
      this.state.storeantebetchips.map((eachchip, index) => (
        <div key={index} id="chipcentercarebean" className={`${this.state.antebetpdvcss}`}>
          <img
            className={`${this.state.antechpimgcss}`}
            src={eachchip.chip}
            alt="Chip"
          />
          <span className={`${eachchip.chipcolor1} ${this.state.antespntxtcss}`} >{eachchip.chipvalue}</span>
        </div>
      ))
    )
  }


  
  ReturnChipBasedAmount = (amt) => {
    const amount = Number(amt)
    let chipImage = chip_10;
    if (Number(this.chipVal[0].val) <= amount && Number(this.chipVal[1].val) > amount) {
      chipImage = chip_10
    } else if (Number(this.chipVal[1].val) <= amount && Number(this.chipVal[2].val) > amount) {
      chipImage = chip_25
    }
    else if (Number(this.chipVal[2].val) <= amount && Number(this.chipVal[3].val) > amount) {
      chipImage = chip_50
    }
    else if (Number(this.chipVal[3].val) <= amount && Number(this.chipVal[4].val) > amount) {
      chipImage = chip_100

    } else if (Number(this.chipVal[4].val) <= amount && Number(this.chipVal[5].val) > amount) {
      chipImage = chip_500

    } else {
      chipImage = chip_1000
    }
    return chipImage
  }


  ReturnChipBasedAmountUndo = (amt) => {
    console.log(amt)

    const amount = Number(amt)
    let chipImage = "";
    if (Number(this.chipVal[0].val) <= amount && Number(this.chipVal[1].val) > amount) {
      chipImage = chip_10


    } else if (Number(this.chipVal[1].val) <= amount && Number(this.chipVal[2].val) > amount) {
      chipImage = chip_25

    }
    else if (Number(this.chipVal[2].val) <= amount && Number(this.chipVal[3].val) > amount) {
      chipImage = chip_50
    }
    else if (Number(this.chipVal[3].val) <= amount && Number(this.chipVal[4].val) > amount) {
      chipImage = chip_100

    } else if (Number(this.chipVal[4].val) <= amount && Number(this.chipVal[5].val) > amount) {
      chipImage = chip_500

    }
    else if (Number(this.chipVal[5].val) <= amount && Number(this.chipVal[6].val) > amount) {
      chipImage = chip_1000
    }
    else {
      this.setState({ storebetchips: [], storeantebetchips: [] })

      chipImage = ""
      this.setState({ infoText: this.props.language.Place_antebet });
      document.getElementById("mobileAntiMaskCover_crbn").style.display = "none";
      document.getElementById("mobileAntiMaskCover_crbn").classList.remove("mobileAntiMaskCover_crbn")

      document.getElementById("mobileAntiMaskCover_crbn").classList.remove("maskcoverlndspe_crbn")
      document.getElementById("betbtncrbn").style.display="none"
      document.getElementById("undobtn").style.display="none"

      document.getElementById("Foldbtncrbn").src=""
       document.getElementById("foldtextcrbn").textContent=""


    }
    return chipImage
  }
  confirmFoldactin(cnfrmtext) {
    if (this.state.mutesounds) {
      this.betrussiansnd.pause();
    }
    else {
      this.betrussiansnd.play();

    }
    if (this.state.isoptionmenu === true) {
      this.Showoptionsmenu();
    }
    const confirmbuydealerPopupElement = document.getElementById("cnfrmfoldpop");
    if (cnfrmtext === true) {


      document.getElementById("alertTxtcrbn").classList.remove("alertTxtcrbn");

      document.getElementById("alertTxtcrbn").innerHTML = "";
      document.getElementById("cnfrmfoldpop").style.display = "none";
      document.getElementById("cnfrmfoldpop").classList.remove("popup_cnfrmbuydealrcardcrbn");
      document.getElementById("yesbtn").classList.remove("yesbutton_popupcrbn")
      document.getElementById("nobtn").classList.remove("nobutton_popupcrbn")

      confirmbuydealerPopupElement.className = "";
      confirmbuydealerPopupElement.style.display = "none";
      confirmbuydealerPopupElement.classList.remove("popup_Portraitcrbn");
      document.getElementById("alertTxtcrbn").innerHTML = "";
      document.getElementById("yesbtn").classList.remove("yesbutton_popup_portraitcrbn")
      document.getElementById("nobtn").classList.remove("nobutton_popup_portraitcrbn")



      confirmbuydealerPopupElement.classList.remove("popup_cnfrmfoldcrbn");
      document.getElementById("alertTxtcrbn").innerHTML = "";
      document.getElementById("alertTxtcrbn").classList.remove('alertTxtPortraitcrbn');
      document.getElementById("yesbtn").classList.remove("yesbutton_popup_portraitcrbn")
      document.getElementById("nobtn").classList.remove("nobutton_popup_portraitcrbn")

      const body = {
        sessionId: sessionStorage.getItem("sessionId"),

        CN: "CARIBBEAN_POKER_BET",
        object: {
          action: "CONFIRM_FOLD",
          gameId: this.props.gameId,
          playerId: Number(this.props.playerId),
          handId: Number(this.props.gameState.handId)
        },
      };

      this.props.network.sendof(body);

    }

    else {

      this.showParamBetStatus(false);

      document.getElementById("alertTxtcrbn").classList.remove("alertTxtcrbn");

      document.getElementById("alertTxtcrbn").innerHTML = "";
      document.getElementById("cnfrmfoldpop").classList.remove("popup_cnfrmbuydealrcardcrbn");
      document.getElementById("yesbtn").classList.remove("yesbutton_popupcrbn")
      document.getElementById("nobtn").classList.remove("nobutton_popupcrbn")

      confirmbuydealerPopupElement.className = "";
      confirmbuydealerPopupElement.style.display = "none";
      confirmbuydealerPopupElement.classList.remove("popup_Portraitcrbn");
      document.getElementById("alertTxtcrbn").innerHTML = "";
      document.getElementById("alertTxtcrbn").classList.remove('alertTxtPortraitcrbn');
      document.getElementById("yesbtn").classList.remove("yesbutton_popup_portraitcrbn")
      document.getElementById("nobtn").classList.remove("nobutton_popup_portraitcrbn")



      confirmbuydealerPopupElement.classList.remove("popup_cnfrmfoldcrbn");
      document.getElementById("alertTxtcrbn").innerHTML = "";
      document.getElementById("alertTxtcrbn").classList.remove('alertTxtPortrait');
      document.getElementById("yesbtn").classList.remove("yesbutton_popup_portraitcrbn")
      document.getElementById("nobtn").classList.remove("nobutton_popup_portraitcrbn")

    }

  }
  closeMenuOption = () => {
    this.setState({
      isoptionmenu: false,
      ShowLimits: false,
      ShowHistory: false,
      ShowGamerules: false,
    })
  }
  Showpayouttable=()=>{
    this.setState({showpayouts:!this.state.showpayouts},()=>{
      if (
        navigator.userAgentData &&
        navigator.userAgentData.platform &&
        navigator.userAgentData.platform == "Windows"
      ) {

      

        if (document.getElementById("Payout_crbn")) {
          document.getElementById("Payout_crbn").classList.add("payout_crbn")

        }
       
        if (document.getElementById("PAYOUTTABLE")) {
          document.getElementById("PAYOUTTABLE").classList.add("shoWPAYOUTTABLE")

        }
        if (document.getElementById("payout_body")) {
          document.getElementById("payout_body").classList.add("payout_body")

        }
        
        if (document.getElementById("heading_payout")) {
          document.getElementById("heading_payout").classList.add("payoutheading")

        }
       
      

      }
      else {

        if (window.innerHeight > window.innerWidth) {

          if (document.getElementById("Payout_crbn")) {
            document.getElementById("Payout_crbn").classList.remove("payout_crbnLndspe")
  
          }
         
          if (document.getElementById("PAYOUTTABLE")) {
            document.getElementById("PAYOUTTABLE").classList.remove("shoWPAYOUTTABLElndspe")
  
          }
          if (document.getElementById("payout_body")) {
            document.getElementById("payout_body").classList.remove("payout_bodylndspe")
  
          }
          
          if (document.getElementById("heading_payout")) {
            document.getElementById("heading_payout").classList.remove("payoutheadinglndspe")
  
          }

          if (document.getElementById("Payout_crbn")) {
            document.getElementById("Payout_crbn").classList.add("payout_crbnprtrt")
  
          }
         
          if (document.getElementById("PAYOUTTABLE")) {
            document.getElementById("PAYOUTTABLE").classList.add("shoWPAYOUTTABLEprtrt")
  
          }
          if (document.getElementById("payout_body")) {
            document.getElementById("payout_body").classList.add("payout_bodyprtrt")
  
          }
          
          if (document.getElementById("heading_payout")) {
            document.getElementById("heading_payout").classList.add("payoutheadingprtrt")
  
          }
         


        }
        else {
          if (document.getElementById("Payout_crbn")) {
            document.getElementById("Payout_crbn").classList.remove("payout_crbnprtrt")
  
          }
         
          if (document.getElementById("PAYOUTTABLE")) {
            document.getElementById("PAYOUTTABLE").classList.remove("shoWPAYOUTTABLEprtrt")
  
          }
          if (document.getElementById("payout_body")) {
            document.getElementById("payout_body").classList.remove("payout_bodyprtrt")
  
          }
          
          if (document.getElementById("heading_payout")) {
            document.getElementById("heading_payout").classList.remove("payoutheadingprtrt")
  
          }
         


         
          if (document.getElementById("Payout_crbn")) {
            document.getElementById("Payout_crbn").classList.add("payout_crbnLndspe")
  
          }
         
          if (document.getElementById("PAYOUTTABLE")) {
            document.getElementById("PAYOUTTABLE").classList.add("shoWPAYOUTTABLElndspe")
  
          }
          if (document.getElementById("payout_body")) {
            document.getElementById("payout_body").classList.add("payout_bodylndspe")
  
          }
          
          if (document.getElementById("heading_payout")) {
            document.getElementById("heading_payout").classList.add("payoutheadinglndspe")
  
          }
         

        }

      }

    })
  }
  // HideShowpayouttable=()=>{
  //   this.setState({showpayouts:false})
  // }
  render() {
    console.log(this.props.gameState.history)
    console.log(this.props.payoutInfoscrbn)
    console.log(this.props.balance)
    console.log(this.state.betdeductions)
    this.roundNumber = this.props.balance - this.state.betdeductions
    console.log(this.roundNumber)
    this.betAmount = (this.roundNumber)
    let balance_rsp = Number(this.betAmount.toFixed(2));
    console.log(balance_rsp)
    let updateBet = Number(this.state.totalbet.toFixed(2));
    let popup_winamount = this.topLable_rsp(this.state.totalwinRussian)
    console.log(this.state.betactvglwcss)
    const {
      heightOfLayer,
      widthOfLayer,
      dealerDistanceFromX,
      playerDistanceFromX,
      dealerPlayerDistanceFromY,
      isMobilePortraitLndsp,
      dealerDistanceFromY,
      PlayerDistanceFromY,
    } = this.state;
    console.log(window.innerWidth);
    console.log(window.innerHeight);


    console.log(this.state.storebetchips)
    return (
      <Fragment>

        <div id="crbnbgim_crbn" className="crbn_MainDiv_crbn" onClick={() => {

          if (this.state.isoptionmenu === true) {
            this.closeMenuOption();
          }
        }}>

          <img src="" id="pokertableimcrbn" alt="" />
          <img src="" id="pokertablelandscapecrbn" alt="" />
          {/* <div className="optBtn" > */}
          <img id="optionrussian_crbn" src="" onClick={(e) => { e.preventDefault(); this.Showoptionsmenu(); }} alt="" />

          {/* </div> */}
          {/* 
     */}
          <img src="" id="balnceiconcrbn" alt="" />
          <span id="balancecrbn">{balance_rsp}</span>
          <img src="" id="beticoncrbn" alt="" />
          <span id="betscrbn">{updateBet}</span>
          <img src="" id="exitbtn" alt="" />
          <img src="" id="winnericoncrbn" alt="" />
          <div id="playerDetailscrbn">
            <span>P ID:{this.props.playerId}</span>
            <span>ID:{this.props.gameState.handId}</span>
          </div>

          <div id="winscrbn">
            
            <span id="winamount_rsp">{this.state.totalwinRussian}</span>
          </div>

          <div ref={this.buyDealerCardDrawn} id="dealerBuyCard">
            <button type="button" id="dealerChipsAddToBuyButton"> <img src="" id="dealerChipsAddToBuy" alt="" /></button>
            <span id="dealerChipsAddToBuyText"></span>
          </div>




          <div className="player-dealer-card-containercrbn" id="playerDealerCardContainercrbn">

            <div id="container_carebean">
              <h1 id="Dealercrbn">
                DEALER
              </h1>
              <h2 id="Playercrbn">
                PLAYER
              </h2>
            </div>
            {/* <Stage width={window.innerWidth} height={window.innerHeight} > */}
            {/* <Stage width={2350} height={heightOfLayer}> */}
            <Stage width={widthOfLayer} height={heightOfLayer} >
              <Layer>
                <Group x={dealerDistanceFromX} y={dealerDistanceFromY} stroke="black">
                  <DealerCardsContainer ref={this.dealerCardsContainer} x={500} y={120} xPadding={18}
                    scaleX={this.state.scaleX} scaleY={this.state.scaleY}
                    isMobilePortrait={isMobilePortraitLndsp} activeScreen={this.state.activeScreen} />
                </Group>
                <Group x={playerDistanceFromX} y={PlayerDistanceFromY} stroke="black">
                  <Cards ref={this.cardsRef} x={450} y={400} xPadding={18} previousGamestateCardLength6={this.props.previousGamestateCardLength6}
                    scaleX={this.state.scaleX} scaleY={this.state.scaleY}

                    isMobilePortrait={isMobilePortraitLndsp} mutesounds={this.state.mutesounds} activeScreen={this.state.activeScreen} />
                </Group>
              </Layer>

            </Stage>
          </div>




          {/* <img src="" id="dealbtn" onClick={(e) => { e.preventDefault(); this.anteBet(); }} alt="" /> */}
          {/* <span className="dealbtntext">Deal</span> */}
          <div id="undobtn">
            <button type="button" onClick={(e) => { e.preventDefault(); this.Undobetscaribbean(); }}>
              <img src="" id="undobutton" style={this.state.undotextcss} alt="" />
              <span id="undotext" className={this.state.undotexttxtcss}></span>

            </button>
          </div>
          <span id="carebean_ante" className={this.state.antetext}>ANTE</span>
          <div id="betbtncrbn">
            <button disabled={this.state.multibuttonsebledsble}>
              <img style={this.state.betbuttoncss} src="" id="betbtn" onClick={(e) => {
                e.preventDefault();
                console.log(this.state.betbutton);
                if (this.state.betbutton === "Bet") {
                  this.CaribbeanBet();
                } else if (this.state.betbutton === "DEAL") {

                  this.anteBetCaribbean();
                } else if (this.state.betbutton === "REBET") {
                  this.anteclickCaribbean(this.state.betbutton);
                }
              }}
                alt="" />
              <span id="betbtntext" className={this.state.betbtntextcss}></span>
            </button>
          </div>


          <button disabled={this.state.disablemultibutton}>
            <img style={this.state.foldbuttoncss}
              src=""
              id="Foldbtncrbn"
              onClick={(e) => {
                e.preventDefault();
                if (this.state.buttontext == "clear") {
                  this.clearbets();
                } else if (this.state.buttontext == "fold") {
                  this.FoldCards();
                } else if (this.state.buttontext == "REBETX2") {
                  this.anteclickCaribbean(this.state.buttontext);
                }
              }}
              alt="" />
            <span id="foldtextcrbn" className={this.state.foldbtntextcss}></span>
          </button>

          <img src="" id="infrmiconcrbn" onMouseDown={(e) => {

           this.Showpayouttable();
            // this.HideShowpayouttable();
          }}

            alt="" />
          <img src="" id="volumecrbn"


            onClick={(e) => { e.preventDefault(); this.muteunmutesounds(); }} alt=""


          />
          <button disabled={this.state.betchipincrementbtn}>
            <img src="" id="betincrenetcrbn" onClick={(e) => { e.preventDefault(); this.IncreamentBetchips(); }} alt="" />
          </button>
          <button disabled={this.state.betdecreamentbtn}>

            <img src="" id="betdecrementcrbn"
              onClick={(e) => { e.preventDefault(); this.DecreamentBetchips(); }} alt="" />
          </button>
          <img src="" id="betchipscrbn" alt="" />
          {this.chipVal.map((obj, i) => (
            <span key={i} className={`valtext${i}`} id={`val${i}`}></span>
          ))}


         


       
          <div >
            <img src="" id="betchipsaddtconfrm" alt="" />
            {/* <span id="betchipsaddtodrawText"></span> */}
          </div>

          

          <img src="" id="betchipsaddinsurence" ref={this.chipinsurenceref} alt="" />
          <div id="delaerchipanim_crbn">
            <img src="" id="dealerchip_crbn" alt="" />
          </div>
          <img src="" id="playerwinchip" alt="" />
          <img src="" id="useridcrbn" alt="" />
          <span id="useridtextcrbn">{this.props.user}</span>
          <div id="caribbeantablecontainer">
            <img src="" id="caribbeantbleport" />
          </div>

          <img src="" id="Russianbgwindows" onClick={(e) => { e.preventDefault(); this.hidepopup_betshigh() }} />



         
        </div>

        {(this.state.isMobilePortraitLndsp === false) && (
          <div id="chipsstoredcontainer" ref={this.chipsarray} className={this.state.chipsstoredcontainercss}>
            {/* {this.storebetchips} */}
            {this.state.storebetchips}
            {/* <span id="betchipsaddtobonustxt"></span> */}
          </div>

        )}


        <div id="infocontainercrbn">
          <span id="info_crbn" className={this.state.infocrbn}>{this.state.infoText}</span>
        </div>



        <div>
          <span id="rankinginfo_crbn" >{this.state.pokerranking}</span>
        </div>
        <div>
          <span id="rankinginfodeler_crbn">{this.state.pokerrankingdeler}</span>
        </div>
        {(this.state.isMobilePortraitLndsp === true) && (

          <div id="chipsstoredcontainer" ref={this.chipsarray} className={this.state.chipsstoredcontainercss}>
            {this.state.storebetchips}
            {this.showAnteBetchips()}



          </div>

        )}

        <div id="anitecircleactive"></div>
        {this.state.isoptionmenu ? (
          <div id="optionsmenubutton">
            {/* <div id="optiontopmenu"> */}
            {/* <span id="optiontext">OPTIONS</span> */}
            {/* </div> */}
            <div id="buttontscontainer">
              <button id="limitscrbn" onClick={(e) => {
                e.preventDefault();
                this.ShowLimits();
              }}>LIMITS</button>
              <button id="help_crbn" onClick={(e) => {
                e.preventDefault();
                this.Gamerules();
              }}>HELP</button>
              <button id="historytext_crbn" onClick={(e) => {
                e.preventDefault();
                this.ShowHistory()
              }}>HISTORY</button>
              {/* <button id="arrow"></button> */}
              {/* <img
                src={exitrussian}
                id="exitmenu"
                onClick={(e) => {
                  e.preventDefault();
                  this.Exitoptionmenu();
                }}
                alt=""
              /> */}
            </div>
          </div>
        ) : (
          ""
        )}

        <div id="betpannel">
          <button onClick={(e) => this.anteclickCaribbean()}>
            <img id="anteclickCaribbean" src="" />


          </button>



          <img src="" id="carebeanbetsanteclick" ref={this.chiprefee} alt="" />
          <img src="" ref={this.chiprefcarebean} id={`careabeanantebets${this.state.carebbeanidincrement}`} alt="" style={this.state.carebbeanbetchipstoante} />

          <button id="betbtn_carebean" disabled={this.state.betbtncrbn}>
            <img id="bet_carebean" src="" />
            <span id="carebean_bet">BET</span>


          </button>
          <img src="" id="betchipsaddtobetcaribn" ref={this.chipbetref} alt="" style={this.state.betchiptobetbtncss} />
          <span id="betTextaddtoBetcrbn" className={this.state.betbtntxtcss}></span>


          {/* <div id="betglow" className={this.state.betactvglwcss}   ></div> */}
          {/* <div id="betglow"></div> */}



        </div>

        <div id="anteglow"></div>





        <div id="glowonwinchip_carri"></div>
        <div id="winpopup_crbn">
          <span id="winamt_crbn">{this.state.totalwinRussian}</span>
        </div>

        <div id="minmaxbetscrbn">
          <span>MinBet:{this.props.minBetAmt}</span>
          <span>MaxBet:{this.props.maxBetAmt}</span>

        </div>



        {this.state.ShowGamerules ? (



          <div id="ShowGamerulescrbn">
            <h1 id="gameruleMainHeadingcrbn">GAME RULES</h1>
            <ul id="unorderedlistitemscrbn">

              <hr id="hrline" />
              {this.GameRules.map((val, key) => (
                <li>{val.name}</li>
              ))}
            </ul>
            {/* <button id="exitLimits" onClick={(e) => {
e.preventDefault();
this.exitLimits();
}}
// onMouseOver={() => this.animexit()}
>EXIT</button> */}
          </div>

        ) : ""}




        {this.state.ShowHistory ? (
          <div id="prevhistory_crbn">
            <table id="histry_russian_crbn">
              <thead id="heading_hstiry_crbn">
                <tr>
                  <th>Dealer Cards</th>
                  <th>Dealer Hand</th>
                  <th>Player Cards</th>
                  <th>Player Hand</th>
                  <th>Ante BetAmount</th>
                  <th>Ante WinAmount</th>
                  <th>Bet Amount</th>
                  <th>Bet WinAmount</th>
                  <th>Total Bet</th>
                  <th>Total Win</th>
                </tr>
              </thead>

              <tbody id="histry_body_crbn">
                {this.props.gameState.history.map((obj, i) => (
                  <tr key={`card-${i}`} id={`${i}`}>
                    <td id="dealercards_hstry_crbn">

                      {obj.result.dealerCardsSorted.map((card_num, index) => {
                        const matchingCard = resultCards_crbn.find(res => res.rsc_val === card_num);
                        return (

                          <img
                            className="cardsimages_crbn"
                            key={index}
                            src={matchingCard ? matchingCard.src : ''}
                            alt={card_num}
                          />


                        );
                      })}
                    </td>
                    <td id="dealercards_hstry_crbn">{obj.result.dealerHand}</td>
                    <td id="dealerhand_hstry_crbn">
                      {obj.result.playerCardsSorted.map((card_num, index) => {
                        const matchingCard = resultCards_crbn.find(res => res.rsc_val === card_num);
                        return (
                          <img
                            className="cardsimages_crbn"
                            key={index}
                            src={matchingCard ? matchingCard.src : ''}
                            alt={card_num}
                          />
                        );
                      })}
                    </td>
                    <td id="playerhand_hstry_crbn">{obj.result.playerHand}</td>
                    <td id="totalbet_histry_crbn">{obj.result.anteBetAmount ? obj.result.anteBetAmount : 0}</td>
                    <td id="totalwin_hstry_crbn">{obj.result.anteWinAmount ? obj.result.anteWinAmount : 0}</td>

                    <td id="totalwin_hstry_crbn">{obj.result.betAmount ? obj.result.betAmount : 0}</td>
                    <td id="playerhand_hstry_crbn">{obj.result.winAmount ? obj.result.winAmount : 0}</td>

                    <td id="playerhand_hstry_crbn">{obj.result.totalBetAmount ? obj.result.totalBetAmount : 0}</td>
                    <td id="totalbet_histry_crbn">{obj.result.totalWinAmount ? obj.result.totalWinAmount : 0}</td>



                  </tr>
                ))}
              </tbody>
            </table>
            {/* <button id="exithistory" onClick={(e) => {
              e.preventDefault();
              this.exithistory()
            }}
            // onMouseOver={() => this.animexit()}
            >EXIT</button> */}
          </div>
        ) : null}




        <div id="landscapeInfocrbn" className={this.state.inforussiancss}>
          <span id="inforussianlandcrbn">{this.state.infoText}</span>
        </div>




        <div id="popupbet_greaterthanbalncecrbn">
          {(this.state.betshigh_balnce !== null) &&
            <button className="exit_popupcrbn" id="exit_popupcrbn" onClick={this.hidepopup_betshigh}><img src={popupExit} alt={popupExit} /></button>
          }
          <span id="textbets_highcrbn">
            <h4 id="attentioncrbn">Attention!</h4>
            {this.state.lowBalaceErrorInfo}
          </span>



        </div>



        <div id="betpannelcarebbeancover"></div>
        <div id="mobileAntiMaskCover_crbn" className={this.state.mobileAntiMaskCovercss}></div>

        <div id="cnfrmfoldpop" className={this.state.coverbetpannel}>
          <span id="alertTxtcrbn" ></span>
          <button id="yesbtn" onClick={(e) => {


            this.confirmFoldactin(true);



          }}>YES</button>
          <button id="nobtn" ref={this.no}
            onClick={(e) => {

              this.confirmFoldactin(false);



            }}
          >NO</button>
        </div>
        <div id="PokerDeckcrbn">
          <img id="crbndeck" src="" />
        </div>

        {this.state.ShowLimits ? (
          <div id="limitscrbnpopup">
            <span>MinBet:{this.props.minBetAmt}</span>
            <span>MaxBet:{this.props.maxBetAmt}</span>
            {/* <button id="exitLimits" onClick={(e) => {
              e.preventDefault();
              this.exitLimits()
            }}
            // onMouseOver={() => this.animexit()}
            >EXIT</button> */}
          </div>
        ) : ""}
        
 {this.state.showpayouts ? (
          <div id="PAYOUTTABLE">
            <table id="Payout_crbn">
              <thead id="heading_payout">
                <tr>
                  <th>PLAYER HAND</th>
                  <th>AMOUNT</th>
                  
                </tr>
              </thead>

              <tbody id="payout_body">
                {this.props.payoutInfoscrbn.map((obj, i) => (
                  <tr key={`card-${i}`} id={`${i}`}>
                    <td id="playerhand">

                    {obj.key}
                    </td>
                    <td id="playerwinamount">{obj.value}</td>
                    
              
                

                  </tr>
                ))}
              </tbody>
            </table>
            {/* <button id="exithistory" onClick={(e) => {
              e.preventDefault();
              this.exithistory()
            }}
            // onMouseOver={() => this.animexit()}
            >EXIT</button> */}
          </div>
        ) : null}


      </Fragment>
    );
  }

}



const mapStatesToProps = (state) => {
  return {
    language: state.languageObjs.languageObj
  }
}

export default connect(mapStatesToProps)(RussianPoker)


