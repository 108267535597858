import { Component } from "react";
import { Group, Sprite } from "react-konva";
import gsap from "gsap";
import CardDefault from '../../../../../../assets/games/CarebbeanPoker/rp_sprite_card.png';


import cardsdropping_snd from '../../../../../../assets/sounds/carddroping.mp3';
import cardsshuffle from "../../../../../../assets/sounds/shuffle-cards.mp3";
import { Howl } from "howler";




class DealerCard extends Component {

    constructor(props) {
        super(props);

        this.state = {
            image: null,
            cardDropSound: null,
            isCardDropSound: false,
            cardShuffledSound: null,
            isCardShuffledSound: false,

            // x: props.x1,
            // y: props.y1,

        };
        this.cardStyle = CardDefault;

        this.cardDropPositionX = null;
        this.cardDropPositionY = null;
        this.handleResize = this.handleResize.bind(this);
        this.handleResize();
    }

    componentDidMount() {
        this.carddropsound = this.initializeCardDropSound();
        this.cardsshuffleSound = this.initializeCardShuffledSound();
        this.loadImage(CardDefault);
        if (this.imageNode && this.imageNode !== null && this.imageNode != undefined) {
            window.addEventListener("resize", this.handleResize);
        }

    }

    componentDidUpdate(prevProps) {
        
       
        if (this.cardStyle !== this.props.cardStyle) {
            this.loadImage(this.props.cardStyle);
            this.cardStyle = this.props.cardStyle;
        }
        if (this.props.x != prevProps.x) {
            this.changeCardPosition();
        }

    }
    initializeCardDropSound = () => {
        let cardDropSound = new Howl({
            src: [cardsdropping_snd],
            html5: true, // Enable HTML5 Audio for mobile compatibility
        });
        this.setState({
            cardDropSound,
            isCardDropSound: false
        })
        return cardDropSound

    }
    initializeCardShuffledSound = () => {
        let cardShuffledSound = new Howl({
            src: [cardsshuffle],
            html5: true, // Enable HTML5 Audio for mobile compatibility
        });
        this.setState({
            cardShuffledSound,
            isCardShuffledSound: false
        })
        return cardShuffledSound

    }

    playPokerCardDropSound = (chipSound) => {
        const { isCardDropSound } = this.state;
        const { mutesounds } = this.props
        let currentSound = chipSound;
        if (!mutesounds) {

            if (isCardDropSound) {
                chipSound.stop();
            }
            if (!chipSound) {
                currentSound = this.initializeCardDropSound();
            }

            currentSound.play();
            this.setState({
                isCardDropSound: true
            })
        }

    };

    playPokerCardShuffledSound = (chipSound) => {
        const { isCardShuffledSound } = this.state;
        const { mutesounds } = this.props
        let currentSound = chipSound;
        if (!mutesounds) {

            if (isCardShuffledSound) {
                chipSound.stop();
            }
            if (!chipSound) {
                currentSound = this.initializeCardShuffledSound();
            }

            currentSound.play();
            this.setState({
                isCardShuffledSound: true
            })
        }

    };


    componentWillUnmount() {
        this.carddropsound.pause();
        this.cardsshuffleSound.pause();
        window.removeEventListener("resize", this.handleResize);
    }


    handleResize() {
        if (this.imageNode && this.imageNode !== null && this.imageNode != undefined) {
            console.log("Dealer card container");
            this.imageNode.to({
                x: this.props.x,
                y: this.props.y,
                scaleX: this.props.scaleX,
                scaleY: this.props.scaleY,
                //  rotation: this.props.angled, 
                duration: 0.001, ease: "EaseInOut",
                onFinish: this.afterFinish()
            });

        }

        if (navigator.userAgentData && navigator.userAgentData.platform &&
            navigator.userAgentData.platform == "Windows") {
            // x={1000}
            // y={-200}
            this.cardDropPositionX = window.innerWidth / 2 +950;
            this.cardDropPositionY = -(window.innerHeight / 2-400);

        } else {

            if (window.innerWidth > window.innerHeight) {
                this.cardDropPositionX = window.innerWidth / 2 +600;
                this.cardDropPositionY = - (window.innerHeight / 2-200);
            } else {

                this.cardDropPositionX = window.innerWidth / 2 +600;
                this.cardDropPositionY = window.innerHeight / 2 -500;
                // this.cardDropPositionY = 200;
            }
        }

    }

    changeCardPosition() {
        this.imageNode.to({ x: this.props.x, y: this.props.y, duration: 0.5, ease: "Linear" });

    }

    loadImage(img) {
        this.image = new window.Image();
        this.image.src = img;
        this.image.addEventListener("load", this.handleLoad);
    }

    handleLoad = () => {
        this.setState({
            image: this.image,
        });
    };


    showPreviousDealercards() {
        
       
        this.props.UpdateCard({ x: this.props.x, y: this.props.y })


        if (this.imageNode && this.imageNode !== null && this.imageNode !== undefined) {
            this.imageNode.to({ visible: true });

            gsap.fromTo(
                this.imageNode,
                {  duration: 0.00001, x: this.cardDropPositionX, y: this.cardDropPositionY },
                { x: this.props.x, y: this.props.y, scaleX: this.props.scaleX, scaleY: this.props.scaleY, duration: 0.5,ease: "EaseInOut", onComplete: this.afterFinish() }
              );

        }

    }



    animateCard() {
        // console.log("this.props.text" , this.props.text , "this.props.frame :" , this.props.frame);
        // this.setState({x:this.props.x,y:this.props.y})
        this.props.UpdateCard({ x: this.props.x, y: this.props.y })


        if (this.imageNode && this.imageNode !== null && this.imageNode !== undefined) {
            this.imageNode.to({ visible: true, duration: 0.05 });
            // this.imageNode.to({ x: this.props.x, y: this.props.y, scaleX: this.props.scale, scaleY: this.props.scale, rotation: this.props.angled, duration: 0.5, ease: "EaseInOut",onFinish:this.afterFinish() });
            // this.imageNode.to({ x: this.props.x, y: this.props.y, scaleX: this.props.scaleX, scaleY: this.props.scaleY, duration: 0.5, ease: "EaseInOut", onFinish: this.afterFinish()});
           

            gsap.fromTo(
                this.imageNode,
                {  duration: 0.05, x: this.cardDropPositionX, y: this.cardDropPositionY },
                { x: this.props.x, y: this.props.y, scaleX: this.props.scaleX, scaleY: this.props.scaleY, duration: 0.5, onComplete: this.afterFinish() }
              );

        }

    }

    afterFinish() {
        this.props.XY({ index: -1, text: this.props.text, x: this.props.x, y: this.props.y }); 

    }

    revealdealerMaskCard = () => {

        // var tl = gsap.timeline({ onComplete: this.draw() });
        // tl.fromTo(this.imageNode, { scaleX: 0.5, opacity: 1, duration: 0 }, { scaleX: this.props.scaleX, scaleY: this.props.scaleY, duration: 0.6, ease: "EaseInOut", })
        // tl.fromTo(this.imageNode, { scaleX: this.props.scaleX, scaleY: this.props.scaleY, duration: 0.5 }, { scaleX: this.props.scaleX, scaleY: this.props.scaleY, duration: 0.5, ease: "EaseInOut", opacity: 1 })
        // tl.play()

        var tl = gsap.timeline({ onComplete: this.draw() });
        tl.fromTo(this.imageNode, { scaleX: 0.5, opacity: 1, duration: 0.6 }, { scaleX: 0.5, scaleY: this.props.scaleY, duration: 0.3, ease: "EaseInOut", })
        tl.fromTo(this.imageNode, { scaleX: this.props.scaleX, scaleY: this.props.scaleY, duration: 0.6 }, { scaleX: this.props.scaleX, scaleY: this.props.scaleY, duration: 0.5, ease: "EaseInOut", opacity: 1 })
        tl.play()


    }


    draw() {
        this.props.XY({ index: this.props.index, text: this.props.text, x: this.props.x, y: this.props.y })
    }

    DrawanimateCard(data) {
        console.log('this.props.x', this.props.x, 'this.props.y', this.props.y)
        var tl = gsap.timeline({ onComplete: this.draw() });
        tl.fromTo(this.imageNode, { x: this.props.x, y: this.props.y, scaleX: 1, scaleY: 1, opacity: 1, duration: 0.5 },
            { x: this.cardDropPositionX, y: this.cardDropPositionY, scaleX: 0.8, scaleY: 0.6, duration: 0.6, ease: "EaseInOut", opacity: 0 })
        tl.fromTo(this.imageNode, { x: this.cardDropPositionX, y: this.cardDropPositionY, scaleX: 0.15, scaleY: 0.15, opacity: 0, duration: 0.5 },
            { x: this.props.x, y: this.props.y, scaleX: this.props.scaleX, scaleY: this.props.scaleY, duration: 0.5, ease: "EaseInOut", opacity: 1 })
        tl.play()
        console.log('this.imageNode', this.imageNode)

    }




    SuffleCard(data) {
        console.log(data)
        gsap.fromTo(this.imageNode, { x: data.x, y: data.y - 10, opacity: 0.5 }, { x: this.props.x, y: this.props.y, duration: 0.5, ease: "EaseInOut", opacity: 1, onComplete: this.Uc(data) });
    }

    Uc(data) {
        this.props.XY({ index: this.props.index, text: this.props.text, x: data.x, y: data.y });
    }

    render() {
        console.log('this.cardDropPositionX  : ', this.cardDropPositionX, 'this.cardDropPositionY', this.cardDropPositionY)

        return (
            <Group>
                <Sprite

                    // x={1000}
                    // y={-200}
                    // x={this.cardDropPositionX}
                    // y={this.cardDropPositionY}
                    ref={(node) => {
                        this.imageNode = node;
                    }}
                    image={this.state.image}
                    // scaleX={this.props.scale}
                    // scaleY={this.props.scale}
                    scaleX={0.15}
                    scaleY={0.15}
                    animations={{
                        cardFrame: this.props.frame,
                    }}
                    animation={"cardFrame"}
                    frameRate={1}
                    frameIndex={0}
                    opacity={this.props.alpha}
                    visible={false}
                    // visible={this.props.show}
                    // rotation={this.props.angled}
                    rotation={0}
                ></Sprite>
            </Group>
        )
    }
}


export default DealerCard;

