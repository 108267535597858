import React from "react";
import { connect } from "react-redux";
import i_symbol from "../../../assets/common_imgs/i_symbol.png";
import img2x from "../../../assets/common_imgs/2x.svg";
import img4x from "../../../assets/common_imgs/4x.svg";
import snd from "../../../assets/sounds/bet.mp3"

class payOutInfo extends React.Component {
    constructor(props) {
      
        super(props)
        this.state = {
            payoutInfo: [],
            isPayout_show: false,
            gameId: 0,
        }
        this.snd = new Audio(snd);
        this.cardGames = [28,30,33,39,40]
    }

    componentDidMount() {
        const { childRef } = this.props;
        childRef(this);
    }

    setPayoutData(payoutData, gameId) {
     
if(document.getElementById("infoIcon")){
    document.getElementById("infoIcon").className = "";

}
       

        this.setState({
            payoutInfo: payoutData,
            gameId: gameId
        })
        console.log(payoutData);
        console.log(gameId);
        if(document.getElementById("winratiopop")){
            document.getElementById("winratiopop").style.top = "0px";
            document.getElementById("winratiopop").style.right = "0px";
        }
        
        switch (gameId) {
            case 1:
              
                if (document.getElementById("infoIcon").classList.contains("fc_payTable")) {
                    document.getElementById("infoIcon").classList.remove('fc_payTable')
                }
                if (this.props.isMobile) {
                    document.getElementById("infoIcon").classList.add('fc_payTable')
                    document.getElementById("infoIcon").classList.add('emptyPointer')

                } else {
                  
                    document.getElementById("infoIcon").classList.add('fc_payTable')
                }
                document.getElementById("winratiopop").style.top = "85px";
                document.getElementById("winratiopop").style.right = "-164px";
                document.getElementById("winratiopop").style.zIndex = 999;

                break;
            case 2:
                if (document.getElementById("infoIcon").classList.contains("ftt_payTable")) {
                    document.getElementById("infoIcon").classList.remove('ftt_payTable')
                }
                if (this.props.isMobile) {
                    document.getElementById("infoIcon").classList.add('ftt_payTable')
                    document.getElementById("infoIcon").classList.add('emptyPointer')

                } else {
                    document.getElementById("infoIcon").classList.add('ftt_payTable')
                }
                document.getElementById("winratiopop").style.top = "113px";
                document.getElementById("winratiopop").style.zIndex = 9999;

                break;

         
       
            case 12:
                if (document.getElementById("infoIcon").classList.contains("ertimer_payTable")) {
                    document.getElementById("infoIcon").classList.remove('ertimer_payTable')
                }
                if (this.props.isMobile) {
                    document.getElementById("infoIcon").classList.add('ertimer_payTable')
                    document.getElementById("infoIcon").classList.add('emptyPointer')
                } else {
                    document.getElementById("infoIcon").classList.add('ertimer_payTable')
                }
            
                    document.getElementById("winratiopop").style.top = "120px";
                    document.getElementById("winratiopop").style.right = "-215px";
                    document.getElementById("winratiopop").style.zIndex = 9999;
                
                break;


            case 19:
                if (document.getElementById("infoIcon").classList.contains("art_payTable")) {
                    document.getElementById("infoIcon").classList.remove('art_payTable')
                }
                if (this.props.isMobile) {
                    document.getElementById("infoIcon").classList.add('art_payTable')
                    document.getElementById("infoIcon").classList.add('emptyPointer')
                } else {
                    document.getElementById("infoIcon").classList.add('art_payTable')
                }
                if (gameId == 19) {
                    document.getElementById("winratiopop").style.top = "105px";
                    document.getElementById("winratiopop").style.right = "655px";
                    document.getElementById("winratiopop").style.zIndex = 9999;
                } 
                break;
                case 3:
                    if (document.getElementById("infoIcon").classList.contains("artimer_payTable")) {
                        document.getElementById("infoIcon").classList.remove('artimer_payTable')
                    }
                    if (this.props.isMobile) {
                        document.getElementById("infoIcon").classList.add('artimer_payTable')
                        document.getElementById("infoIcon").classList.add('emptyPointer')
                    } else {
                        document.getElementById("infoIcon").classList.add('artimer_payTable')
                    }
                    document.getElementById("winratiopop").style.top = "86px";
                    document.getElementById("winratiopop").style.right = "-237px";
                    document.getElementById("winratiopop").style.zIndex = 9999;
                    break;

                case 5:
                    if (document.getElementById("infoIcon").classList.contains("ft_payTable")) {
                        document.getElementById("infoIcon").classList.remove('ft_payTable')
                    }
                    if (this.props.isMobile) {
                        document.getElementById("infoIcon").classList.add('ft_payTable')
                        document.getElementById("infoIcon").classList.add('emptyPointer')
                    } else {
                        document.getElementById("infoIcon").classList.add('ft_payTable')
                    }
                    document.getElementById("winratiopop").style.top = "86px";
                    document.getElementById("winratiopop").style.right = "-117px";
                    document.getElementById("winratiopop").style.zIndex = 9999;
                    break;
            case 6:
            case 13:
                if (document.getElementById("infoIcon").classList.contains("arnt_payTable")) {
                    document.getElementById("infoIcon").classList.remove('arnt_payTable')
                }
                if (this.props.isMobile) {
                    document.getElementById("infoIcon").classList.add('arnt_payTable')
                    document.getElementById("infoIcon").classList.add('emptyPointer')
                } else {
                    document.getElementById("infoIcon").classList.add('arnt_payTable')
                }
                document.getElementById("winratiopop").style.top = "92px";
                document.getElementById("winratiopop").style.right = "-338px";
                document.getElementById("winratiopop").style.zIndex = 9999;

                break;

            case 7:
            case 14:
                if (document.getElementById("infoIcon").classList.contains("mtt_payTable")) {
                    document.getElementById("infoIcon").classList.remove('mtt_payTable')
                }
                if (this.props.isMobile) {
                    document.getElementById("infoIcon").classList.add('mtt_payTable')
                    document.getElementById("infoIcon").classList.add('emptyPointer')

                } else {
                    document.getElementById("infoIcon").classList.add('mtt_payTable')
                }
                document.getElementById("winratiopop").style.top = "101px"
                document.getElementById("winratiopop").style.right = "429px"

                break;
            case 20:
                 if (document.getElementById("infoIcon").classList.contains("dt_payTable")) {
                    document.getElementById("infoIcon").classList.remove('dt_payTable')
                }
                if (this.props.isMobile) {
                    document.getElementById("infoIcon").classList.add('dt_payTable')
                    document.getElementById("infoIcon").classList.add('emptyPointer')

                } else {
                    document.getElementById("infoIcon").classList.add('dt_payTable')
                }
                document.getElementById("winratiopop").style.top = "225px";
                document.getElementById("winratiopop").style.right = "30px";
                document.getElementById("winratiopop").style.zIndex = 9999;

                break;
            case 26:
                if (document.getElementById("infoIcon").classList.contains("kn_payTable")) {
                    document.getElementById("infoIcon").classList.remove('kn_payTable')
                }
                if (this.props.isMobile) {
                    document.getElementById("infoIcon").classList.add('kn_payTable')
                    document.getElementById("infoIcon").classList.add('emptyPointer')

                } else {
                    document.getElementById("infoIcon").classList.add('kn_payTable')
                }
                document.getElementById("winratiopop").style.top = "100px";
                document.getElementById("winratiopop").style.right = "180px";
                document.getElementById("winratiopop").style.zIndex = 999;

                break
            case 23:
                if (document.getElementById("infoIcon").classList.contains("mw_payTable")) {
                    document.getElementById("infoIcon").classList.remove('mw_payTable')
                }
                if (this.props.isMobile) {
                    document.getElementById("infoIcon").classList.add('mw_payTable')
                    document.getElementById("infoIcon").classList.add('emptyPointer')

                } else {
                    document.getElementById("infoIcon").classList.add('mw_payTable')
                }
                document.getElementById("winratiopop").style.top = "80px";
                document.getElementById("winratiopop").style.right = "-6px";
                document.getElementById("winratiopop").style.zIndex = 99;

                break
            case 24:
                if (document.getElementById("infoIcon").classList.contains("ab_payTable")) {
                    document.getElementById("infoIcon").classList.remove('ab_payTable')
                }
                if (this.props.isMobile) {
                    document.getElementById("infoIcon").classList.add('ab_payTable')
                    document.getElementById("infoIcon").classList.add('emptyPointer')

                } else {
                    document.getElementById("infoIcon").classList.add('ab_payTable')
                }
                document.getElementById("winratiopop").style.top = "300px";
                document.getElementById("winratiopop").style.right = "-337px";
                document.getElementById("winratiopop").style.zIndex = 99;

                break
            case 25:
                if (document.getElementById("infoIcon").classList.contains("bingo_payTable")) {
                    document.getElementById("infoIcon").classList.remove('bingo_payTable')
                }
                if (this.props.isMobile) {
                    document.getElementById("infoIcon").classList.add('bingo_payTable')
                    document.getElementById("infoIcon").classList.add('emptyPointer')

                } else {
                    document.getElementById("infoIcon").classList.add('bingo_payTable')
                }
                document.getElementById("winratiopop").style.top = "158px";
                document.getElementById("winratiopop").style.right = "183px";
                document.getElementById("winratiopop").style.zIndex = 99;

                break;
                case 28:
                case 30:
                case 33:
                case 39:
                case 40:
                    if(document.getElementById("payPage")){
                        document.getElementById("payPage").display = "none"
                        clearTimeout( this.payoutTimerId);
                        
                    }

                break   ; 
            // case "kn_dup":
            //     if (document.getElementById("infoIcon").classList.contains("kn_payTable")) {
            //         document.getElementById("infoIcon").classList.remove('kn_payTable')
            //     }
            //     if (this.props.isMobile) {
            //         document.getElementById("infoIcon").classList.add('kn_payTable')
            //         document.getElementById("infoIcon").classList.add('emptyPointer')

            //     } else {
            //         document.getElementById("infoIcon").classList.add('kn_payTable')
            //     }
            //     document.getElementById("winratiopop").style.top = "100px";
            //     document.getElementById("winratiopop").style.right = "180px";
            //     document.getElementById("winratiopop").style.zIndex = 9999;
            //     break
            // case "bngo_dup":
            //     if (document.getElementById("infoIcon").classList.contains("bgno_dup_payTable")) {
            //         document.getElementById("infoIcon").classList.remove('bgno_dup_payTable')
            //     }
            //     if (this.props.isMobile) {
            //         document.getElementById("infoIcon").classList.add('bgno_dup_payTable')
            //         document.getElementById("infoIcon").classList.add('emptyPointer')

            //     } else {
            //         document.getElementById("infoIcon").classList.add('bgno_dup_payTable')
            //     }
            //     document.getElementById("winratiopop").style.top = "100px";
            //     document.getElementById("winratiopop").style.right = "180px";
            //     document.getElementById("winratiopop").style.zIndex = 9999;
            //     break
            default:
                break;
        }

      
        const activeStatus = this.cardGames.includes(Number(gameId));


        this.payoutTimerId =  setTimeout(() => {
            // if(document.getElementById("payPage")&&gameId !== 28&&gameId !== 30)
            if(document.getElementById("payPage")&& !activeStatus)
            document.getElementById("payPage").style.display = "block";
        }, 500)

    }
    addwinrationtble() {
      
        if (!this.state.isPayout_show) {
           
            if (document.getElementById("winratiopop")) {
               
                document.getElementById("winratiopop").classList.add("arnt_winratio");
                document.getElementById("winratiopop").style.display = "block";
            }
            this.setState({ isPayout_show: true })
        } else {
            if (document.getElementById("winratiopop")) {
                document.getElementById("winratiopop").classList.remove("arnt_winratio")
                document.getElementById("winratiopop").style.display = "none";
            }
            this.setState({ isPayout_show: false })
        }
    }

    hidewinrationtble() {
        if (this.state.isPayout_show) {
            if (document.getElementById("winratiopop")) {
                document.getElementById("winratiopop").classList.remove("arnt_winratio")
                document.getElementById("winratiopop").style.display = "none";                
            }
            this.setState({ isPayout_show: false })
        }
    }

    show2xImg(val){
        console.log(val);
        // document.getElementById("img2x").style.display = "block";
    }

    render() {
        return (
            <div id="payPage" className="PayoutPage">
                <div id="infoIcon" className={(this.props.isMobile) ? 'emptyPointer' : ''}
                    onClick={(e) => { e.preventDefault(); this.addwinrationtble(); this.snd.play();}}
                    onMouseLeave={(e) => { e.preventDefault(); this.hidewinrationtble() }}
                    onMouseOut={(e) => { e.preventDefault(); this.hidewinrationtble() }}
                >
                    <img className={(this.props.isMobile) ? 'i_symbol emptyPointer' : 'i_symbol'} src={i_symbol} />
                </div>
                <div className='payout_table' id="winratiopop" >
                    <table id="payTable" className={(this.props.isMobile) ? 'payTable emptyPointer' : 'payTable'}>
                        <tbody>
                            <tr id="tableHead">
                                <th className="payout_head" >{this.state.gameId == 26 ? "Numbers Matched" : "Win Combination" }</th>
                                <th className="payout_head">Prize</th>
                            </tr>
                        </tbody>
                        <tbody>
                            {this.state.payoutInfo.map((item, index) => (
                                <tr key={index}>
                                    <td >
                                        {/* {item.key} */}
                                        {item.key == "2x" ? <img id="img2x" className="bonusImg" src={img2x} alt="2X"></img> : "" } 
                                    {item.key == "4x" ? <img id="img4x" className="bonusImg" src={img4x} alt="4X"></img>:""} 
                                    { item.key !== "2x" && item.key != "4x" ? item.key: ""}
                                    
                                    </td>
                                    <td >
                                        {item.value}
                                    
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        )
    }
}

const mapStatesToProps = (state) => {
    return {
        language: state.languageObjs.languageObj
    }
}

export default connect(mapStatesToProps)(payOutInfo) 